import * as BMsActions from "../actions";

const initialState = {
  type: "",
  isLoading: false,
  bmsDataList: [],
  BMsList: [],
  firstStoresNumberNeedToLoad: 50,
  isLoadedEnoughBMsData: false,
  isLoadingRemainingData: false,
  actionLoader: false,
  xmlIsLoading: false,
  bmVersion: {},
  bmVersionIsLoading: false,
  bmFileIsLoading: false,
  bmFileLoaded: false,
  bmFileSuccess: false,
  bmCommandLoading: false,
};

const Sensor = (state = initialState, action) => {
  switch (action.type) {
    case BMsActions.GET_BMS_LIST:
    case BMsActions.GET_BMS_LIST_OF_ALL:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    case BMsActions.GET_REMAINING_BMS_LIST:
      return {
        ...state,
        isLoadingRemainingData: true,
        type: action.type,
      };

    case BMsActions.GET_BMS_LIST_SUCCESS:
      const {
        bmsDataList,
        isLoadedEnoughBMsData,
        firstStoresNumberNeedToLoad,
      } = action.payload;
      return {
        ...state,
        bmsDataList,
        isLoadedEnoughBMsData,
        firstStoresNumberNeedToLoad,
        type: action.type,
      };

    case BMsActions.GET_REMAINING_BMS_LIST_SUCCESS:
      const { bmsDataList: bmsDataListUpdated } = action.payload;
      return {
        ...state,
        bmsDataList: bmsDataListUpdated,
        isLoadedEnoughBMsData: true,
        isLoadingRemainingData: false,
        type: action.type,
      };

    case BMsActions.GET_BMS_LIST_OF_ALL_SUCCESS:
    case BMsActions.ADD_NEW_BMS_SUCCESS:
    case BMsActions.UPDATE_NEW_BMS_SUCCESS:
    case BMsActions.DELETE_BM_SUCCESS:
    case BMsActions.TOGGLE_RECONNECT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        BMsList: [...action.payload],
        type: action.type,
      };

    case BMsActions.GET_BMS_LIST_FAILURE:
    case BMsActions.GET_BMS_LIST_OF_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case BMsActions.GET_BMS_LIST_CLEAN_STATE:
    case BMsActions.GET_BMS_LIST_OF_ALL_CLEAN_STATE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case BMsActions.RESET_BMS_LIST_LAZY_LOAD:
      return {
        ...state,
        bmsDataList: [],
        isLoadedEnoughBMsData: false,
        type: action.type,
      };

    case BMsActions.ADD_NEW_BMS:
    case BMsActions.UPDATE_NEW_BMS:
    case BMsActions.DELETE_BM:
      return {
        ...state,
        actionLoader: true,
        type: action.type,
      };
    case BMsActions.ADD_NEW_BMS_FAILURE:
    case BMsActions.UPDATE_NEW_BMS_FAILURE:
    case BMsActions.DELETE_BM_FAILURE:
    case BMsActions.ADD_NEW_BMS_CLEAN_STATE:
    case BMsActions.UPDATE_NEW_BMS_CLEAN_STATE:
    case BMsActions.DELETE_BM_CLEAN_STATE:
      return {
        ...state,
        actionLoader: false,
        type: action.type,
      };
    case BMsActions.LOGIN_ENTER:
      return {
        ...initialState,
        bmsDataList: [],
        BMsList: [],
      };

    case BMsActions.UPLOAD_XML:
      return {
        ...state,
        xmlIsLoading: true,
        type: action.type,
      };
    case BMsActions.UPLOAD_XML_SUCCESS:
    case BMsActions.UPLOAD_XML_FAILURE:
    case BMsActions.UPLOAD_XML_CLEAN_STATE:
      return {
        ...state,
        xmlIsLoading: false,
        type: action.type,
      };

    case BMsActions.EXPORT_DEVICE_SENSORS:
      return {
        ...state,
        isExportingSensors: true,
        type: action.type,
      };
    case BMsActions.EXPORT_DEVICE_SENSORS_SUCCESS:
    case BMsActions.EXPORT_DEVICE_SENSORS_FAILURE:
    case BMsActions.EXPORT_DEVICE_SENSORS_CLEAN_STATE:
      return {
        ...state,
        isExportingSensors: false,
        type: action.type,
      };

    case BMsActions.ADD_DEPARTMENT_MANAGER_SUCCESS:
      return {
        ...state,
        BMsList: state.BMsList.map((x) =>
          action.payload?.bms?.includes(x.deviceId)
            ? { ...x, manager: { id: action.payload?.id } }
            : x
        ),
        type: action.type,
      };
    case BMsActions.DELETE_DEPARTMENT_MANAGER_SUCCESS:
      return {
        ...state,
        BMsList: state.BMsList.map((x) =>
          x.manager?.id === action.payload ? { ...x, manager: null } : x
        ),
        type: action.type,
      };
    case BMsActions.ADD_STORE_SUCCESS:
      return {
        ...state,
        BMsList: state.BMsList.map((x) =>
          action.payload?.bms?.includes(x.deviceId)
            ? { ...x, store: { id: action.payload?.id } }
            : x
        ),
        type: action.type,
      };
    case BMsActions.DELETE_STORE_SUCCESS:
      return {
        ...state,
        BMsList: state.BMsList.map((x) =>
          x.manager?.id === action.payload ? { ...x, store: null } : x
        ),
        type: action.type,
      };

    case BMsActions.UPDATE_BM_NICKNAME:
      return {
        ...state,
        type: action.type,
        isLoading: true,
      };
    case BMsActions.UPDATE_BM_NICKNAME_SUCCESS:
      return {
        ...state,
        type: action.type,
        isLoading: false,
        BMsList: [...action.payload],
      };
    case BMsActions.UPDATE_BM_NICKNAME_FAILURE:
    case BMsActions.UPDATE_BM_NICKNAME_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        isLoading: false,
      };
    case BMsActions.BM_OTA_GET_VERSION:
    case BMsActions.BM_OTA_GET_COMMAND_VERSION:
      return {
        ...state,
        bmVersion: {},
        bmVersionIsLoading: true,
      };
    case BMsActions.BM_OTA_GET_VERSION_SUCCESS:
    case BMsActions.BM_OTA_GET_COMMAND_VERSION_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        bmVersion: action.payload,
        bmVersionIsLoading: false,
      };
    case BMsActions.BM_OTA_GET_VERSION_FAILURE:
    case BMsActions.BM_OTA_GET_COMMAND_VERSION_FAILURE:
      return {
        ...state,
        bmVersion: { error: true },
        bmVersionIsLoading: false,
      };
    case BMsActions.BM_OTA_GET_VERSION_CLEAN_STATE:
    case BMsActions.BM_OTA_GET_COMMAND_VERSION_CLEAN_STATE:
      return {
        ...state,
        bmVersion: {},
        bmVersionIsLoading: false,
      };
    case BMsActions.BM_OTA_SEND_BINFILE:
      return {
        ...state,
        bmFileIsLoading: true,
        bmFileLoaded: false,
        bmFileSuccess: false,
      };
    case BMsActions.BM_OTA_SEND_BINFILE_SUCCESS:
      return {
        ...state,
        bmFileIsLoading: false,
        bmFileLoaded: true,
        bmFileSuccess: true,
      };
    case BMsActions.BM_OTA_SEND_BINFILE_FAILURE:
      return {
        ...state,
        bmFileIsLoading: false,
        bmFileLoaded: true,
        bmFileSuccess: false,
      };
    case BMsActions.BM_OTA_SEND_BINFILE_CLEAN_STATE:
      return {
        ...state,
        bmFileIsLoading: false,
        bmFileLoaded: false,
        bmFileSuccess: false,
      };
    case BMsActions.BM_OTA_SEND_COMMAND:
      return {
        ...state,
        bmCommandLoading: true,
      };
    case BMsActions.BM_OTA_SEND_COMMAND_SUCCESS:
    case BMsActions.BM_OTA_SEND_COMMAND_FAILURE:
    case BMsActions.BM_OTA_SEND_COMMAND_CLEAN_STATE:
      return {
        ...state,
        bmCommandLoading: false,
      };

    default:
      return state;
  }
};

export default Sensor;
