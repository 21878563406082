import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";

// Load Amplify
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { APP_CHECK_VERSION } from "./store/actions";
import { willCheckVersion } from "./helpers/Utils";
Amplify.configure(awsconfig);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">loading...</div>
  </div>
);

// Login Pages
const ManagementLogin = React.lazy(() => import("./views/pages/login/Login"));
const OnsiteLogin = React.lazy(() => import("./views/pages/login/OnsiteLogin"));
const HaccpLogin = React.lazy(() => import("./views/pages/login/HaccpLogin"));

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const AuthRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);

let checkVersionInterval;

class App extends Component {
  componentDidMount() {
    if (willCheckVersion()) {
      this.props.checkVersion();
    }
    checkVersionInterval = setInterval(this.props.checkVersion, 180000); // 3 minutes
  }

  componentWillUnmount() {
    if (checkVersionInterval) {
      clearInterval(checkVersionInterval);
    }
  }

  render() {
    const { user } = this.props;
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <AuthRoute
              exact
              path="/management/login"
              name="Login Page"
              auth={user.user}
              component={ManagementLogin}
            />
            <AuthRoute
              exact
              path="/onsite/login"
              name="Login Page"
              auth={user.user}
              component={OnsiteLogin}
            />
            <AuthRoute
              exact
              path="/haccp/login"
              name="Login Page"
              auth={user.user}
              component={HaccpLogin}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkVersion: () => dispatch({ type: APP_CHECK_VERSION }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
