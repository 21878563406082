import * as StoreActions from "../actions";

export const fetchStores = () => ({
  type: StoreActions.GET_STORES,
});
export const fetchStoresSuccess = (payload) => ({
  type: StoreActions.GET_STORES_SUCCESS,
  payload,
});
export const fetchStoresFailure = (payload) => ({
  type: StoreActions.GET_STORES_FAILURE,
  payload,
});

export const fetchDepartmentStores = (payload) => ({
  type: StoreActions.GET_DEPARTMENT_STORES,
  payload,
});
export const fetchDepartmentStoresSuccess = (payload) => ({
  type: StoreActions.GET_DEPARTMENT_STORES_SUCCESS,
  payload,
});
export const fetchDepartmentStoresFailure = (payload) => ({
  type: StoreActions.GET_DEPARTMENT_STORES_FAILURE,
  payload,
});

// Fetch store details
export const getStoreSettings = () => ({
  type: StoreActions.GET_STORE_SETTINGS,
});
export const getStoreSettingsSuccess = (payload) => ({
  type: StoreActions.GET_STORE_SETTINGS_SUCCESS,
  payload,
});
export const getStoreSettingsFailure = () => ({
  type: StoreActions.GET_STORE_SETTINGS_FAILURE,
});

export const addStore = (payload) => ({
  type: StoreActions.ADD_STORE,
  payload,
});
export const addStoreSuccess = (payload) => ({
  type: StoreActions.ADD_STORE_SUCCESS,
  payload,
});
export const addStoreFailure = (payload) => ({
  type: StoreActions.ADD_STORE_FAILURE,
  payload,
});

export const updateStore = (id, data) => ({
  type: StoreActions.UPDATE_STORE,
  payload: { id, data },
});
export const updateStoreSuccess = (payload) => ({
  type: StoreActions.UPDATE_STORE_SUCCESS,
  payload,
});
export const updateStoreFailure = (payload) => ({
  type: StoreActions.UPDATE_STORE_FAILURE,
  payload,
});

export const deleteStore = (payload) => ({
  type: StoreActions.DELETE_STORE,
  payload,
});
export const deleteStoreSuccess = (payload) => ({
  type: StoreActions.DELETE_STORE_SUCCESS,
  payload,
});
export const deleteStoreFailure = (payload) => ({
  type: StoreActions.DELETE_STORE_FAILURE,
  payload,
});

export const updateStoreSettings = (payload) => ({
  type: StoreActions.UPDATE_STORE_SETTINGS,
  payload,
});

export const updateStoreSettingsSuccess = (payload) => ({
  type: StoreActions.UPDATE_STORE_SETTINGS_SUCCESS,
  payload,
});

export const updateStoreSettingsFailure = (payload) => ({
  type: StoreActions.UPDATE_STORE_SETTINGS_FAILURE,
  payload,
});

export const requestPauseAlert = (payload) => ({
  type: StoreActions.REQUEST_PAUSE_ALERT,
  payload,
});

export const pauseAlertSuccess = (payload) => ({
  type: StoreActions.PAUSE_ALERT_SUCCESS,
  payload,
});

export const pauseAlertFailure = (payload) => ({
  type: StoreActions.PAUSE_ALERT_FAILURE,
  payload,
});

export const requestSendTemplates = (payload) => ({
  type: StoreActions.SEND_MAIL_TEMPLATES,
  payload,
});

export const sendTemplatesSuccess = (payload) => ({
  type: StoreActions.SEND_MAIL_TEMPLATES_SUCCESS,
  payload,
});

export const sendTemplatesFailure = (payload) => ({
  type: StoreActions.SEND_MAIL_TEMPLATES_FAILED,
  payload,
});

export const getStoreUsers = (payload) => ({
  type: StoreActions.GET_STORE_USERS,
  payload,
});

export const getStoreUsersSuccess = (payload) => ({
  type: StoreActions.GET_STORE_USERS_SUCCESS,
  payload,
});

export const getStoreUsersFailure = () => ({
  type: StoreActions.GET_STORE_USERS_FAILURE,
});

export const addStoreUsers = (payload) => ({
  type: StoreActions.ADD_STORE_USERS,
  payload,
});

export const addStoreUsersSuccess = (payload) => ({
  type: StoreActions.ADD_STORE_USERS_SUCCESS,
  payload,
});

export const addStoreUsersFailure = () => ({
  type: StoreActions.ADD_STORE_USERS_FAILURE,
});

export const updateStoreUsers = (payload) => ({
  type: StoreActions.UPDATE_STORE_USERS,
  payload,
});

export const updateStoreUsersSuccess = (payload) => ({
  type: StoreActions.UPDATE_STORE_USERS_SUCCESS,
  payload,
});

export const updateStoreUsersFailure = (payload) => ({
  type: StoreActions.UPDATE_STORE_USERS_FAILURE,
  payload,
});

export const deleteStoreUsers = (payload) => ({
  type: StoreActions.DELETE_STORE_USERS,
  payload,
});

export const deleteStoreUsersSuccess = (payload) => ({
  type: StoreActions.DELETE_STORE_USERS_SUCCESS,
  payload,
});

export const deleteStoreUsersFailure = (payload) => ({
  type: StoreActions.DELETE_STORE_USERS_FAILURE,
});

export const getStoreLogs = (payload) => ({
  type: StoreActions.GET_STORE_LOGS,
  payload,
});

export const getStoreLogsSuccess = (payload) => ({
  type: StoreActions.GET_STORE_LOGS_SUCCESS,
  payload,
});

export const getStoreLogsFailure = (payload) => ({
  type: StoreActions.GET_STORE_LOGS_FAILURE,
  payload,
});

export const toggleSummaryNotification = (payload) => ({
  type: StoreActions.TOGGLE_SUMMARY_NOTIFICATION,
  payload,
});

export const toggleSummaryNotificationSuccess = (payload) => ({
  type: StoreActions.TOGGLE_SUMMARY_NOTIFICATION_SUCCESS,
  payload,
});

export const toggleSummaryNotificationFailure = (payload) => ({
  type: StoreActions.TOGGLE_SUMMARY_NOTIFICATION_FAILURE,
  payload,
});