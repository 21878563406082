import * as BMsActions from "../actions";

// Single Bm list for department
export const fetBMsList = () => ({
  type: BMsActions.GET_BMS_LIST,
});
export const fetRemainingBMsList = (data) => ({
  type: BMsActions.GET_REMAINING_BMS_LIST,
  payload: data,
});
export const fetRemainingBMsListSuccess = (data) => ({
  type: BMsActions.GET_REMAINING_BMS_LIST_SUCCESS,
  payload: data,
});
export const fetRemainingBMsListFailed = () => ({
  type: BMsActions.GET_REMAINING_BMS_LIST_FAILURE,
});
export const fetRemainingBMsListCleanState = () => ({
  type: BMsActions.GET_REMAINING_BMS_LIST_CLEAN_STATE,
});
export const resetBMsListLazyLoad = () => ({
  type: BMsActions.RESET_BMS_LIST_LAZY_LOAD,
});
export const setBMsListSuccess = (data) => ({
  type: BMsActions.GET_BMS_LIST_SUCCESS,
  payload: data,
});
export const setBMsListFailure = () => ({
  type: BMsActions.GET_BMS_LIST_FAILURE,
});
export const setBMsListCleanState = () => ({
  type: BMsActions.GET_BMS_LIST_CLEAN_STATE,
});

// Single Bm list for store (department manager or store user)
export const fetchStoreDevices = (storeId) => ({
  type: BMsActions.GET_STORE_BMS,
  payload: storeId
});
export const fetchStoreDevicesSuccess = (data) => ({
  type: BMsActions.GET_STORE_BMS_SUCCESS,
  payload: data,
});
export const fetchStoreDevicesFailure = () => ({
  type: BMsActions.GET_STORE_BMS_FAILURE,
});
export const fetchStoreDevicesCleanState = () => ({
  type: BMsActions.GET_STORE_BMS_CLEAN_STATE,
});

// Bm list all for Super admin
export const fetBMsListForAll = (selectedCompanyId = "") => ({
  type: BMsActions.GET_BMS_LIST_OF_ALL,
  payload: { selectedCompanyId },
});
export const setBMsListForAllSuccess = (data) => ({
  type: BMsActions.GET_BMS_LIST_OF_ALL_SUCCESS,
  payload: data,
});
export const setBMsListForAllFailure = () => ({
  type: BMsActions.GET_BMS_LIST_OF_ALL_FAILURE,
});
export const setBMsListForAllCleanState = () => ({
  type: BMsActions.GET_BMS_LIST_OF_ALL_CLEAN_STATE,
});

export const addNewBMs = (data, callback = () => {}) => ({
  type: BMsActions.ADD_NEW_BMS,
  payload: data,
  callback,
});
export const addNewBMsSuccess = (data) => ({
  type: BMsActions.ADD_NEW_BMS_SUCCESS,
  payload: data,
});
export const addNewBMsFailure = () => ({
  type: BMsActions.ADD_NEW_BMS_FAILURE,
});
export const addNewBMsCleanState = () => ({
  type: BMsActions.ADD_NEW_BMS_CLEAN_STATE,
});

export const updateNewBMs = (data) => ({
  type: BMsActions.UPDATE_NEW_BMS,
  payload: data,
});
export const updateNewBMsSuccess = (data) => ({
  type: BMsActions.UPDATE_NEW_BMS_SUCCESS,
  payload: data,
});
export const updateNewBMsFailure = () => ({
  type: BMsActions.UPDATE_NEW_BMS_FAILURE,
});
export const updateNewBMsCleanState = () => ({
  type: BMsActions.UPDATE_NEW_BMS_CLEAN_STATE,
});

export const deleteBm = (data) => ({
  type: BMsActions.DELETE_BM,
  payload: data,
});
export const deleteBmSuccess = (data) => ({
  type: BMsActions.DELETE_BM_SUCCESS,
  payload: data,
});

export const deleteBmFailure = () => ({
  type: BMsActions.DELETE_BM_FAILURE,
});

export const deleteBmCleanState = () => ({
  type: BMsActions.DELETE_BM_CLEAN_STATE,
});

export const uploadXml = (bm, xmlData) => ({
  type: BMsActions.UPLOAD_XML,
  payload: { bm, xmlData },
});

export const uploadXmlSuccess = () => ({
  type: BMsActions.UPLOAD_XML_SUCCESS,
});

export const uploadXmlFailure = () => ({
  type: BMsActions.UPLOAD_XML_FAILURE,
});

export const uploadXmlCleanState = () => ({
  type: BMsActions.UPLOAD_XML_CLEAN_STATE,
});

export const exportDeviceSensors = (deviceId) => ({
  type: BMsActions.EXPORT_DEVICE_SENSORS,
  payload: deviceId,
});

export const exportDeviceSensorsSuccess = () => ({
  type: BMsActions.EXPORT_DEVICE_SENSORS_SUCCESS,
});

export const exportDeviceSensorsFailure = () => ({
  type: BMsActions.EXPORT_DEVICE_SENSORS_FAILURE,
});

export const exportDeviceSensorsCleanState = () => ({
  type: BMsActions.EXPORT_DEVICE_SENSORS_CLEAN_STATE,
});

export const uploadMapBackground = (bmId, file) => ({
  type: BMsActions.UPLOAD_MAP_BACKGROUND,
  payload: { bmId, file },
});

export const uploadMapBackgroundSuccess = () => ({
  type: BMsActions.UPLOAD_MAP_BACKGROUND_SUCCESS,
});

export const uploadMapBackgroundFailure = () => ({
  type: BMsActions.UPLOAD_MAP_BACKGROUND_FAILURE,
});

export const uploadMapBackgroundCleanState = () => ({
  type: BMsActions.UPLOAD_MAP_BACKGROUND_CLEAN_STATE,
});

export const updateBmNickname = (data) => ({
  type: BMsActions.UPDATE_BM_NICKNAME,
  payload: data,
});
export const updateBmNicknameSuccess = (data) => ({
  type: BMsActions.UPDATE_BM_NICKNAME_SUCCESS,
  payload: data,
});
export const updateBmNicknameFailure = () => ({
  type: BMsActions.UPDATE_BM_NICKNAME_FAILURE,
});
export const updateBmNicknameCleanState = () => ({
  type: BMsActions.UPDATE_BM_NICKNAME_CLEAN_STATE,
});

export const getBmOtaVersion = (bmId) => ({
  type: BMsActions.BM_OTA_GET_VERSION,
  payload: { bmId }
});

export const getBmOtaVersionSuccess = (versions) => ({
  type: BMsActions.BM_OTA_GET_VERSION_SUCCESS,
  payload: versions
});

export const getBmOtaVersionFailure = () => ({
  type: BMsActions.BM_OTA_GET_VERSION_FAILURE,
});

export const getBmOtaVersionCleanState = () => ({
  type: BMsActions.BM_OTA_GET_VERSION_CLEAN_STATE,
});

export const getBmOtaVersionCommand = (bmId) => ({
  type: BMsActions.BM_OTA_GET_COMMAND_VERSION,
  payload: { bmId }
});

export const getBmOtaVersionCommandSuccess = (versions) => ({
  type: BMsActions.BM_OTA_GET_COMMAND_VERSION_SUCCESS,
  payload: versions
});

export const getBmOtaVersionCommandFailure = () => ({
  type: BMsActions.BM_OTA_GET_COMMAND_VERSION_FAILURE,
});

export const getBmOtaVersionCommandCleanState = () => ({
  type: BMsActions.BM_OTA_GET_COMMAND_VERSION_CLEAN_STATE,
});

export const sendBmOtaCommand = (bmId, namespace, action, params = {}, waitForEvent = false) => ({
  type: BMsActions.BM_OTA_SEND_COMMAND,
  payload: { bmId, namespace, action, params, waitForEvent }
});

export const sendBmOtaCommandSuccess = (versions) => ({
  type: BMsActions.BM_OTA_SEND_COMMAND_SUCCESS,
  payload: versions
});

export const sendBmOtaCommandFailure = () => ({
  type: BMsActions.BM_OTA_SEND_COMMAND_FAILURE,
});

export const sendBmOtaCommandCleanState = () => ({
  type: BMsActions.BM_OTA_SEND_COMMAND_CLEAN_STATE,
});

export const sendBmOtaBinfile = (bmId, name, file) => ({
  type: BMsActions.BM_OTA_SEND_BINFILE,
  payload: { bmId, name, file }
});

export const sendBmOtaBinfileSuccess = () => ({
  type: BMsActions.BM_OTA_SEND_BINFILE_SUCCESS,
});

export const sendBmOtaBinfileFailure = () => ({
  type: BMsActions.BM_OTA_SEND_BINFILE_FAILURE,
});

export const sendBmOtaBinfileCleanState = () => ({
  type: BMsActions.BM_OTA_SEND_BINFILE_CLEAN_STATE,
});

export const toggleReconnectNotification = (payload) => ({
  type: BMsActions.TOGGLE_RECONNECT_NOTIFICATION,
  payload
});

export const toggleReconnectNotificationSuccess = (payload) => ({
  type: BMsActions.TOGGLE_RECONNECT_NOTIFICATION_SUCCESS,
  payload
});

export const toggleReconnectNotificationFailure = () => ({
  type: BMsActions.TOGGLE_RECONNECT_NOTIFICATION_FAILURE
});

// new BM with Mouse Click (EDIT)
export const modifyMlList = (payload) => ({
  type: BMsActions.MODIFY_SL_LIST,
  payload
});
// new BM with Mouse Click (EDIT)