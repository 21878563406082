export const getUserData = () => {
  return JSON.parse(localStorage.getItem("HACCPAppUserData"));
};
export const getUserToken = () => {
  return localStorage.getItem("userToken");
};

export const setUserData = (data) => {
  localStorage.setItem("HACCPAppUserData", JSON.stringify(data));
};
export const setUserToken = (token) => {
  localStorage.setItem("userToken", `${token}`);
};

export const unsetUserData = () => {
  localStorage.removeItem("HACCPAppUserData");
};
export const unsetUserToken = () => {
  localStorage.removeItem("userToken");
};

export const getReadNews = () => {
  const news = localStorage.getItem("HACCP_News");
  if (!news)
    return null;
  return JSON.parse(news);
};
export const setReadNews = (news) => {
  if (!news) {
    localStorage.removeItem("HACCP_News");
    return;
  }
  return localStorage.setItem("HACCP_News", JSON.stringify(news));
};

export const setOffsetTop = (top) => {
  localStorage.setItem("HACCP_ScrollTo", parseFloat(top));
};
export const getOffsetTop = () => {
  return localStorage.getItem("HACCP_ScrollTo");
};
export const removeOffsetTop = () => {
  return localStorage.removeItem("HACCP_ScrollTo");
};
