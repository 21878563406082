import * as DashboardTypes from "../actions";

export const fetchDashoard = () => ({
  type: DashboardTypes.GET_DASHBOARD,
});

export const setDashboardSuccess = (data) => ({
  type: DashboardTypes.GET_DASHBOARD_SUCCESS,
  data,
});

export const setDashboardFailure = (data) => ({
  type: DashboardTypes.GET_DASHBOARD_FAILURE,
  data,
});

export const setDashboardCleanState = () => ({
  type: DashboardTypes.GET_DASHBOARD_CLEAN_STATE,
});
