import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { APP_CHECK_VERSION, forceLogout } from "../actions";
import * as client from 'src/helpers/HTTPRequest';
import ApiConstants from "../api/ApiConstants";
import {
  getLocalVersion,
  setLocalVersion,
  willCheckVersion,
} from "src/helpers/Utils";

function* checkVersion() {
  try {
    const localVersion = getLocalVersion();
    if (!willCheckVersion()) return

    const url = `${ApiConstants.BASE_URL}${ApiConstants.VERSION}`;
    const response = yield call(client.get, url);

    if (!localVersion || response.version !== localVersion.version) {
      resetCache();
      yield put(forceLogout());
    }
    setLocalVersion(response.version);
  } catch (error) {
    console.log(error);
  }
}

function resetCache() {
  console.log("Reset caches");
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      })
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
}

export function* watchApp() {
  yield takeLatest(APP_CHECK_VERSION, checkVersion);
}

export default function* appSaga() {
  yield all([fork(watchApp)]);
}
