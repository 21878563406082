const currentEnv = process.env.REACT_APP_ENV || "staging";
console.log("currentEnv =>", currentEnv);

const urlHolder = {
  prod: "https://api.iriscloudservice.com/",
  staging: "https://api.iriscloudservice.com/",
  dev: "https://5erx7tipal.execute-api.ap-northeast-1.amazonaws.com/api/",
};

const ApiConstants = {
  BASE_URL: urlHolder[currentEnv],

  VERSION: "version",
  DEVICES: "devices",
  DEVICES_ANALYSIS: "devices/analysis",
  DEVICE_SENSORS: "devices/{:deviceId}/sensors",
  DEVICE_SENSORS_STATUS: "devices/{:deviceId}/sensors-status",
  DEVICE_MANAGE_SENSORS: "devices/{:deviceId}/sensors",
  PRODUCT_SETTINGS: "product-settings",

  SETUP_ADMINS: "setup-admins",
  COMPANIES: "companies",
  SINGLE_COMPANY: "companies/{:companyId}",
  SINGLE_DEVICE: "devices/{:deviceId}",
  SINGLE_DEVICE_IMAGE: "devices/{:deviceId}/image",
  DEVICE_SENSORS_UPDATE: "devices/{:deviceId}/sensors",
  DEVICE_VERSIONS: "devices/{:deviceId}/versions",
  DEVICE_COMMANDS: "devices/{:deviceId}/commands",
  DEVICE_BINFILES: "devices/{:deviceId}/binfiles",
  SINGLE_DEVICE_DATA_REPORT: "devices/{:deviceId}/data/report",
  DEPARTMENTS: "departments",
  DEPARTMENT_MANAGERS: "departments/managers",
  SINGLE_DEPARTMENT_MANAGER: "departments/managers/{:managerId}",
  STORES: "stores",
  SINGLE_STORE: "stores/{:storeId}",

  MONITORING_DISPLAY_DATA: "monitoring/display-data",
  MONITORING_MAINTENANCE_DETAILS: "monitoring/{:storeId}/maintenance-details",
  MONITORING_REFRESH_STORE: "monitoring/{:storeId}/refresh-store",
  SETTINGS: "settings",

  GENERATE_REPORT: "reports/generate",
  DOWNLOAD_REPORT: "reports/download",

  MAIL_GROUPS: "mail-groups",
  MAIL_TEMPLATES: "mail-templates",
  MAIL_HISTORY: "mail-histories",
  MAILS: "mails",

  NOTIFICATIONS: "notifications",
  USER_GUIDE: "user-docs",

  STORE_HEARING: "stores/{:storeId}/files/hearing",
  STORE_SETTINGS: "stores/{:storeId}/files/settings",
  DEVICE_SETTINGS: "devices/{:deviceId}/files",
};

export default ApiConstants;
