import moment from "moment";
import { NotificationManager } from "react-notifications";
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { GenerateGraphData } from "src/helpers/GraphUtils";
import { get } from "src/helpers/HTTPRequest";
import TextConstants from "src/helpers/TextConstants";
import _ from "underscore";
import * as SensorGraphActions from "../actions";
import ApiConstants from "../api/ApiConstants";

function* fetchSensorGraphRawData({ payload }) {
  const { SensorId, bmId, settingsData } = payload;
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS.replace(
      "{:deviceId}",
      bmId
    )}/${SensorId}`;
    const receivedData = yield call(get, url, {}, true);
    const currentSensor = receivedData;

    const promises = [];

    const startDate = settingsData?.fromDate
      ? moment(settingsData.fromDate)
      : moment().local();
    const endDate = settingsData?.toDate
      ? moment(settingsData.toDate)
      : moment().local();

    const currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      const start = currentDate.clone();
      const end = currentDate.clone().add(6, "days").isSameOrBefore(endDate)
        ? currentDate.clone().add(6, "days")
        : endDate;
      promises.push(
        get(
          `${url}/raw-data?start-date=${start.format(
            "YYYY-MM-DD"
          )}&end-date=${end.format("YYYY-MM-DD")}`
        )
      );
      currentDate.add(7, "days");
    }

    const responses = yield Promise.all(promises);

    const plotData = [];

    responses.forEach((response) => {
      plotData.push(...(response.data || []));
    });

    if (plotData.length === 0) {
      NotificationManager.error(TextConstants.NoDataFound, "", 5000);
    }

    // generate dummy graph data
    let sensorGraphData = GenerateGraphData(
      currentSensor,
      _.sortBy(plotData, "timestamp")
      // _.sortBy([], "timestamp") // just to check dummy plot data
    );

    let sensorList = yield select((state) => state.sensor.sensorDataCopy);
    if (sensorList.length === 0) {
      sensorList = yield call(
        get,
        `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS_STATUS.replace(
          "{:deviceId}",
          bmId
        )}`
      );
    }
    const getCurrentSensorFromTheList = sensorList.find(
      (item) => item.sensorId === SensorId
    );

    yield put(
      SensorGraphActions.setSensorGraphDataSuccess({
        currentSensor: {
          ...currentSensor,
          ...(getCurrentSensorFromTheList && {
            currentTemp: getCurrentSensorFromTheList.currentTemp,
            lastInteractedAt: getCurrentSensorFromTheList.lastInteractedAt,
            lastUpdate: getCurrentSensorFromTheList.lastUpdate,
            status: getCurrentSensorFromTheList.status,
            isDF: getCurrentSensorFromTheList.isDF,
          }),
          // isWired field to display in sensor settings modal should be from sensorSettings, it is not from last data
          isWired: currentSensor.isWired,
        },
        sensorGraphData,
        settingsData,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(SensorGraphActions.setSensorGraphDataFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorGraphActions.setSensorGraphDataCleanState());
  }
}

function* getProductSettings() {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.PRODUCT_SETTINGS}`;
    const receivedData = yield call(get, url);
    yield put(SensorGraphActions.getProductSettingsSuccess(receivedData));
  } catch (error) {
    yield put(SensorGraphActions.getProductSettingsFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* fetchSensorGraphDailyData({ payload }) {
  const { SensorId, bmId, settingsData } = payload;
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS.replace(
      "{:deviceId}",
      bmId
    )}/${SensorId}`;

    // daily data from sensors
    let fromDate = moment().local().subtract(30, "days").format("YYYY-MM-DD");
    let toDate = moment().local().format("YYYY-MM-DD");
    if (settingsData && settingsData.fromDate && settingsData.toDate) {
      fromDate = settingsData.fromDate;
      toDate = settingsData.toDate;
    }

    const response = yield call(
      get,
      `${url}/daily-data?start-date=${fromDate}&end-date=${toDate}`
    );

    const plotData = response.data || {};

    var sensorGraphData = [];

    for (const [date, data] of Object.entries(plotData || {})) {
      sensorGraphData.push([
        new Date(date),
        data.sm / data.ct,
        data.mn,
        data.mx,
      ]);
    }

    // Sort data by date
    sensorGraphData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    console.log("sensorGraphData", sensorGraphData);

    if (sensorGraphData.length > 0) {
      yield put(
        SensorGraphActions.setSensorGraphDataDailySuccess({
          sensorGraphData,
          settingsData,
        })
      );
    } else {
      NotificationManager.error(TextConstants.NoDataFound, "", 5000);
      yield put(SensorGraphActions.setSensorGraphDataDailyFailure());
    }
  } catch (error) {
    yield put(SensorGraphActions.setSensorGraphDataDailyFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorGraphActions.setSensorGraphDataDailyCleanState());
  }
}

function* letDownLoadGraphData({ payload }) {
  const { SensorId, bmId, settingsData } = payload;
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS.replace(
      "{:deviceId}",
      bmId
    )}/${SensorId}`;

    // daily data from sensors
    let fromDate = moment().local().subtract(30, "days").format("YYYY-MM-DD");
    let toDate = moment().local().format("YYYY-MM-DD");
    if (settingsData && settingsData.fromDate && settingsData.toDate) {
      fromDate = settingsData.fromDate;
      toDate = settingsData.toDate;
    }

    const selectorePath =
      settingsData.displayType === "daily" ? "daily" : "raw";

    const response = yield call(
      get,
      `${url}/${selectorePath}-data?start-date=${fromDate}&end-date=${toDate}`
    );

    const plotData = response.data || {};

    let currentSensor = yield select((state) => state.sensor.currentSensor);

    var sensorGraphData = [];

    if (selectorePath === "raw") {
      sensorGraphData = GenerateGraphData(
        currentSensor,
        _.sortBy(plotData, "timestamp")
      );
    } else {
      for (const [date, data] of Object.entries(plotData || {})) {
        sensorGraphData.push([
          new Date(date),
          data.sm / data.ct,
          data.mn,
          data.mx,
        ]);
      }
    }

    if (sensorGraphData.length > 0) {
      yield put(
        SensorGraphActions.letDownloadRequestedDataSuccess(sensorGraphData)
      );
    } else {
      NotificationManager.error(TextConstants.NoDataFound, "", 5000);
      yield put(SensorGraphActions.letDownloadRequestedDataFailure());
    }
  } catch (error) {
    yield put(SensorGraphActions.letDownloadRequestedDataFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorGraphActions.letDownloadRequestedDataCleanState());
  }
}

function* getSensorSetting({ payload }) {
  try {
    const { bmId, sensorId } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS.replace(
      "{:deviceId}",
      bmId
    )}/${sensorId}`;
    const sensorSettings = yield call(get, url);
    yield put(SensorGraphActions.getSensorSettingSuccess(sensorSettings));
  } catch (error) {
    yield put(SensorGraphActions.getSensorSettingFailure());
  } finally {
    yield put(SensorGraphActions.getSensorSettingCleanState());
  }
}

export function* watchSensorGraph() {
  yield takeEvery(
    SensorGraphActions.GET_SENSOR_GRAPH_DATA,
    fetchSensorGraphRawData
  );
  yield takeEvery(SensorGraphActions.GET_PRODUCT_SETTINGS, getProductSettings);
  yield takeEvery(
    SensorGraphActions.GET_SENSOR_GRAPH_DATA_DAILY,
    fetchSensorGraphDailyData
  );
  yield takeEvery(
    SensorGraphActions.LET_DOWNLOAD_REQUESTED_DATA,
    letDownLoadGraphData
  );
  yield takeLatest(SensorGraphActions.GET_SENSOR_SETTING, getSensorSetting);
}

export default function* sensorListSaga() {
  yield all([fork(watchSensorGraph)]);
}
