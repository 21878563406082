import saveAs from "file-saver";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import * as requests from "src/helpers/HTTPRequest";
import { getUserToken } from "src/helpers/StorageUtils";
import TextConstants from "src/helpers/TextConstants";
import * as BMsActions from "../actions";
import ApiConstants from "../api/ApiConstants";

//////////////////////////////////////////////////
// AWSのLambdaからBMとMLの情報Fetch
//////////////////////////////////////////////////
function* modifySlList({ payload }) {
  let isError = false;
  let getPayload = payload.payload;
  try {
    let url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${getPayload.bmId}`;
    let body = { positions: getPayload.positions, mls: getPayload.mls };
    yield call(requests.post, url, body);
  } catch {
    isError = true;
    NotificationManager.error(
      TextConstants.PositionUpdateFailureBMML,
      "",
      5000
    );
  } finally {
    if (!isError) {
      NotificationManager.success(
        TextConstants.PositionUpdateSuccessBMML,
        "",
        5000
      );
      let selectedStore = JSON.parse(localStorage.getItem("SelectedStore"));
      for (let i = 0; i < selectedStore.bmDetails.length; i++) {
        if (getPayload.bmId === selectedStore.bmDetails[i].deviceId) {
          selectedStore.bmDetails[i].positions = getPayload.positions;
          selectedStore.bmDetails[i].mls = getPayload.mls;
        }
      }
      localStorage.setItem("SelectedStore", JSON.stringify(selectedStore));
    }
  }
}
//////////////////////////////////////////////////

function* fetBMsList() {
  try {
    const userType = yield select((state) => state.auth.userType);
    const hasSelectedStore = localStorage.getItem("SelectedStore");

    let storeId;
    // check if storage has selected store
    if (hasSelectedStore) {
      const SelectedStore = JSON.parse(hasSelectedStore);
      storeId = SelectedStore.id;
    }

    let queryParams = {};
    if (userType === "SA" && storeId) {
      queryParams.storeId = storeId;
    }
    const queryString = `?${new URLSearchParams(queryParams).toString()}`;
    const BMListUrl = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES_ANALYSIS}${queryString}`;
    const { bmsDataList, firstStoresNumberNeedToLoad } = yield call(
      requests.get,
      BMListUrl
    );
    const isLoadedEnoughBMsData =
      bmsDataList && bmsDataList.length <= firstStoresNumberNeedToLoad;
    yield put(
      BMsActions.setBMsListSuccess({
        bmsDataList,
        isLoadedEnoughBMsData,
        firstStoresNumberNeedToLoad,
      })
    );

    // check if storage has selected store
    if (hasSelectedStore) {
      const SelectedStore = JSON.parse(hasSelectedStore);
      const storeTobeUpdate = bmsDataList.find(
        (item) => item.id === SelectedStore.id
      );
      if (storeTobeUpdate) {
        localStorage.setItem("SelectedStore", JSON.stringify(storeTobeUpdate));
        // update the selected store's BMs list
        yield put(BMsActions.setStoreForSensor(storeTobeUpdate));
      }
    }
  } catch (error) {
    yield put(BMsActions.setBMsListFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(BMsActions.setBMsListCleanState());
  }
}

function* fetRemainingBMsList({ payload }) {
  const { batchStoreIds } = payload;

  try {
    const userType = yield select((state) => state.auth.userType);
    const hasSelectedStore = localStorage.getItem("SelectedStore");
    let storeId;
    // check if storage has selected store
    if (hasSelectedStore) {
      const SelectedStore = JSON.parse(hasSelectedStore);
      storeId = SelectedStore.id;
    }

    let queryParams = {};
    if (userType === "SA" && storeId) {
      queryParams.storeId = storeId;
    }
    const queryString = `?${new URLSearchParams(queryParams).toString()}`;
    const BMListUrl = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES_ANALYSIS}${queryString}`;

    let batchGetStoreIdsURL = [];
    batchStoreIds.forEach((storeIds) => {
      batchGetStoreIdsURL.push(
        `${BMListUrl}&storeIds=${JSON.stringify(storeIds)}`
      );
    });
    if (batchGetStoreIdsURL.length) {
      const results = yield all(
        batchGetStoreIdsURL.map((url) => {
          return call(requests.get, url);
        })
      );
      const { bmsDataList: oldBmsDataList } = yield select(
        (state) => state.bms
      );
      let bmsDataObject = oldBmsDataList.reduce(
        (obj, cur) => ({ ...obj, [cur.id]: cur }),
        {}
      );
      results.forEach((storesData) => {
        bmsDataObject = {
          ...bmsDataObject,
          ...storesData.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), {}),
        };
      });
      yield put(
        BMsActions.fetRemainingBMsListSuccess({
          bmsDataList: Object.values(bmsDataObject),
        })
      );
    }
  } catch (error) {
    yield put(BMsActions.fetRemainingBMsListFailed());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* fetchStoreBMs({ payload: storeId }) {
  try {
    if (!storeId) {
      const hasSelectedStore = localStorage.getItem("SelectedStore");

      // check if storage has selected store
      if (hasSelectedStore) {
        const SelectedStore = JSON.parse(hasSelectedStore);
        storeId = SelectedStore.id;
      } else {
        const {
          user: { attributes },
        } = yield select((state) => state.auth);
        storeId = attributes["custom:store_id"];
      }
    }

    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES}/${storeId}/${ApiConstants.DEVICES}`;
    const bms = yield call(requests.get, url);

    yield put(BMsActions.fetchStoreDevicesSuccess({
      bms: bms.map(x => x.deviceId),
      bmDetails: bms,
      storeId: storeId,
    }));
  } catch (error) {
    yield put(BMsActions.fetchStoreDevicesFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(BMsActions.fetchStoreDevicesCleanState());
  }
}

function* fetBMsListOfAll({ payload }) {
  const { selectedCompanyId } = payload;
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}`;
    let data = yield call(requests.get, url);

    if (selectedCompanyId) {
      data = data.filter((itm) => itm.Company === selectedCompanyId);
    }

    data = data.map((bm) => ({ ...bm, isEditable: false, notifyOnReconnect: bm?.notifyOnReconnect || false }));

    yield put(BMsActions.setBMsListForAllSuccess(data));
  } catch (error) {
    yield put(BMsActions.setBMsListForAllFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(BMsActions.setBMsListForAllCleanState());
  }
}

function* addNewBms({ payload, callback }) {
  try {
    const { deviceId, companyId } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}`;
    const response = yield call(
      requests.post,
      url,
      { deviceId, companyId }
    );
    const bmList = yield select((state) => state.bms.BMsList);
    const companyList = yield select((state) => state.company.companies);
    for (let i = 0, n = bmList.length; i < n; i++) {
      if (bmList[i].deviceId === response.deviceId) {
        const company = companyList.find((c) => c.id === response.companyId);
        bmList[i] = {
          id: response.id,
          deviceId: response.deviceId,
          company,
          departmentId: null,
          storeId: null,
        };
        break;
      }
    }
    yield put(BMsActions.addNewBMsSuccess(bmList));

    callback && callback();
  } catch (error) {
    yield put(BMsActions.addNewBMsFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(BMsActions.addNewBMsCleanState());
  }
}

function* updateNewBms({ payload }) {
  try {
    const { deviceId, companyId } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${payload.deviceId}`;
    const response = yield call(
      requests.put,
      url,
      { deviceId, companyId }
    );

    const bmList = yield select((state) => state.bms.BMsList);
    const companyList = yield select((state) => state.company.companies);

    for (let i = 0, n = bmList.length; i < n; i++) {
      if (bmList[i].deviceId === response.deviceId) {
        const company = companyList.find((c) => c.id === response.companyId);
        bmList[i] = {
          id: response.id,
          deviceId: response.deviceId,
          company,
          departmentId: null,
          storeId: null,
        };
        break;
      }
    }
    yield put(BMsActions.updateNewBMsSuccess(bmList));
  } catch (error) {
    yield put(BMsActions.updateNewBMsFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(BMsActions.updateNewBMsCleanState());
  }
}

function* deleteBm({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${payload.deviceId}`;
    yield call(requests.deleteMethod, url);
    const data = yield select((state) => state.bms.BMsList);
    yield put(
      BMsActions.deleteBmSuccess(
        data.filter((item) => item.deviceId !== payload.deviceId)
      )
    );
    NotificationManager.success(TextConstants.BmDeletedSuccess, "", 5000);
  } catch (e) {
    NotificationManager.error(TextConstants.BmDeletedError, "", 5000);
    yield put(BMsActions.deleteBmFailure());
  } finally {
    yield put(BMsActions.deleteBmCleanState());
  }
}

function* toggleReconnectNotification({ payload }) {
  try {
    const { deviceId, notifyOnReconnect } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${deviceId}`;
    yield call(requests.patch, url, { notifyOnReconnect });
    const data = yield select((state) => state.bms.BMsList);
    yield put(
      BMsActions.toggleReconnectNotificationSuccess(data.map((item) => item.deviceId === deviceId ? ({ ...item, notifyOnReconnect }) : item))
    );
    NotificationManager.success(TextConstants.ToggleReconnectNotificationSuccess, "", 5000);
  } catch (e) {
    NotificationManager.error(TextConstants.ToggleReconnectNotificationError, "", 5000);
  }
}

function* uploadXml({ payload }) {
  const { bm, xmlData } = payload;
  try {
    const url = `${ApiConstants.BASE_URL}xml-files/${bm}`;
    yield call(sendXmlRequest, url, xmlData);
    NotificationManager.success(TextConstants.XmlUploadSuccess, "", 5000);
    yield put(BMsActions.uploadXmlSuccess());
  } catch (e) {
    console.log(e);
    NotificationManager.error(TextConstants.XmlUploadError, "", 5000);
    yield put(BMsActions.uploadXmlFailure());
  } finally {
    yield put(BMsActions.uploadXmlCleanState());
  }
}

function sendXmlRequest(url, file) {
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      "Content-Type": "application/xml",
    },
    body: file,
  };
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response.arrayBuffer());
      })
      .catch((e) => reject(e));
  });
}

function* exportDeviceSensors({ payload: deviceId }) {
  try {
    const url = `${ApiConstants.BASE_URL}xml-files/${deviceId}`;
    const response = yield call(exportSensorsXml, url);
    saveAs(response, `${deviceId}_${moment().valueOf()}.xml`);
    yield put(BMsActions.exportDeviceSensorsSuccess());
  } catch (e) {
    console.log(e);
    NotificationManager.error(TextConstants.ExportSensorsError, "", 5000);
    yield put(BMsActions.exportDeviceSensorsFailure());
  } finally {
    yield put(BMsActions.exportDeviceSensorsCleanState());
  }
}

function exportSensorsXml(url) {
  return new Promise((resolve, reject) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    };
    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response.blob());
      })
      .catch((e) => reject(e));
  });
}

function* uploadBmMapBackground({ payload }) {
  try {
    const { bmId, file } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.SINGLE_DEVICE_IMAGE}`.replace('{:deviceId}', bmId);
    const response = yield call(requests.post, url);
    yield call(uploadMapBackground, response, file);
    NotificationManager.success(
      TextConstants.UpdateMapBackgroundSuccess,
      "",
      5000
    );
    yield put(BMsActions.uploadMapBackgroundSuccess());
    yield put(BMsActions.loadBmImage(bmId));
  } catch (e) {
    console.log(e);
    NotificationManager.error(TextConstants.UpdateMapBackgroundFail, "", 5000);
    yield put(BMsActions.uploadMapBackgroundFailure());
  } finally {
    yield put(BMsActions.uploadMapBackgroundCleanState());
  }
}

function uploadMapBackground(signedData, file) {
  const formdata = new FormData();
  formdata.append("AWSAccessKeyId", signedData?.fields["AWSAccessKeyId"]);
  formdata.append("key", signedData?.fields["key"]);
  formdata.append("policy", signedData?.fields["policy"]);
  formdata.append("signature", signedData?.fields["signature"]);
  formdata.append(
    "x-amz-security-token",
    signedData?.fields["x-amz-security-token"]
  );
  formdata.append("file", file);

  const options = {
    method: "POST",
    body: formdata,
  };

  return new Promise((resolve, reject) => {
    fetch(signedData.url, options)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response);
      })
      .catch((e) => reject(e));
  });
}

function* updateBmNickname({ payload }) {
  try {
    const dataObj = payload.map((bm) => {
      return {
        deviceId: bm.deviceId,
        nickname: bm.nickname,
      };
    });
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/nickname`;
    const response = yield call(requests.put, url, { devices: dataObj });

    console.log(response);

    yield put(
      BMsActions.updateBmNicknameSuccess(
        payload.map((bm) => ({ ...bm, isEditable: false }))
      )
    );
    NotificationManager.success(TextConstants.BmUpdateSuccess, "", 3500);
  } catch (error) {
    yield put(BMsActions.updateBmNicknameFailure());
    NotificationManager.error(error.message, "", 3500);
  } finally {
    yield put(BMsActions.updateBmNicknameCleanState());
  }
}

function* getBmOtaVersion({ payload }) {
  const { bmId } = payload;
  const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_VERSIONS.replace(
    "{:deviceId}",
    bmId
  )}`;
  console.log(bmId);
  try {
    const response = yield call(requests.get, url);
    if (!response) {
      yield put(BMsActions.getBmOtaVersionFailure());
      return;
    }
    yield put(BMsActions.getBmOtaVersionSuccess(response));
  } catch (e) {
    console.warn(e);
    yield put(BMsActions.getBmOtaVersionFailure());
  }
}

function* getBmOtaCommandVersion({ payload }) {
  const { bmId } = payload;
  const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_COMMANDS.replace(
    "{:deviceId}",
    bmId
  )}`;
  const command = {
    namespace: "iris",
    action: "version",
    waitForEvent: true,
  };
  try {
    const response = yield call(requests.post, url, command);
    if (!response) {
      yield put(BMsActions.getBmOtaVersionFailure());
      return;
    }
    console.log(response.eventData.command_response);
    yield put(
      BMsActions.getBmOtaVersionSuccess(response.eventData.command_response)
    );
  } catch (e) {
    console.warn(e);
    yield put(BMsActions.getBmOtaVersionFailure());
  }
}

function* sendBmBinFile({ payload }) {
  const { bmId, name, file } = payload;
  const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_BINFILES.replace(
    "{:deviceId}",
    bmId
  )}`;
  const params = {
    file,
    filename: name,
  };
  try {
    const response = yield call(requests.post, url, params);
    if (!response) {
      yield put(BMsActions.sendBmOtaBinfileFailure());
      return;
    }
    console.log(response);
    if (response.eventStatus === "SUCCESS") {
      yield put(BMsActions.sendBmOtaBinfileSuccess());
    } else {
      yield put(BMsActions.sendBmOtaBinfileFailure());
    }
  } catch (e) {
    console.warn(e);
    yield put(BMsActions.sendBmOtaBinfileFailure());
  }
}

function* sendBmCommand({ payload }) {
  const { bmId, namespace, action, params, waitForEvent } = payload;
  const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_COMMANDS.replace(
    "{:deviceId}",
    bmId
  )}`;
  const command = {
    namespace,
    action,
    waitForEvent,
    params: params || {},
  };
  try {
    const response = yield call(requests.post, url, command);
    if (!response) {
      yield put(BMsActions.sendBmOtaCommandFailure());
      return;
    }
    if (response.eventStatus === "SUCCESS") {
      NotificationManager.success(
        "コマンドが成功に実行されました",
        "コマンド成功"
      );
      yield put(BMsActions.sendBmOtaCommandSuccess());
      return;
    }
    NotificationManager.error("コマンドの実行に失敗しました", "コマンド失敗");
    yield put(BMsActions.sendBmOtaCommandFailure());
  } catch (e) {
    console.warn(e);
    NotificationManager.error("コマンドの実行に失敗しました", "コマンド失敗");
    yield put(BMsActions.sendBmOtaCommandFailure());
  }
}

export function* watchBMs() {
  yield takeEvery(BMsActions.GET_BMS_LIST, fetBMsList);
  yield takeEvery(BMsActions.GET_REMAINING_BMS_LIST, fetRemainingBMsList);
  yield takeEvery(BMsActions.GET_STORE_BMS, fetchStoreBMs);
  yield takeEvery(BMsActions.GET_BMS_LIST_OF_ALL, fetBMsListOfAll);
  yield takeEvery(BMsActions.ADD_NEW_BMS, addNewBms);
  yield takeEvery(BMsActions.UPDATE_NEW_BMS, updateNewBms);
  yield takeEvery(BMsActions.DELETE_BM, deleteBm);
  yield takeEvery(
    BMsActions.TOGGLE_RECONNECT_NOTIFICATION,
    toggleReconnectNotification
  );
  yield takeLeading(BMsActions.UPLOAD_XML, uploadXml);
  yield takeLeading(BMsActions.UPLOAD_MAP_BACKGROUND, uploadBmMapBackground);
  yield takeLatest(BMsActions.EXPORT_DEVICE_SENSORS, exportDeviceSensors);
  yield takeLeading(BMsActions.UPDATE_BM_NICKNAME, updateBmNickname);
  yield takeLeading(BMsActions.BM_OTA_GET_VERSION, getBmOtaVersion);
  yield takeLeading(
    BMsActions.BM_OTA_GET_COMMAND_VERSION,
    getBmOtaCommandVersion
  );
  yield takeLeading(BMsActions.BM_OTA_SEND_BINFILE, sendBmBinFile);
  yield takeLeading(BMsActions.BM_OTA_SEND_COMMAND, sendBmCommand);
  yield takeLeading(BMsActions.MODIFY_SL_LIST, modifySlList);
}

export default function* sensorListSaga() {
  yield all([fork(watchBMs)]);
}
