import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN_USER,
  LOGOUT_USER,
  loginUserSuccess,
  loginUserFailed,
  letLogoutUserSuccess,
} from "../actions";
import { NotificationManager } from "react-notifications";
import TextConstants from "src/helpers/TextConstants";

function* loginWithUsernamePassword({ payload }) {
  try {
    const { loginType, user } = payload;
    const userToken = user.signInUserSession.idToken.jwtToken;

    const { attributes } = user;

    let userType;
    if (attributes && attributes.hasOwnProperty("custom:company_id")) {
      userType = "CA";
    } else if (
      attributes &&
      attributes.hasOwnProperty("custom:department_id")
    ) {
      if (loginType === "haccp") {
        userType = "DMHACCP";
      } else {
        userType = "DM";
      }
    } else if (attributes && attributes.hasOwnProperty("custom:store_id")) {
      userType = "GU";
    } else if (attributes && attributes["custom:role"]) {
      userType = "SETUP_ADMIN";
    } else {
      userType = "SA";
    }

    // just to make sure that users are logged into respected portal [Start]
    if (["SA", "CA", "DM", "SETUP_ADMIN"].includes(userType) && loginType === "haccp") {
      NotificationManager.error(
        TextConstants.PermissionDeniedMessage,
        "",
        5000
      );
      yield put(loginUserFailed());
      return false;
    }
    if (["DMHACCP", "GU"].includes(userType) && loginType === "management") {
      NotificationManager.error(
        TextConstants.PermissionDeniedMessage,
        "",
        5000
      );
      yield put(loginUserFailed());
      return false;
    }
    // just to make sure that users are logged into respected portal [End]

    const userData = { ...user, userType };

    const currentVersion = localStorage.getItem("_version");
    // clear the storage
    localStorage.clear();

    if (currentVersion) {
      localStorage.setItem("_version", currentVersion);
    }

    // check if already stored, than removed
    if (localStorage.getItem("HaccpAppUserData")) {
      localStorage.removeItem("HaccpAppUserData");
    }

    if (localStorage.getItem("userToken")) {
      localStorage.removeItem("userToken");
    }

    localStorage.setItem("HaccpAppUserData", JSON.stringify(userData));
    localStorage.setItem("userToken", userToken);
    yield put(loginUserSuccess({ data: userData, userType, loginType }));
  } catch (error) {
    console.log("login error : ", error);
    yield put(loginUserFailed());
  }
}

function* letLogoutUser() {
  try {
    // clear storage data
    localStorage.removeItem("HaccpAppUserData");
    localStorage.removeItem("userToken");
    localStorage.removeItem("BmList");
    localStorage.removeItem("SelectedBmId");
    localStorage.removeItem("SelectedStore");
    localStorage.removeItem("SensorListScrollTop");
    yield put(letLogoutUserSuccess());
  } catch (error) {
    console.log("login error : ", error);
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithUsernamePassword);
  yield takeEvery(LOGOUT_USER, letLogoutUser);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser)]);
}
