import * as Actions from "../actions";

// Setup Admin Management
export const fetchSetupAdmins = (payload) => ({
    type: Actions.FETCH_SETUP_ADMINS,
    payload,
});
export const fetchSetupAdminsSuccess = (payload) => ({
    type: Actions.FETCH_SETUP_ADMINS_SUCCESS,
    payload,
});
export const fetchSetupAdminsFailure = ({ message }) => ({
    type: Actions.FETCH_SETUP_ADMINS_FAILURE,
    payload: message,
});
// ---
export const createSetupAdmin = (payload) => ({
    type: Actions.CREATE_SETUP_ADMIN,
    payload,
});
export const createSetupAdminSuccess = (payload) => ({
    type: Actions.CREATE_SETUP_ADMIN_SUCCESS,
    payload,
});
export const createSetupAdminFailure = ({ message }) => ({
    type: Actions.CREATE_SETUP_ADMIN_FAILURE,
    payload: message,
});
// ---
export const updateSetupAdmin = (payload) => ({
    type: Actions.UPDATE_SETUP_ADMIN,
    payload,
});
export const updateSetupAdminSuccess = (payload) => ({
    type: Actions.UPDATE_SETUP_ADMIN_SUCCESS,
    payload,
});
export const updateSetupAdminFailure = ({ message }) => ({
    type: Actions.UPDATE_SETUP_ADMIN_FAILURE,
    payload: message,
});
// ---
export const deleteSetupAdmin = (payload) => ({
    type: Actions.DELETE_SETUP_ADMIN,
    payload,
});
export const deleteSetupAdminSuccess = (payload) => ({
    type: Actions.DELETE_SETUP_ADMIN_SUCCESS,
    payload,
});
export const deleteSetupAdminFailure = ({ message }) => ({
    type: Actions.DELETE_SETUP_ADMIN_FAILURE,
    payload: message,
});
// ---
// Setup Dashboard & Onsite Dashboard
export const uploadStoreHearing = (payload) => ({
    type: Actions.UPLOAD_STORE_HEARING,
    payload,
});
export const uploadStoreHearingSuccess = (payload) => ({
    type: Actions.UPLOAD_STORE_HEARING_SUCCESS,
    payload,
});
export const uploadStoreHearingFailure = ({ message }) => ({
    type: Actions.UPLOAD_STORE_HEARING_FAILURE,
    payload: message,
});
// ---
export const downloadStoreHearing = (payload) => ({
    type: Actions.DOWNLOAD_STORE_HEARING,
    payload,
});
export const downloadStoreHearingSuccess = (payload) => ({
    type: Actions.DOWNLOAD_STORE_HEARING_SUCCESS,
    payload,
});
export const downloadStoreHearingFailure = ({ message }) => ({
    type: Actions.DOWNLOAD_STORE_HEARING_FAILURE,
    payload: message,
});
// ---
export const uploadStoreSettingFiles = (payload) => ({
    type: Actions.UPLOAD_STORE_SETTING_FILES,
    payload,
});
export const uploadStoreSettingFilesSuccess = (payload) => ({
    type: Actions.UPLOAD_STORE_SETTING_FILES_SUCCESS,
    payload,
});
export const uploadStoreSettingFilesFailure = ({ message }) => ({
    type: Actions.UPLOAD_STORE_SETTING_FILES_FAILURE,
    payload: message,
});
// ---
export const downloadStoreSettingFiles = (payload) => ({
    type: Actions.DOWNLOAD_STORE_SETTING_FILES,
    payload,
});
export const downloadStoreSettingFilesSuccess = (payload) => ({
    type: Actions.DOWNLOAD_STORE_SETTING_FILES_SUCCESS,
    payload,
});
export const downloadStoreSettingFilesFailure = ({ message }) => ({
    type: Actions.DOWNLOAD_STORE_SETTING_FILES_FAILURE,
    payload: message,
});
// ---
export const uploadDeviceSettings = (payload) => ({
    type: Actions.UPLOAD_DEVICE_SETTINGS,
    payload,
});
export const uploadDeviceSettingsSuccess = (payload) => ({
    type: Actions.UPLOAD_DEVICE_SETTINGS_SUCCESS,
    payload,
});
export const uploadDeviceSettingsFailure = ({ message }) => ({
    type: Actions.UPLOAD_DEVICE_SETTINGS_FAILURE,
    payload: message,
});
// ---
export const downloadDeviceSettings = (payload) => ({
    type: Actions.DOWNLOAD_DEVICE_SETTINGS,
    payload,
});
export const downloadDeviceSettingsSuccess = (payload) => ({
    type: Actions.DOWNLOAD_DEVICE_SETTINGS_SUCCESS,
    payload,
});
export const downloadDeviceSettingsFailure = ({ message }) => ({
    type: Actions.DOWNLOAD_DEVICE_SETTINGS_FAILURE,
    payload: message,
});
// ---
export const downloadStoreHoldingFiles = (payload) => ({
    type: Actions.DOWNLOAD_STORE_HOLDING_FILES,
    payload,
});
export const downloadStoreHoldingFilesSuccess = (payload) => ({
    type: Actions.DOWNLOAD_STORE_HOLDING_FILES_SUCCESS,
    payload,
});
export const downloadStoreHoldingFilesFailure = ({ message }) => ({
    type: Actions.DOWNLOAD_STORE_HOLDING_FILES_FAILURE,
    payload: message,
});
// ---
export const registerStoreDevice = (payload) => ({
    type: Actions.REGISTER_STORE_DEVICE,
    payload,
});
export const registerStoreDeviceSuccess = (payload) => ({
    type: Actions.REGISTER_STORE_DEVICE_SUCCESS,
    payload,
});
export const registerStoreDeviceFailure = ({ message }) => ({
    type: Actions.REGISTER_STORE_DEVICE_FAILURE,
    payload: message,
});
// ---
export const displayDeviceSensors = (payload) => ({
    type: Actions.DISPLAY_DEVICE_SENSORS,
    payload,
});
export const displayDeviceSensorsSuccess = (payload) => ({
    type: Actions.DISPLAY_DEVICE_SENSORS_SUCCESS,
    payload,
});
export const displayDeviceSensorsFailure = ({ message }) => ({
    type: Actions.DISPLAY_DEVICE_SENSORS_FAILURE,
    payload: message,
});
// ---
