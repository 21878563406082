import moment from "moment";
import GraphGradientBg from "src/assets/icons/Resources/graf_gradetion20.png";

export const RandomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const GenerateRandomPointsBetweenDates = (
  fromDate,
  toDate,
  currentSensor
) => {
  const currentMoment = moment(fromDate);
  const endMoment = moment(toDate);

  // generate dummy graph data
  var sensorGraphData = [];

  while (currentMoment.isSameOrBefore(endMoment, "day")) {
    var t = new Date(
      `${currentMoment.format("YYYY-MM-DD")} ${moment().format("HH:mm:ss")}`
    );

    const minVal = RandomIntFromInterval(currentSensor.warningTemp.min, 0);
    const maxVal = RandomIntFromInterval(0, currentSensor.warningTemp.max);
    sensorGraphData.push([
      new Date(t.getTime() - 1 * 1000),
      RandomIntFromInterval(minVal, maxVal),
      minVal,
      maxVal,
    ]);

    currentMoment.add(1, "days");
  }

  return sensorGraphData;
};

// Calculate the min/max y value in the Dygraph's data set.
// function calcMinMax(g) {
//   var ymin = g.getValue(0, 1),
//     ymax = g.getValue(0, 1),
//     y;
//   for (var i = 0; i < g.numRows(); i++) {
//     for (var j = 1; j < g.numColumns(); j++) {
//       y = g.getValue(i, j);
//       if (y < ymin) {
//         ymin = y;
//       } else if (y > ymax) {
//         ymax = y;
//       }
//     }
//   }
//   return [ymin, ymax];
// }

export const UnderlayCallback = (
  ctx,
  area,
  grh,
  settingsData,
  currentSensor
) => {
  // var mm = calcMinMax(grh),
  // ymin = mm[0],
  // ymax = mm[1],
  var ymin = currentSensor.warningTemp.min,
    ymax = currentSensor.warningTemp.max,
    canvasYmin = grh.toDomYCoord(ymin),
    canvasYmax = grh.toDomYCoord(ymax);

  // var my_gradient = ctx.createLinearGradient(0, 0, 0, 170);
  // my_gradient.addColorStop(0.5, "#fef0d3");
  // my_gradient.addColorStop(0.3, "#e3fbe3");
  // my_gradient.addColorStop(0.2, "#d3fbf3");
  // my_gradient.addColorStop(0.7, "#dde8fe");
  // ctx.fillStyle = my_gradient;
  // ctx.fillRect(area.x, area.y, area.w, area.h);

  ctx.globalCompositeOperation = "destination-over";

  var img = new Image();
  img.src = GraphGradientBg;
  img.onload = () => ctx.drawImage(img, area.x, area.y, area.w, area.h);

  // if (settingsData.isShowMinLine) {
  ctx.beginPath();
  ctx.strokeStyle = "blue";
  ctx.moveTo(area.x, canvasYmin);
  ctx.lineTo(area.x + area.w, canvasYmin);
  ctx.closePath();
  ctx.stroke();
  // }
  // if (settingsData.isShowMaxLine) {
  ctx.beginPath();
  ctx.strokeStyle = "blue";
  ctx.moveTo(area.x, canvasYmax);
  ctx.lineTo(area.x + area.w, canvasYmax);
  ctx.closePath();
  ctx.stroke();
  // }

  var yminAlert = currentSensor.alertTemp.min,
    ymaxAlert = currentSensor.alertTemp.max,
    canvasYminAlert = grh.toDomYCoord(yminAlert),
    canvasYmaxAlert = grh.toDomYCoord(ymaxAlert);

  ctx.beginPath();
  ctx.strokeStyle = "red";
  ctx.moveTo(area.x, canvasYmaxAlert);
  ctx.lineTo(area.x + area.w, canvasYmaxAlert);
  ctx.closePath();
  ctx.stroke();

  if (!currentSensor.disableAlertMinTemp) {
    ctx.beginPath();
    ctx.strokeStyle = "red";
    ctx.moveTo(area.x, canvasYminAlert);
    ctx.lineTo(area.x + area.w, canvasYminAlert);
    ctx.closePath();
    ctx.stroke();
  }
};

export const legendFormatterGraphOne = (data) => {
  // console.log(data);

  const channelUnit = "";

  if (data.x == null) {
    // This happens when there's no selection and {legend: 'always'} is set.

    const lastPoints = data.dygraph.file_.slice(-1)[0];
    // console.log(lastPoints);
    let lastDate = new Date();
    if (lastPoints[0] !== 0) {
      lastDate = lastPoints[0];
    }

    return (
      `
        <ul class="no-bullets">
          <li style="margin-bottom:5px;">
            <span style="font-weight:bold;display:inline-block;">時間 :</span>
            <span>${moment(lastDate).format("DD/MM/YYYY HH:mm:ss")}</span>
          </li>
      ` +
      data.series
        .map(function (series, index) {
          return `
              <li>
                <span style="font-weight: bold; color:${
                  series.color
                }">${series.labelHTML}:</span>
                <span>${lastPoints[index + 1]} ${channelUnit}</span>
              </li>
            `;
        })
        .join("") +
      "</ul>"
    );
  }

  var html = `
    <ul class="no-bullets">
      <li style="margin-bottom:5px;">
        <span style="font-weight:bold;display:inline-block;">時間 :</span>
        <span>${moment(new Date(data.x)).format("YYYY/MM/DD HH:mm")}</span>
      </li>
  `;
  data.series.forEach(function (series) {
    if (!series.isVisible) return;
    html += `
      <li>
      <span>${series.yHTML} ${channelUnit} </span>
        <span style="font-weight: bold;" >${series.labelHTML}</span>        
      </li>
    `;
  });
  return html;
};

export const GetFormatedDate = (date) => {
  moment(date).local().format("YYYY-MM-DD HH:mm:ss");
};

export const graphOneOptions = (settingsData, currentSensor) => {
  return {
    drawPoints: true,
    pointSize: 0,
    highlightCircleSize: 0,
    drawGrid: false,
    legend: "follow",
    labels: ["Time", "℃", "Min", "Max"],
    ylabel: "[℃]",
    valueRange: [-40, 50],
    colors: ["#4e9704", "#BB842C", "#E20040"],
    strokeWidth: 2,
    panEdgeFraction: 0.1,
    interactionModel: {}, // this will disable all the interaction againts graph
    legendFormatter: legendFormatterGraphOne,
    underlayCallback: (ctx, area, grh) =>
      UnderlayCallback(ctx, area, grh, settingsData, currentSensor),
    visibility: [true, false, false],
    dateWindow: [
      moment(settingsData.fromDate + " 00:00:00").valueOf(),
      moment(settingsData.toDate + " 23:59:59").valueOf(),
    ],
    axes: {
      x: {
        ticker: function (min, max) {
          // console.log(min, max);
          // console.log(new Date(min));
          // console.log(new Date(max));
          return GenerateTicksFromDateRange(settingsData, min, max);
        },
      },
    },
  };
};

export const graphOneUpdateTempOptions = (settingsData, currentSensor) => {
  return {
    underlayCallback: (ctx, area, grh) =>
      UnderlayCallback(ctx, area, grh, settingsData, currentSensor),
  };
};

export const GenerateTicksFromDateRange = (settingsData, min, max) => {
  // console.log(settingsData.fromDate + " 00:00:00");
  // console.log(settingsData.toDate + " 23:59:59");
  // console.log("----------------");
  const currentMoment = moment(settingsData.fromDate + " 00:00:00");
  const endMoment = moment(settingsData.toDate + " 23:59:59");

  // const currentMoment = moment(min);
  // const endMoment = moment(max);

  const sensorTicks = [];

  let checkGranularityText = "day";
  let checkGranularity = 1;
  let displayFormat = "D日";
  let changeFormatOnHourly = false;
  let changeFormatOnHourlyLLTE30 = false;
  let changeFormatOnGTE30 = false;
  let changeFormatOnMonthly = false;

  if (settingsData.days === 1 && settingsData.displayType === "timeunit") {
    checkGranularityText = "hour";
    checkGranularity = 1;
    displayFormat = "HH時 DD日";
    changeFormatOnHourly = true;
  } else if (
    settingsData.days >= 2 &&
    settingsData.days <= 7 &&
    settingsData.displayType === "timeunit"
  ) {
    checkGranularityText = "hour";
    checkGranularity = 6;
    displayFormat = "HH時 DD日";
    changeFormatOnHourly = true;
  } else if (
    settingsData.days > 7 &&
    settingsData.days <= 30 &&
    settingsData.displayType === "timeunit"
  ) {
    checkGranularityText = "day";
    checkGranularity = 1;
    displayFormat = "D日 MM月";
    changeFormatOnHourlyLLTE30 = true;
  }

  // For Daily data plot
  if (settingsData.days <= 30 && settingsData.displayType === "daily") {
    checkGranularityText = "day";
    checkGranularity = 1;
    displayFormat = "D日 MM月";
    changeFormatOnHourlyLLTE30 = true;
  } else if (
    settingsData.days > 30 &&
    settingsData.days <= 60 &&
    settingsData.displayType === "daily"
  ) {
    checkGranularityText = "day";
    checkGranularity = 2;
    displayFormat = "D日 MM月";
    changeFormatOnGTE30 = true;
  } else if (
    settingsData.days > 60 &&
    settingsData.days <= 182 &&
    settingsData.displayType === "daily"
  ) {
    checkGranularityText = "day";
    checkGranularity = 7;
    displayFormat = "D日 MM月";
    changeFormatOnGTE30 = true;
  } else if (
    settingsData.days > 182 &&
    settingsData.days <= 365 &&
    settingsData.displayType === "daily"
  ) {
    checkGranularityText = "day";
    checkGranularity = 14;
    displayFormat = "D日 MM月";
    changeFormatOnGTE30 = true;
  } else if (
    settingsData.days > 365 &&
    settingsData.days <= 730 &&
    settingsData.displayType === "daily"
  ) {
    checkGranularityText = "month";
    checkGranularity = 1;
    displayFormat = "MM月 YY年";
    changeFormatOnMonthly = true;
  } else if (settingsData.days > 730 && settingsData.displayType === "daily") {
    checkGranularityText = "month";
    checkGranularity = 3;
    displayFormat = "MM月 YY年";
    changeFormatOnMonthly = true;
  }

  let finalFormat = displayFormat;

  let checkCurrentMonth = 0;
  let checkCurrentYear = 0;
  while (currentMoment.isSameOrBefore(endMoment, checkGranularityText)) {
    var t = moment(`${currentMoment.format("YYYY-MM-DD HH:mm:ss")}`).valueOf();

    if (changeFormatOnHourly && moment(t).hours() !== 0) {
      finalFormat = finalFormat.replace(" DD日", "");
    } else if (
      changeFormatOnHourlyLLTE30 &&
      moment(t).date() !== 1 &&
      sensorTicks.length !== 0
    ) {
      finalFormat = finalFormat.replace(" MM月", "");
    } else if (changeFormatOnMonthly) {
      if (checkCurrentYear === moment(t).year() && sensorTicks.length !== 0) {
        finalFormat = finalFormat.replace(" YY年", "");
      } else {
        finalFormat = displayFormat;
        checkCurrentYear = moment(t).year();
      }
    } else if (changeFormatOnGTE30) {
      if (checkCurrentMonth === moment(t).month() && sensorTicks.length !== 0) {
        finalFormat = finalFormat.replace(" MM月", "");
      } else {
        finalFormat = displayFormat;
        checkCurrentMonth = moment(t).month();
      }
    } else {
      finalFormat = displayFormat;
    }

    sensorTicks.push({
      v: t,
      label: splitFormatAtSpace(t, finalFormat),
      // label: moment(t).local().format(finalFormat),
    });
    currentMoment.add(checkGranularity, checkGranularityText);
  }
  // console.log("sensorTicks", sensorTicks);
  return sensorTicks;
};

const splitFormatAtSpace = (time, format) => {
  return format
    .split(" ")
    .map((f) => moment(time).local().format(f))
    .join("<br/>");
};

export const graphOneUpdateRawOptions = (sensorGraphData, settingsData) => {
  return {
    file: sensorGraphData,
    visibility: [true, false, false],
    dateWindow: [
      moment(settingsData.fromDate + " 00:00:00").valueOf(),
      moment(settingsData.toDate + " 23:59:59").valueOf(),
    ],
    axes: {
      x: {
        ticker: function () {
          return GenerateTicksFromDateRange(settingsData);
        },
        // axisLabelFormatter: function (d) {
        //   return moment(d).local().format("MM-DD");
        // },
      },
    },
  };
};

export const graphOneUpdateDailyOptions = (
  sensorGraphDailyData,
  settingsData
) => {
  return {
    file: sensorGraphDailyData,
    // visibility: [true, settingsData.isShowMinLine, settingsData.isShowMaxLine],
    dateWindow: [
      moment(settingsData.fromDate + " 00:00:00").valueOf(),
      moment(settingsData.toDate + " 23:59:59").valueOf(),
    ],
    axes: {
      x: {
        ticker: function () {
          return GenerateTicksFromDateRange(settingsData);
        },
        // axisLabelFormatter: function (d) {
        //   return moment(d).local().format("MM-DD日");
        // },
      },
    },
  };
};

export const GenerateGraphData = (currentSensor, plotData) => {
  var sensorGraphData = [];

  if (plotData.length > 0) {
    for (var k = 0; k < plotData.length; k++) {
      var t1 = new Date(plotData[k].timestamp);
      sensorGraphData.push([
        t1,
        plotData[k].data.decoded.temperature,
        plotData[k].data.decoded.temperature, // just to mimic min/max datasets
        plotData[k].data.decoded.temperature, // just to mimic min/max datasets
      ]);
    }
  } else {
    sensorGraphData.push([0, 0, 0, 0]);
    // var t = new Date();
    // for (var i = 81000; i >= 0; i -= 3600) {
    //   if (i <= 0) break;
    //   var x = new Date(t.getTime() - i * 1000);
    //   // sensorGraphData.push([x, Math.floor(Math.random() * (5 - 1 + 1)) + 1]);
    //   sensorGraphData.push([
    //     x,
    //     RandomIntFromInterval(
    //       currentSensor.dfTemp.min,
    //       currentSensor.dfTemp.max
    //     ),
    //     0,
    //     0,
    //   ]);
    // }
  }

  return sensorGraphData;
};
