import * as SensorGraphActions from "../actions";

const initialState = {
  type: "",
  isLoading: false,
  currentSensor: null,
  sensorGraphData: [], // raw data
  sensorGraphDailyData: [], // daily data
  productSettingsLoader: false,
  productSettings: [],
  isLoadingForDownload: false,
  dataToBeDownloaded: [],
  lastFilterAppliedData: null,
};

const Sensor = (state = initialState, action) => {
  switch (action.type) {
    case SensorGraphActions.GET_SENSOR_GRAPH_DATA:
    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_DAILY:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        sensorGraphData: action.payload.sensorGraphData,
        currentSensor: action.payload.currentSensor,
        lastFilterAppliedData: action.payload.settingsData,
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_DAILY_SUCCESS:
      return {
        ...state,
        sensorGraphDailyData: action.payload.sensorGraphData,
        lastFilterAppliedData: action.payload.settingsData,
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_FAILURE:
    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_DAILY_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_CLEAN_STATE:
    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_DAILY_CLEAN_STATE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_GRAPH_DATA_CLEAR:
      return {
        ...initialState,
      };

    case SensorGraphActions.GET_PRODUCT_SETTINGS:
      return {
        ...state,
        productSettingsLoader: true,
        type: action.type,
      };

    case SensorGraphActions.GET_PRODUCT_SETTINGS_SUCCESS:
      return {
        ...state,
        productSettingsLoader: false,
        productSettings: action.payload,
        type: action.type,
      };

    case SensorGraphActions.GET_PRODUCT_SETTINGS_FAILURE:
      return {
        ...state,
        productSettingsLoader: false,
        type: action.type,
      };

    case SensorGraphActions.LET_DOWNLOAD_REQUESTED_DATA:
      return {
        ...state,
        isLoadingForDownload: true,
        type: action.type,
      };

    case SensorGraphActions.LET_DOWNLOAD_REQUESTED_DATA_SUCCESS:
      return {
        ...state,
        isLoadingForDownload: false,
        type: action.type,
        dataToBeDownloaded: action.payload,
      };

    case SensorGraphActions.LET_DOWNLOAD_REQUESTED_DATA_FAILURE:
    case SensorGraphActions.LET_DOWNLOAD_REQUESTED_DATA_CLEAN_STATE:
      return {
        ...state,
        isLoadingForDownload: false,
        type: action.type,
      };

    case SensorGraphActions.UPDATE_CURRENT_SENSOR:
      return {
        ...state,
        currentSensor: { ...state.currentSensor, ...action.payload },
        type: action.type,
      };

    case SensorGraphActions.GET_SENSOR_SETTING:
      return {
        ...state,
        isLoadingSensorSetting: true,
        type: action.type,
      };
    case SensorGraphActions.GET_SENSOR_SETTING_FAILURE:
    case SensorGraphActions.GET_SENSOR_SETTING_CLEAN_STATE:
      return {
        ...state,
        isLoadingSensorSetting: false,
        type: action.type,
      };
    case SensorGraphActions.GET_SENSOR_SETTING_SUCCESS:
      return {
        ...state,
        currentSensor: action.payload,
        isLoadingSensorSetting: false,
        type: action.type,
      };
    default:
      return state;
  }
};

export default Sensor;
