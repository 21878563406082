import * as SensorActions from "../actions";

const initialSelectedStore = localStorage.getItem("SelectedStore");

const initialState = {
  type: "",
  isLoading: false,
  isLoadingBMs: false,
  selectedBmId: localStorage.getItem("SelectedBmId")
    ? localStorage.getItem("SelectedBmId")
    : "",
  selectedStore: initialSelectedStore ? JSON.parse(initialSelectedStore) : null,
  sensorData: [],
  sensorDataCopy: [],
  disableSearchBar: false,
  filterLoader: false,
  fetchAlertListLoader: false,
  alertListData: [],
  updatingAlertComment: false,
  isLoadingBmImage: false,
  selectedBmImage: null,
  missingBmImage: false,
  mapView: {
    isLoadingBmImage: false,
    selectedBmImage: null,
    missingBmImage: false,
  },
  reportExecution: {},
  reportData: null,
  reportIsLoading: false,
  reportError: false,
  updatingSensorSettings: false,
};

const Sensor = (state = initialState, action) => {
  switch (action.type) {
    case SensorActions.GET_SENSOR_LIST:
    case SensorActions.LET_UPDATE_SENSOR_LIST:
    case SensorActions.LET_UPDATE_SENSOR_POSITION:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    case SensorActions.GET_SENSOR_LIST_SUCCESS:
    case SensorActions.LET_UPDATE_SENSOR_LIST_SUCCESS:
    case SensorActions.LET_UPDATE_SENSOR_POSITION_SUCCESS:
      return {
        ...state,
        sensorData: action.payload,
        sensorDataCopy: action.payload,
        type: action.type,
      };

    case SensorActions.GET_SENSOR_LIST_FAILURE:
    case SensorActions.LET_UPDATE_SENSOR_LIST_FAILURE:
    case SensorActions.LET_UPDATE_SENSOR_POSITION_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case SensorActions.GET_SENSOR_LIST_CLEAN_STATE:
    case SensorActions.LET_UPDATE_SENSOR_LIST_CLEAN_STATE:
    case SensorActions.LET_UPDATE_SENSOR_POSITION_CLEAN_STATE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case SensorActions.LET_APPLY_SENSOR_FILTER:
      return {
        ...state,
        filterLoader: true,
        type: action.type,
        disableSearchBar: true,
      };

    case SensorActions.LET_APPLY_SENSOR_FILTER_SUCCESS:
      return {
        ...state,
        sensorData: action.payload,
        type: action.type,
        filterLoader: false,
      };
    case SensorActions.RESET_THE_FILTERS:
      return {
        ...state,
        sensorData: action.payload,
        type: action.type,
        filterLoader: false,
        disableSearchBar: false,
      };

    case SensorActions.ENABLE_SEARCH_FILTERS:
      return {
        ...state,
        type: action.type,
        disableSearchBar: false,
      };

    case SensorActions.LET_APPLY_SENSOR_FILTER_FAILURE:
      return {
        ...state,
        filterLoader: false,
        type: action.type,
      };

    case SensorActions.GET_ALARAM_LIST:
      return {
        ...state,
        fetchAlertListLoader: true,
        type: action.type,
      };

    case SensorActions.GET_ALARAM_LIST_SUCCESS:
      return {
        ...state,
        alertListData: action.payload,
        type: action.type,
        fetchAlertListLoader: false,
      };

    case SensorActions.GET_ALARAM_LIST_FAILURE:
      return {
        ...state,
        fetchAlertListLoader: false,
        type: action.type,
      };

    case SensorActions.UPDATE_ALERT_COMMENT:
      return {
        ...state,
        updatingAlertComment: true,
        type: action.type,
      };

    case SensorActions.UPDATE_ALERT_COMMENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        updatingAlertComment: false,
        alertListData: state.alertListData.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    case SensorActions.UPDATE_ALERT_COMMENT_FAILURE:
      return {
        ...state,
        updatingAlertComment: false,
        type: action.type,
      };

    case SensorActions.CLEAR_ALERT_HISTORIES:
      return {
        ...state,
        fetchAlertListLoader: true,
        type: action.type,
      };

    case SensorActions.CLEAR_ALERT_HISTORIES_SUCCESS:
      return {
        ...state,
        type: action.type,
        alertListData: [],
        fetchAlertListLoader: false,
      };

    case SensorActions.CLEAR_ALERT_HISTORIES_FAILURE:
    case SensorActions.CLEAR_ALERT_HISTORIES_CLEAN_STATE:
      return {
        ...state,
        fetchAlertListLoader: false,
        type: action.type,
      };

    case SensorActions.GET_STORE_BMS:
      return {
        ...state,
        isLoadingBMs: true,
        type: action.type,
      };
    case SensorActions.GET_STORE_BMS_SUCCESS:
      return {
        ...state,
        isLoadingBMs: false,
        selectedStore: {
          ...state.selectedStore,
          bms: action.payload.bms,
          bmDetails: action.payload.bmDetails,
          id: action.payload.storeId,
        },
        type: action.type,
      };
    case SensorActions.GET_STORE_BMS_FAILURE:
      return {
        ...state,
        isLoadingBMs: false,
        type: action.type,
      };

    case SensorActions.SET_SENSOR_ID:
      // check if already stored, than removed
      if (localStorage.getItem("SelectedBmId")) {
        localStorage.removeItem("SelectedBmId");
      }
      localStorage.setItem("SelectedBmId", action.payload);

      return {
        ...state,
        type: action.type,
        selectedBmId: action.payload,
      };
    case SensorActions.SET_STORE_FOR_SENSOR:
      localStorage.setItem("SelectedStore", JSON.stringify(action.payload));

      return {
        ...state,
        type: action.type,
        selectedStore: action.payload,
      };
    case SensorActions.UPDATE_STORE_SETTINGS_SUCCESS:
    case SensorActions.GET_STORE_SETTINGS_SUCCESS:
    case SensorActions.PAUSE_ALERT_SUCCESS:
      const store = { ...state.selectedStore, ...action.payload };
      localStorage.setItem("SelectedStore", JSON.stringify(store));

      return {
        ...state,
        type: action.type,
        selectedStore: store,
      };
    case SensorActions.LOGIN_ENTER:
    case SensorActions.RESET_SELECTED_VALUES:
      return {
        ...initialState,
        selectedBmId: "",
        selectedStore: null,
        sensorData: [],
        sensorDataCopy: [],
        alertListData: [],
        reportExecution: state.reportExecution,
      };
    case SensorActions.LOAD_BM_IMAGE:
      return {
        ...state,
        isLoadingBmImage: true,
        missingBmImage: false,
      };
    case SensorActions.LOAD_BM_IMAGE_SUCCESS:
      return {
        ...state,
        isLoadingBmImage: false,
        selectedBmImage: action.payload,
      };
    case SensorActions.LOAD_BM_IMAGE_FAILURE:
      return {
        ...state,
        isLoadingBmImage: false,
        selectedBmImage: "",
        missingBmImage: action.payload,
      };
    case SensorActions.LOAD_BM_IMAGE_CLEAN_STATE:
      return {
        ...state,
        isLoadingBmImage: false,
        selectedBmImage: null,
        missingBmImage: false,
      };

    case SensorActions.MAP_LOAD_BM_IMAGE:
      return {
        ...state,
        mapView: {
          ...state.mapView,
          isLoadingBmImage: true,
          missingBmImage: false,
        },
      };
    case SensorActions.MAP_LOAD_BM_IMAGE_SUCCESS:
      return {
        ...state,
        mapView: {
          ...state.mapView,
          isLoadingBmImage: false,
          selectedBmImage: action.payload,
        },
      };
    case SensorActions.MAP_LOAD_BM_IMAGE_FAILURE:
      return {
        ...state,
        mapView: {
          ...state.mapView,
          isLoadingBmImage: false,
          selectedBmImage: "",
          missingBmImage: action.payload,
        },
      };
    case SensorActions.MAP_LOAD_BM_IMAGE_CLEAN_STATE:
      return {
        ...state,
        mapView: {
          ...state.mapView,
          isLoadingBmImage: false,
          selectedBmImage: null,
          missingBmImage: false,
        },
      };

    case SensorActions.UPLOAD_MAP_BACKGROUND:
      return {
        ...state,
        isLoadingBmImage: true,
      };
    case SensorActions.UPLOAD_MAP_BACKGROUND_SUCCESS:
    case SensorActions.UPLOAD_MAP_BACKGROUND_FAILURE:
    case SensorActions.UPLOAD_MAP_BACKGROUND_CLEAN_STATE:
      return {
        ...state,
        isLoadingBmImage: true,
      };
    case SensorActions.REQUEST_GENERATE_REPORT:
    case SensorActions.DOWNLOAD_BM_REPORT:
      return {
        ...state,
        reportData: null,
        reportIsLoading: true,
        reportError: false,
        type: action.type,
      };
    case SensorActions.REQUEST_GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        reportExecution: action.payload,
        type: action.type,
      };
    case SensorActions.DOWNLOAD_BM_REPORT_SUCCESS:
      return {
        ...state,
        reportData: action.payload,
        reportIsLoading: false,
        reportError: false,
        reportExecution: {
          mode: state.reportExecution.mode,
        },
      };
    case SensorActions.DOWNLOAD_BM_REPORT_FAILURE:
      return {
        ...state,
        reportIsLoading: false,
        reportError: true,
        reportExecution: {},
      };
    case SensorActions.DOWNLOAD_BM_REPORT_CLEAN_STATE:
      return {
        ...state,
        reportData: null,
        reportIsLoading: false,
        reportError: false,
      };

    case SensorActions.UPDATE_SENSOR_SETTINGS:
      return {
        ...state,
        updatingSensorSettings: true,
        type: action.type,
      };
    case SensorActions.UPDATE_SENSOR_SETTINGS_SUCCESS:
    case SensorActions.UPDATE_SENSOR_SETTINGS_FAILURE:
      return {
        ...state,
        updatingSensorSettings: false,
        type: action.type,
      };

    case SensorActions.ADD_NEW_SENSOR:
    case SensorActions.DELETE_SENSOR:
    case SensorActions.EDIT_SENSOR:
    case SensorActions.HIDE_SLOT_SENSOR:
      return {
        ...state,
        updatingSensorSettings: true,
        type: action.type,
      };
    case SensorActions.ADD_NEW_SENSOR_SUCCESS:
    case SensorActions.DELETE_SENSOR_SUCCESS:
    case SensorActions.EDIT_SENSOR_SUCCESS:
    case SensorActions.HIDE_SLOT_SENSOR_SUCCESS:
      return {
        ...state,
        sensorData: action.payload,
        updatingSensorSettings: false,
        type: action.type,
      };
    case SensorActions.ADD_NEW_SENSOR_FAILURE:
    case SensorActions.ADD_NEW_SENSOR_CLEAN_STATE:
    case SensorActions.DELETE_SENSOR_FAILURE:
    case SensorActions.DELETE_SENSOR_CLEAN_STATE:
    case SensorActions.EDIT_SENSOR_FAILURE:
    case SensorActions.EDIT_SENSOR_CLEAN_STATE:
    case SensorActions.HIDE_SLOT_SENSOR_FAILURE:
    case SensorActions.HIDE_SLOT_SENSOR_CLEAN_STATE:
      return {
        ...state,
        updatingSensorSettings: false,
        type: action.type,
      };
    case SensorActions.REGISTER_STORE_DEVICE_SUCCESS:
      return {
        ...state,
        selectedStore: {
          ...state.selectedStore,
          bmDetails: [action.payload, ...(state.selectedStore?.bmDetails || [])],
          bms: [action.payload.deviceId, ...(state.selectedStore?.bms || [])],
        },
        type: action.type,
      };
    default:
      return state;
  }
};

export default Sensor;
