import {
  LOGIN_ENTER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER,
  TOGGLE_RELEASE_NOTES_MODAL,
  FORCE_LOGOUT,
} from "../actions";

export const loginUser = (user) => ({
  type: LOGIN_USER,
  payload: user,
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserFailed = () => ({
  type: LOGIN_USER_FAILED,
});

export const letLogoutUser = () => ({
  type: LOGOUT_USER,
});

export const letLogoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const toggleReleaseNotesModal = (flag) => ({
  type: TOGGLE_RELEASE_NOTES_MODAL,
  payload: flag,
});

export const loginEnter = () => ({
  type: LOGIN_ENTER
});

export const forceLogout = () => ({
  type: FORCE_LOGOUT
});
