import {
  all,
  fork,
  takeLeading,
  call,
  put,
  delay,
  select,
} from "redux-saga/effects";
import * as Actions from "../actions";
import Apis from "src/store/api/ApiConstants";
import * as client from "src/helpers/HTTPRequest";
import { NotificationManager } from "react-notifications";
import TextConstants from "src/helpers/TextConstants";
import moment from "moment";
import { batteryRenderer } from "src/views/users/monitoring/Helper";
import { getBatteryPercentage, getLastRowsIndex } from "src/helpers/Utils";
import _ from "lodash";

function* getCompanies() {
  try {
    const result = yield call(fetchCompanies);
    yield put({ type: Actions.GET_COMPANIES_SUCCESS, payload: result });
  } catch (error) {
    console.log("login error : ", error);
    yield put({ type: Actions.GET_COMPANIES_FAILURE });
  }
}

function fetchCompanies() {
  const url = `${Apis.BASE_URL}${Apis.COMPANIES}`;
  return client.get(url);
}

function* addCompany({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.COMPANIES}`;
    const response = yield call(client.post, url, payload);
    yield put(Actions.addCompanySuccess(response));
    NotificationManager.success(TextConstants.AddCompanySuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("addCompany error : ", error);
    yield put(Actions.addCompanyFailure());
  }
}

function* updateCompany({ payload }) {
  try {
    const { id, data } = payload;
    const url = `${Apis.BASE_URL}${Apis.COMPANIES}/${id}`;
    const response = yield call(client.put, url, data);
    yield put(Actions.updateCompanySuccess(response));
    NotificationManager.success(TextConstants.UpdateCompanySuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("updateCompany error : ", error);
    yield put(Actions.updateCompanyFailure());
  }
}

function* deleteCompany({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.COMPANIES}/${payload}`;
    yield call(client.deleteMethod, url);
    yield put(Actions.deleteCompanySuccess(payload));
    NotificationManager.success(TextConstants.DeleteCompanySuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("deleteCompany error : ", error);
    yield put(Actions.deleteCompanyFailure());
  }
}

function getBatteryInfo(sensors, flag) {
  const GroupedBatteries = batteryRenderer(sensors);
  const batteryObj = {};
  let batteryLessThan10 = 0;
  let batteryLessThan20 = 0;
  let batteryLessThan30 = 0;
  if (GroupedBatteries) {
    Object.keys(GroupedBatteries || {}).map((batteryKey, idx) => {
      const batteryPer = getBatteryPercentage(batteryKey);
      if (batteryPer < 10) {
        batteryLessThan10 += GroupedBatteries[batteryKey].length;
      } else if (batteryPer >= 10 && batteryPer < 20) {
        batteryLessThan20 += GroupedBatteries[batteryKey].length;
      } else if (batteryPer >= 20 && batteryPer <= 30) {
        batteryLessThan30 += GroupedBatteries[batteryKey].length;
      }
      return [];
    });
    batteryObj["30"] = batteryLessThan30;
    batteryObj["20"] = batteryLessThan20;
    batteryObj["10"] = batteryLessThan10;
    if (flag && flag === "forGrid") {
      batteryObj["batteryUnderTwenty"] = batteryLessThan20 + batteryLessThan10;
    }
  }
  const batteryCount = Object.values(batteryObj).reduce((a, b) => a + b, 0);
  return { GroupedBatteries, batteryObj, batteryCount };
}

const getStoreStatus = (sensors) => {
  let statusText = [];

  const checkAllNormal = (sensors.filter((fl) => fl.status === "ok") || [])
    .length;
  if (
    (checkAllNormal === sensors.length && sensors.length > 0) ||
    sensors.length === 0
  ) {
    statusText.push("normal");
  }

  const checkAbNormal = (sensors.filter((fl) => fl.status === "alert") || [])
    .length;
  if (checkAbNormal > 0 && sensors.length > 0) {
    statusText.push("abnormal");
  }

  const hasBatteryLessThanTen =
    sensors
      .map((fl) => getBatteryPercentage(parseInt(fl.battery)))
      .filter((fl) => fl <= 10).length > 0;
  if (hasBatteryLessThanTen) {
    statusText.push("low_charge");
  }

  const errorSTatusLen = (sensors.filter((fl) => fl.status === "error") || [])
    .length;
  if (errorSTatusLen > 0) {
    statusText.push("error");
  }
  if (errorSTatusLen === sensors.length && sensors.length > 0) {
    statusText.push("error_all");
  }

  return statusText;
};

function* getMonitoringData({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.MONITORING_DISPLAY_DATA}`;
    const datasets = yield call(client.get, url);

    const data = [];

    for (let index = 0; index < datasets.length; index++) {
      const element = datasets[index];
      for (let j = 0; j < element.stores.length; j++) {
        const storeElement = element.stores[j];

        const erroredSensors = storeElement.sensors.filter(
          (sensor) => sensor.status === "error"
        ).length;

        const trafficData = storeElement.trafficData;

        data.push({
          keyId: storeElement.storeId,
          // timestamp: moment(element.timestamp).format("YYYY/MM/DD HH:mm:ss"),
          timestamp: storeElement.timestamp,
          Company: { id: element.companyId, name: element.companyName },
          CompanyName: element.companyName,
          Store: { id: storeElement.storeId, name: storeElement.storeName },
          sim: storeElement.sim,
          StoreName: storeElement.storeName,
          Sensors: storeElement.sensors,
          NoOfSensors: storeElement.sensors.length,
          LastMaintenanceDate: storeElement.lastMaintenanceDate,
          IntroductionDay: storeElement.introductionDay,
          NextMaintenance: storeElement.nextMaintenance,
          SendAdmin: storeElement.sendAdmin,
          SendMaintenance: storeElement.sendMaintenance,
          StoreAdminEmail: storeElement.storeAdminEmail,
          MaintenancePersonEmail: storeElement.maintenancePersonEmail,
          nonRespondingSensors: storeElement.nonRespondingSensors,
          trafficData,
          trafficDataUsage: trafficData?.usage || 0,
          CommunicationError3DaysInARow:
            storeElement.nonRespondingSensors.length,
          lowBatterySensors: storeElement.lowBatterySensors,
          erroredSensors,
          // filterStatus: erroredSensors > 0 ? "failure" : "normal",
          filterStatus: getStoreStatus(storeElement.sensors),
          ...getBatteryInfo(storeElement.sensors),
        });
      }
    }
    yield put(Actions.getMonitoringDataSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(Actions.getMonitoringDataFailure());
  } finally {
    yield delay(2500);
    yield put(Actions.getMonitoringDataCleanState());
  }
}

function* getMonitoringStoreData({ payload }) {
  try {
    const { companyId, storeId } = payload;
    const url = `${Apis.BASE_URL}${Apis.MONITORING_REFRESH_STORE.replace("{:storeId}", storeId)}/?companyId=${companyId}`;
    const dataRes = yield call(client.get, url);

    const oldData = yield select((state) => state.company.monitoringData);
    const dataToBeUpdated = [...oldData];

    const findStoreIndex = oldData.findIndex(
      (store) => store.Store.id === storeId
    );
    if (findStoreIndex !== -1) {
      dataToBeUpdated[findStoreIndex] = {
        ...dataToBeUpdated[findStoreIndex],
        ...dataRes,
        CommunicationError3DaysInARow: dataRes.nonRespondingSensors.length,
        ...getBatteryInfo(dataRes.Sensors || []),
      };
      yield put(Actions.getMonitoringStoreDataSuccess(dataToBeUpdated));
    } else {
      yield put(Actions.getMonitoringStoreDataFailure());
    }
  } catch (error) {
    yield put(Actions.getMonitoringStoreDataFailure());
  } finally {
    yield delay(1500);
    yield put(Actions.getMonitoringStoreDataCleanState());
  }
}

function returnJustDate(datetime) {
  return moment(datetime).format("YYYY-MM-DD");
}

function* updateMonitoringData({ payload }) {
  try {
    const oldData = yield select((state) => state.company.monitoringData);

    const findIndex = oldData.findIndex((itm) => itm.keyId === payload.keyId);
    if (findIndex !== -1) {
      const dataToBeUpdated = [...oldData];
      dataToBeUpdated[findIndex] = {
        ...dataToBeUpdated[findIndex],
        ...payload,
      };

      const objToBeUpdated = {
        MaintenancePersonEmail: payload.MaintenancePersonEmail,
        // AdminEmail: payload.StoreAdminEmail,
        SendAdmin: payload.SendAdmin,
        SendMaintenance: payload.SendMaintenance,
        LastMaintenanceDate: payload.LastMaintenanceDate
          ? returnJustDate(payload.LastMaintenanceDate)
          : null,
        IntroductionDay: payload.IntroductionDay
          ? returnJustDate(payload.IntroductionDay)
          : null,
        NextMaintenance: payload.NextMaintenance
          ? returnJustDate(payload.NextMaintenance)
          : null,
        storeId: payload.Store?.id || "",
        sim: payload?.sim || "",
      };

      const url = `${Apis.BASE_URL}${Apis.MONITORING_MAINTENANCE_DETAILS.replace("{:storeId}", objToBeUpdated.storeId)}`;
      yield call(client.post, url, objToBeUpdated);

      NotificationManager.success(TextConstants.DataUpdatedSuccessfully);
      yield put(Actions.updateMonitoringDataSuccess(dataToBeUpdated));
    } else {
      yield put(Actions.updateMonitoringDataFailure());
    }
  } catch (error) {
    NotificationManager.error(error.message);
    yield put(Actions.updateMonitoringDataFailure());
  } finally {
    yield delay(1500);
    yield put(Actions.updateMonitoringDataCleanState());
  }
}

function* getHistoryList({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.MAIL_HISTORY}`;
    const histories = yield call(client.get, url);
    yield put(
      Actions.getHistoryListSuccess(_.orderBy(histories, "timestamp", "desc"))
    );
  } catch (error) {
    NotificationManager.error(error.message);
    yield put(Actions.getHistoryListFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getHistoryListCleanState());
  }
}

function* getGroupsAndTemplates({ payload }) {
  try {
    const urlGroups = `${Apis.BASE_URL}${Apis.MAIL_GROUPS}`;
    const groups = yield call(client.get, urlGroups);

    const urlTemplates = `${Apis.BASE_URL}${Apis.MAIL_TEMPLATES}`;
    const templates = yield call(client.get, urlTemplates);

    yield put(Actions.getGroupsAndTemplatesSuccess({ groups, templates }));
  } catch (error) {
    yield put(Actions.getGroupsAndTemplatesFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getGroupsAndTemplatesCleanState());
  }
}

function* sendGuidanceEmail({ payload }) {
  try {
    const urlMail = `${Apis.BASE_URL}${Apis.MAILS}`;
    yield call(client.post, urlMail, payload, true);
    yield put(Actions.sendGuidanceEmailSuccess());
    NotificationManager.success("ガイダンスメールが正常に送信されました");
  } catch (error) {
    NotificationManager.error(error.message);
    yield put(Actions.sendGuidanceEmailFailure());
  } finally {
    yield delay(500);
    yield put(Actions.sendGuidanceEmailCleanState());
  }
}

function* getGridMonitoringData({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.MONITORING_DISPLAY_DATA}`;
    const datasets = yield call(client.get, url);

    const data = [];

    let erroredStore, batteryLessThanTwentyStore, totalSensors;
    for (let index = 0; index < datasets.length; index++) {
      const element = datasets[index];
      erroredStore = 0;
      batteryLessThanTwentyStore = 0;
      totalSensors = 0;
      for (let j = 0; j < element.stores.length; j++) {
        const storeElement = element.stores[j];
        const erroredSensors = storeElement.sensors.filter(
          (sensor) => sensor.status === "error"
        ).length;
        if (erroredSensors > 0) {
          erroredStore++;
        }
        const batteryData = getBatteryInfo(storeElement.sensors, "forGrid");
        if (batteryData.batteryObj.batteryUnderTwenty > 0) {
          batteryLessThanTwentyStore++;
        }
        // batteryLessThanTwentyStore += batteryData.batteryObj.batteryUnderTwenty;
        totalSensors += storeElement.sensors.length;
      }
      data.push({
        ...element,
        erroredStore,
        batteryLessThanTwentyStore,
        totalSensors,
      });
    }
    yield put(
      Actions.getGridMonitoringDataSuccess({
        gridList: data,
        lastRowGridsIndex: getLastRowsIndex(data),
      })
    );
  } catch (error) {
    console.log(error);
    yield put(Actions.getGridMonitoringDataFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getGridMonitoringDataCleanState());
  }
}

function* setGridCompany({ payload }) {
  try {
    yield put(Actions.setGridCompanySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(Actions.setGridCompanyFailure());
  } finally {
    yield delay(500);
    yield put(Actions.setGridCompanyCleanState());
  }
}

function* updateGlobalEmails({ payload }) {
  try {
    const { maintenanceAdmins, constructionUsers } = payload;

    const apiPayload = {
      key: "_maintenance_settings",
      value: { maintenanceAdmins, constructionUsers },
    };

    const url = `${Apis.BASE_URL}${Apis.SETTINGS}`;
    yield call(client.put, url, apiPayload, true);
    yield put(Actions.updateGlobalEmailsSuccess());
    NotificationManager.success(TextConstants.DataUpdatedSuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    yield put(Actions.updateGlobalEmailsFailure());
  } finally {
    yield delay(500);
    yield put(Actions.updateGlobalEmailsCleanState());
  }
}

function* getGlobalEmails({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.SETTINGS}?key=_maintenance_settings`;
    const apiRes = yield call(client.get, url);

    const dataKeyRes = apiRes.find(
      (data) => data.key === "_maintenance_settings"
    );
    if (dataKeyRes) {
      yield put(Actions.getGlobalEmailsSuccess(dataKeyRes?.value || null));
    }
  } catch (error) {
    yield put(Actions.getGlobalEmailsFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getGlobalEmailsCleanState());
  }
}

export function* watchCompany() {
  yield takeLeading(Actions.GET_COMPANIES, getCompanies);
  yield takeLeading(Actions.ADD_COMPANY, addCompany);
  yield takeLeading(Actions.UPDATE_COMPANY, updateCompany);
  yield takeLeading(Actions.DELETE_COMPANY, deleteCompany);
  yield takeLeading(Actions.GET_MONITORING_DATA, getMonitoringData);
  yield takeLeading(Actions.UPDATE_MONITORING_DATA, updateMonitoringData);
  yield takeLeading(Actions.GET_STORE_DATA, getMonitoringStoreData);
  yield takeLeading(Actions.GET_HISTORY_LIST, getHistoryList);
  yield takeLeading(Actions.GET_GROUPS_AND_TEMPLATES, getGroupsAndTemplates);
  yield takeLeading(Actions.SEND_GUIDANCE_EMAIL, sendGuidanceEmail);
  yield takeLeading(Actions.GET_GRID_MONITORING, getGridMonitoringData);
  yield takeLeading(Actions.SET_GRID_COMPANY, setGridCompany);
  yield takeLeading(Actions.UPDATE_GLOBAL_EMAILS, updateGlobalEmails);
  yield takeLeading(Actions.GET_GLOBAL_EMAILS, getGlobalEmails);
}

export default function* rootSaga() {
  yield all([fork(watchCompany)]);
}
