import {
  all,
  delay,
  fork,
  put,
  takeEvery,
  call,
} from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationManager } from "react-notifications";
import * as client from "src/helpers/HTTPRequest";
import TextConstants from "src/helpers/TextConstants";
import Apis from "src/store/api/ApiConstants";
import JSZip from 'jszip';
import saveAs from "file-saver";
import { SortSensorBySlotNo, downloadS3File, uploadS3File } from "src/helpers/Utils";
import moment from "moment";
import { getUserToken } from "src/helpers/StorageUtils";

function* uploadStoreHearingSaga({ payload }) {
  try {
    const { storeId, file } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORE_HEARING.replace('{:storeId}', storeId)}`;
    const response = yield call(client.post, url);
    yield call(uploadS3File, response, file);
    yield put(Actions.uploadStoreHearingSuccess());
    NotificationManager.success(TextConstants.UploadStoreHearingSuccess, '', 5000);
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.uploadStoreHearingFailure(e));
  }
}

function* downloadStoreHearingSaga({ payload: storeId }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORE_HEARING.replace('{:storeId}', storeId)}`;
    const presignedUrl = yield call(client.get, url);
    if (!presignedUrl) {
      NotificationManager.error(TextConstants.StoreHearingFileNotFound, "", 5000);
      yield put(Actions.downloadStoreHearingFailure({}));
      return
    } 
    const fileData = yield call(downloadS3File, presignedUrl);
    const fileName = `${storeId}_hearing.xml`;
    saveAs(fileData, fileName);
    yield put(Actions.downloadStoreHearingSuccess());
  } catch (e) {
    NotificationManager.error(e.status === 404 ? TextConstants.StoreHearingFileNotFound : e.message, "", 5000);
    yield put(Actions.downloadStoreHearingFailure(e));
  }
}
// ---
function* uploadStoreSettingFilesSaga({ payload }) {
  try {
    const { storeId, files } = payload;
    const fileNames = files.map(x => x.name);
    const url = `${Apis.BASE_URL}${Apis.STORE_SETTINGS.replace('{:storeId}', storeId)}`;
    const urls = yield call(client.post, url, fileNames);
    const promises = [];
    for (const file of files) {
      const signedData = urls[file.name]
      if (signedData) {
        promises.push(uploadS3File(signedData, file));
      }
    }
    yield Promise.all(promises);
    yield put(Actions.uploadStoreSettingFilesSuccess());
    NotificationManager.success(TextConstants.UploadStoreSettingsSuccess, '', 5000);
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.uploadStoreSettingFilesFailure(e));
  }
}

function* downloadStoreSettingFilesSaga({ payload: storeId }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORE_SETTINGS.replace('{:storeId}', storeId)}`;
    const response = yield call(client.get, url);
    if (!response || !Object.keys(response).length) {
      NotificationManager.error(TextConstants.StoreSettingFilesNotFound, "", 5000);
      yield put(Actions.downloadStoreSettingFilesFailure({}));
      return
    }
    const promises = [];
    for (const fileName of Object.keys(response)) {
      promises.push(downloadS3File(response[fileName]))
    }
    const files = yield Promise.all(promises);
    const zip = new JSZip();
    Object.keys(response).forEach((fileName, idx) => zip.file(fileName, files[idx]));
    const content = yield zip.generateAsync({ type: 'blob' });
    saveAs(content, `${storeId}_settings.zip`);
    yield put(Actions.downloadStoreSettingFilesSuccess());
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.downloadStoreSettingFilesFailure(e));
  }
}
// ---
function* downloadStoreHoldingFilesSaga({ payload: storeId }) {
  try {
    let fileUrls = {};
    // Get store hearing file presigned URL
    const hearingUrl = `${Apis.BASE_URL}${Apis.STORE_HEARING.replace('{:storeId}', storeId)}`;
    const hearingPresignedUrl = yield call(client.get, hearingUrl);
    if (hearingPresignedUrl) {
      fileUrls['hearing.xml'] = hearingPresignedUrl;
    }

    // Get store settings files presigned URLs
    const url = `${Apis.BASE_URL}${Apis.STORE_SETTINGS.replace('{:storeId}', storeId)}`;
    const response = yield call(client.get, url);
    if (response && Object.keys(response).length) {
      fileUrls = { ...fileUrls, ...response };
    }

    if (!Object.keys(fileUrls).length) {
      NotificationManager.error(TextConstants.StoreHoldingFilesNotFound, "", 5000);
      yield put(Actions.downloadStoreHoldingFilesFailure({}));
      return
    }

    const promises = [];
    for (const fileName of Object.keys(fileUrls)) {
      promises.push(downloadS3File(fileUrls[fileName]))
    }
    const files = yield Promise.all(promises);
    const zip = new JSZip();
    Object.keys(fileUrls).forEach((fileName, idx) => zip.file(fileName, files[idx]));
    const content = yield zip.generateAsync({ type: 'blob' });
    saveAs(content, `${storeId}_holding_files.zip`);
    yield put(Actions.downloadStoreHoldingFilesSuccess());
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.downloadStoreHoldingFilesFailure(e));
  }
}
// ---
function* uploadDeviceSettingsSaga({ payload }) {
  try {
    const { deviceId, file } = payload;
    const url = `${Apis.BASE_URL}${Apis.DEVICE_SETTINGS.replace('{:deviceId}', deviceId)}`;
    yield call(client.post, url, file);
    yield put(Actions.uploadDeviceSettingsSuccess());
    NotificationManager.success(TextConstants.UploadDeviceSettingsSuccess, "", 5000);
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.uploadDeviceSettingsFailure(e));
  }
}

function* downloadDeviceSettingsSaga({ payload: deviceId }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.DEVICE_SETTINGS.replace('{:deviceId}', deviceId)}`;
    const response = yield call(exportDeviceSensors, url);
    saveAs(response, `${deviceId}_${moment().valueOf()}.xml`);
    yield put(Actions.downloadDeviceSettingsSuccess());
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.downloadDeviceSettingsFailure(e));
  }
}

function exportDeviceSensors(url) {
  const token = getUserToken();

  const options = {
    headers: {
      Authorization: "Bearer " + token
    },
  };
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response.blob());
      })
      .catch((e) => reject(e));
  });
}
// ---
function* registerStoreDeviceSaga({ payload: { storeId, deviceId } }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.SINGLE_STORE.replace('{:storeId}', storeId)}/${Apis.DEVICES}`;
    const response = yield call(client.post, url, { deviceId });
    NotificationManager.success(TextConstants.RegisterStoreDeviceSuccess, "", 5000);
    yield put(Actions.registerStoreDeviceSuccess(response));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.registerStoreDeviceFailure(e));
  }
}
// ---
function* displayDeviceSensorsSaga({ payload: deviceId }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.DEVICES}/${deviceId}/onsite-sensors-status`;
    const sensors = yield call(client.get, url);
    yield put(Actions.displayDeviceSensorsSuccess(SortSensorBySlotNo(sensors)));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.displayDeviceSensorsFailure(e));
  }
}

// --- Setup Admin Management
function* fetchSetupAdminsSaga() {
  try {
    const url = `${Apis.BASE_URL}${Apis.SETUP_ADMINS}`;
    const users = yield call(client.get, url);
    yield put(Actions.fetchSetupAdminsSuccess(users));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.fetchSetupAdminsFailure(e));
  }
}

function* createSetupAdminSaga({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.SETUP_ADMINS}`;
    yield call(client.post, url, payload);
    delete payload['password'];
    yield put(Actions.createSetupAdminSuccess(payload));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.createSetupAdminFailure(e));
  }
}

function* updateSetupAdminSaga({ payload }) {
  try {
    const { username, data } = payload;
    const url = `${Apis.BASE_URL}${Apis.SETUP_ADMINS}/${username}`;
    yield call(client.put, url, data);
    if (data.password) {
      delete payload['password'];
    }
    data['username'] = username;
    yield put(Actions.updateSetupAdminSuccess({ username, data }));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.updateSetupAdminFailure(e));
  }
}

function* deleteSetupAdminSaga({ payload: username }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.SETUP_ADMINS}/${username}`;
    yield call(client.deleteMethod, url);
    yield put(Actions.deleteSetupAdminSuccess(username));
  } catch (e) {
    NotificationManager.error(e.message, "", 5000);
    yield put(Actions.deleteSetupAdminFailure(e));
  }
}
// ---

export function* watchSetupDashboard() {
  // --- Setup Dashboard & Onsite Dashboard
  yield takeEvery(Actions.UPLOAD_STORE_HEARING, uploadStoreHearingSaga);
  yield takeEvery(Actions.DOWNLOAD_STORE_HEARING, downloadStoreHearingSaga);
  yield takeEvery(Actions.UPLOAD_STORE_SETTING_FILES, uploadStoreSettingFilesSaga);
  yield takeEvery(Actions.DOWNLOAD_STORE_SETTING_FILES, downloadStoreSettingFilesSaga);
  yield takeEvery(Actions.DOWNLOAD_STORE_HOLDING_FILES, downloadStoreHoldingFilesSaga);
  yield takeEvery(Actions.UPLOAD_DEVICE_SETTINGS, uploadDeviceSettingsSaga);
  yield takeEvery(Actions.DOWNLOAD_DEVICE_SETTINGS, downloadDeviceSettingsSaga);
  yield takeEvery(Actions.REGISTER_STORE_DEVICE, registerStoreDeviceSaga);
  yield takeEvery(Actions.DISPLAY_DEVICE_SENSORS, displayDeviceSensorsSaga);
  // --- Setup Admin Management
  yield takeEvery(Actions.FETCH_SETUP_ADMINS, fetchSetupAdminsSaga);
  yield takeEvery(Actions.CREATE_SETUP_ADMIN, createSetupAdminSaga);
  yield takeEvery(Actions.UPDATE_SETUP_ADMIN, updateSetupAdminSaga);
  yield takeEvery(Actions.DELETE_SETUP_ADMIN, deleteSetupAdminSaga);
}

export default function* storeListSaga() {
  yield all([fork(watchSetupDashboard)]);
}
