const TextConstants = {
  Logout: "ログアウト",
  State: "状態",
  Situation: "状態",
  OK: "OK",
  OKnSummary: "OK & サマリー",
  Caution: "警告",
  Warning: "注意",
  Error: "通信エラー",
  Icon: "アイコン",
  UnitType: "名称",
  UnitTypePlaceHolder: "例）精肉、野菜など",
  SensorNo: "識別No.",
  CurrentTemp: "測定温度",
  Temp: "温度",
  TempRange: "管理温度",
  Power: "電源",
  Graph: "グラフ",
  SelectBMPlaceHolder: "BM選択",
  SelectedAllBMPlaceHolder: "ALL",
  BmNo: "No.",
  BmName: "店舗名称",
  TotalSensors: "設置台数",
  LastUpdate: "最新データ取得時間",
  Settings: "設定",
  Filter: "表示項目",
  DeviceId: "ユニットID",
  LastStatus: "最新の状態",
  DateSelector: "編集",
  AlaramList: "警報一覧",
  Number: "識別No.",
  SensorID: "ユニットID",
  SensorNane: "ユニット名称",
  UnitTypeAndName: "名称",
  Clear: "OK",
  Cancel: "キャンセル",
  GraphSettings: "Graph Settings",
  TimeRangeToDisplay: "表示する期間を編集",
  To: "から",
  Until: "まで",
  DatesDisplay: "日間表示",
  ChooseDisplayFormat: "表示フォーマット選択",
  MaxValueDisplay: "最大値表示",
  MinValueDisplay: "最小値表示",
  DownloadCSVWithoutDisplayGraph: "グラフを表示せずそのままレポート出力",
  Edit: "編集",
  SensorListPageTitle: "表示店舗",
  HACCP: "表示絞り込み",
  CautionDesc: "警告通知温度を超過",
  WarningDesc: "管理温度を超過",
  SensorSettings: "センサー設定",
  SensorSettingsId: "識別 No.(R):",
  UnitId: "ユニット ID(R):",
  Type: "種別",
  OperationMode: "運転モード",
  Refrigerate: "冷蔵",
  Freeze: "冷凍",
  Network: "ネットワーク(R):",
  DefrostTime: "霜取り時間",
  DefrostModeEffectiveInvalid: "霜取りモードの有効／無効",
  StorageTemperatureRange: "警告設定温度（保管温度範囲）",
  LowerLimit: "下限",
  UpperLimit: "上限",
  WarningTemperatureRange: "注意設定温度(R)（保管温度範囲）",
  DefrostWarningTemperature: "霜取り警告温度(霜取り通常温度範囲)",
  PowerWiredConnection: "電源有線接続",
  DefrostTimingSettings: "霜取りタイミング設定",
  DefrostSettings: "霜取り設定",
  DefrostMode: "霜取りモード",
  EstimatedDefrostOperationTime: "霜取り動作想定時間(分)",
  DefrostTimingList: "霜取りタイミング一覧",
  BasicSettings: "基本設定",
  PowerConnectionONOff: "電源有線接続On/Off (R)",
  isWired: "有線タイプ",
  isBattery: "電池タイプ",
  EnableAlertMonitoring: "アラート監視の有効化",
  DisableAlertMonitoring: "アラート監視の無効化",
  AdvancedSettings: "上級者向けの設定",
  EnableEditing: "編集の有効化",
  IdentificationNo: "識別 no.",
  API: "API",
  SamplingTiming: "サンプリング時間(分)",
  TemperatureSettings: "温度設定",
  KanriTemp: "管理温度",
  KanriTempDisabled: "管理温度の下限を無効化",
  AlertNotificationTemperature: "警告通知温度",
  AlertNotificationTempDisabled: "警告通知温度下限を無効化",
  AbnormalTemp: "異常判定温度",
  TemperatureCorrectionValue: "温度補正値",
  Set: "設定",
  GoBack: "戻る",
  SystemSettings: "システム設定",
  Map: "マップ",
  NotificationSettings: "通知設定",
  EMailAddressList: "送信先メールアドレス一覧",
  EMail: "メールアドレス",
  Search: "検索",
  PleaseWait: "操作中",
  Add: "追加",
  AddCompanyAdmin: "追加 ー 会社管理者",
  EditCompanyAdmin: "編集 ー 会社管理者",
  Company: "会社",
  CompanyName: "会社名",
  CompanyValidation: "会社名が必要です",
  UserName: "ユーザー名",
  UserNameValidation: "ユーザー名が必要です",
  Email: "メール",
  EmailValidation: "メールが必要です",
  ValidEmailAddress: "有効なメールアドレスを入力してください",
  Password: "パスワード",
  PasswordValidation: "パスワードが必要です",
  Department: "部門",
  DepartmentName: "部門名",
  DepartmentList: "部門リスト",
  DepartmentValidation: "部門名が必要です",
  Actions: "アクション",
  Confirmation: "確認",
  ConfirmationBMML: "BM/ML",
  ConfirmationBMMLCancel: "警告",
  Delete: "削除",
  View: "見る",
  ConfirmXmlUpload: "{:bm}に{:filename}のデータをアップロードしますか？",
  DeleteMessage: "削除しますか？",
  AccessibleBM: "店舗のBM",
  GeneralUser: "店舗ユーザー",
  AddGeneralUser: "追加 ー 店舗ユーザー",
  EditGeneralUser: "編集 ー 店舗ユーザー",
  DepartmentManagement: "本部マネージャー",
  DepartmentManagerManagement: "部門マネージャー管理",
  AddDepartmentManager: "追加 - 部門マネージャー",
  EditDepartmentManager: "編集 - 部門マネージャー",
  GeneralUserManagement: "店舗ユーザー管理",
  StoreName: "店舗名",
  Yomigana: "よみがな",
  MaintenancePersonEmail: "メンテナンス担当者",
  StoreNameValidation: "店舗名が必要です",
  NumberOfSensors: "センサーの数",
  AddDepartmentAccount: "追加 ー 部門マネージャー",
  EditDepartmentAccount: "編集 ー 部門マネージャー",
  Reset: "リセット",
  Show: "Show",
  Hide: "Hide",
  BmList: "BMリスト",
  SerialId: "シリアルID",
  SerialIdValidation: "シリアルIDが必要です",
  AddBm: "追加ーBM",
  EditBm: "編集ーBM",
  BmNameValidation: "BM名が必要です",
  NoDataFound: "データが見つかりませんでした",
  SelectCompanyToDisplay: "表示する会社を選択してください",
  PleaseSelectBm: "BMを選択してください",
  ThreeDaysOrLessDisplayPlotText:
    "プロット表示時間単位でデータ表示されます（3日以内）",
  FourDaysOrMoreDisplayPlotText:
    "プロット表示は日の平均データがプロットされます（4日以上）",
  Plot: "プロット",
  Upload: "アップロード",
  Download: "ダウンロード",
  TimeUnit: "時間単位",
  Daily: "日単位",
  EmailDeletedSuccess: "メールは正常に削除されました",
  SelectBM: "BM選択",
  SelectCompany: "会社選択",
  SelectDepartment: "部門選択",
  BmAddSuccess: "BMが正常に追加されました",
  BmUpdateSuccess: "BMが正常に更新されました",
  BmDeletedSuccess: "BMが正常に削除されました",
  BmDeletedError: "BMが削除できませんでした",
  UserCreateSuccess: "ユーザーが正常に追加されました",
  DataUpdatedSuccess: "データーが正常にアップロードされました",
  UserDeletedSuccess: "ユーザーが正常に削除されました",
  alcohol: "アルコール",
  drink: "飲み物",
  icecream: "アイスクリーム",
  kai: "貝",
  kakou: "加工",
  nichihaihinn: "日配品",
  nippai: "日配",
  norimaki: "海苔巻",
  nyuseihin: "乳製品",
  onigiri: "おにぎり",
  reitoshokuhin: "冷凍食品",
  sakana: "魚",
  salada: "サラダ",
  seika: "青果",
  seiniku: "精肉",
  sonota: "その他",
  sushi: "寿司",
  tamago: "卵",
  yasai: "野菜",
  ReleaseNotes: "リリースノート",
  None: "なし",
  Time: "定期時間設定",
  TempVal: "温度管理設定",
  UpdateStoreSettingsSuccess: "店舗設定が更新されました",
  UpdateSensorSettingsSuccess: "設定更新されました",
  reportModalTitle: "レポート作成",
  chooseReportFormat: "レポートフォーマット選択",
  from: "から",
  AlaramDeletionTime: "アラート停止時間設定(0～12時間)",
  AlaramDeletionWarnTime: "警報設定を解除してから復帰するまでの時間",
  SaveTimeSetting: "保存タイミング設定",
  ReportLoadingText: "レポート作成中",
  PauseAlertSuccess: "アラート停止されました",
  ResumeAlertSuccess: "アラート停止が解除されました",
  HighlightSettingMessage:
    "最終受信時刻が下記の設定時間を超えた場合、ハイライトされます。",
  Hours: "時間",
  SetDepartmentSettingSuccess: "部門設定が更新されました",
  XmlUpload: "XMLアップロード",
  XmlUploadSuccess: "データがアップロードされました",
  XmlUploadError: "データがアップロードできませんでした",
  UploadBtm: "アップロード",
  Loading: "操作中",
  ScreenSettings: "画面設定",
  AddCompanySuccess: "会社が登録されました",
  UpdateCompanySuccess: "会社が更新されました",
  DeleteCompanySuccess: "会社が削除されました",
  AddDepartmentManagerSuccess: "部門マネージャーが登録されました",
  UpdateDepartmentManagerSuccess: "部門マネージャーが更新されました",
  DeleteDepartmentManagerSuccess: "部門マネージャーが削除されました",
  AddStoreUserSuccess: "店舗ユーザーが登録されました",
  UpdateStoreUserSuccess: "店舗ユーザーが更新されました",
  DeleteStoreUserSuccess: "店舗ユーザーが削除されました",
  FindErrors: "通信エラー検索",
  SearchFilter: "絞り込み実行",
  AlertHistoryModal: "警告履歴一覧",
  ClearList: "アラートクリア",
  NameAndNumber: "センサーNo.",
  AlertTime: "発生日時",
  AlertStatus: "状態",
  EditAlertModal: "警告発生履歴",
  Temperature: "温度(°C)",
  AlertComment: "発生事由/経緯/原因を記入",
  ClearAlertHistorySuccess: "アラート発生履歴をクリアしました",
  UpdateAlertCommentSuccess: "発生事由/経緯/原因を入力しました",
  HozonTimingsError: "保存タイミングが読み込めませんでした",
  LoadingError: "ロードエラー",
  AlertMinMaxValidationErrorMessage:
    "警告通知の最高温度は、警告通知の最低温度より3°C高くする必要があります",
  WarningMinMaxValidationErrorMessage:
    "管理通知温度の上限下限値の差が３℃以上に設定必要があります。",
  isWiredLabel: "ユニットタイプ",
  ListOfRecipients: "送信先メールアドレス一覧",
  HeadingError: "通信\nエラー",
  InvalidTimeFound: "入力時間を修正してください",
  DuplicatedEmail: "ご入力頂いたメールアドレスは既に登録されています",
  NotificationTimeFrame: "メール配信有効時間範囲",
  InvalidNotificationTimeRange: "ご入力頂いた範囲が無効です",
  PleaseSetTime: "(時刻を設定してください)",
  UpdateMapBackground: "更新",
  UpdateMapBackgroundSuccess: "マップの更新が成功しました",
  UpdateMapBackgroundFail: "マップの更新が失敗しました",
  ConfirmUpdateMapBackgroundMessage: "マップを更新しますか？",
  List: "リスト",
  IconSize: "icon size",
  MapZoom: "map zoom",
  PositionUpdateSuccess: "センサーの位置が正常に更新されました。",
  PositionUpdateSuccessBMML: "BM/MLの位置が正常に更新されました。",
  PositionUpdateFailure: "センサー位置の更新が失敗しました。",
  PositionUpdateFailureBMML: "BM/MLの位置の更新が失敗しました。",
  Update: "更新",
  UpdateMessage: "更新しますか？",
  UpdateMessageBMML: "BM/MLの情報を更新しますか？",
  UpdateMessageBMMLCancle: "センサー位置が更新されています。\n先に保存してください。",
  SensorNumber: "センサーNo",
  Hour: "時",
  StartTime: "レポート開始時刻",
  EndTime: "レポート終了時刻",
  Interval: "レポート間隔(時間)",
  FinalMeasurement: "最終表示時刻(R)",
  OKPressRequired: "部門名を入力後「OK」ボタンの動作が必要です。",
  Name: "名称",
  SensorId: "センサID",
  ControlTemperatureNotation: "管理温度",
  SensorType: "センサタイプ",
  EmailDeliveryText: "終日メール配信有効",
  ClearButtonText: "クリア",
  OccurrenceTime: "発生日時",
  CurrentDayExcludedText: "当日のレポートは出力できません",
  Sensor: "センサー",
  Save: "保存する",
  Register: "登録",
  AllFieldsAreRequired: "* フィールドは必須項目です",
  SensorAddSuccess: "センサーが正常に追加されました",
  SensorDeleteSuccess: "センサーは正常に削除されました",
  SensorUpdatedSuccess: "センサーが正常に更新されました",
  SendMailTemplatesSuccess: "テストメールを送信致しました。",
  OnlyHiragana: "ひらがなのみ入力可能",
  DataUpdatedSuccessfully: "データは正常に更新されました",
  MaintenancePeriod: "メンテナンス時期",
  LastMaintenanceDate: "前回メンテナンス日",
  IntroductionDay: "導入日",
  NextMaintenance: "次回メンテナンス日",
  MaintenanceDateSetting: "メンテナンス日設定",
  SendMaintenanceEmail: "メンテナンスメール送信",
  NextMaintenanceDateError:
    "次期メンテナンス日は、最終メンテナンス日または導入日より前に設定することはできません。",
  GuidanceEmail: "案内 メール",
  Receiver: "宛先",
  ChooseMailList: "メールリストを選択してください",
  EnterMailAddress: "メールアドレスを入力",
  Subject: "件名",
  GuidanceMailTemplateDefaultOption: "テンプレートを選択してください",
  EmailContent: "メール内容",
  FunctionGuidanceMail: "機能案内メール",
  RequiredField: "このフィールドを空白にすることはできません",
  ConfirmationScreen: "確認画面へ",
  TransmissionHistory: "送信履歴",
  History: "履歴",
  EmailTimestamp: "送信時刻",
  TableFilterLabel: "フィルター",
  TableFilterInput: "入力",
  InDF: "(霜取)",
  SlotNoLabel: "識別No.",
  NetworkLabel: "ネットワーク",
  NoLabel: "No.",
  MacLabel: "MAC",
  SensorIDLabel: "センサーID",
  ProductLabel: "種別",
  AdminMaxTempLabel: "異常温度上限",
  AlertMinTempLabel: "警告通知温度下限",
  AlertMaxTempLabel: "警告通知温度上限",
  WarningMinTempLabel: "管理温度下限",
  WarningMaxTempLabel: "管理温度上限",
  PositionXLabel: "Pos X",
  PositionYLabel: "Pos Y",
  DefrostTimingsLabel: "霜取りタイミング",
  HideSlotLabel: "識別No.を隠す",
  UnhideSlotLabel: "識別No.を表示する",
  ValueLabel: "入力値",
  WarningMaxValidationErrorMessage: "管理温度上限は-25°C〜35°C範囲入力可能",
  WarningMinValidationErrorMessage: "管理温度下限は-30°C〜30°C範囲入力可能",
  AlertMaxValidationErrorMessage: "警告温度上限は-20°C〜40°C範囲入力可能",
  AlertMinValidationErrorMessage: "警告温度下限は-35°C〜25°C範囲入力可能",
  DefrostMaxValidationErrorMessage: "異常判定温度上限は-15°C〜45°C範囲入力可能",
  DefrostMinutesValidationErrorMessage: "霜取り動作想定時間(分)は60~180入力可能",
  TemperaturesValidationErrorMessage:
    "入力された温度は必ず警告温度下限 ≤ 管理温度下限 ≤ 管理温度上限 ≤ 警告温度上限 ≤ 異常判定温度上限",
  Bmtitle: "BM名",
  Nickname: "ニックネーム",
  InvalidFileExt: "無効なファイルタイプ拡張子",
  NoItems: "該当なし",
  SelectState: "状態選択",
  InvalidCreds: "ユーザー名またはパスワードが無効です。",
  PermissionDeniedMessage: "ポータルにログインする権限がありません",
  ReportNotGenerated: "レポートを生成できませんでした",
  CantSpecifyStore: "店舗を指定できません",
  InvalidDateRangeFound: "無効な日付範囲です",
  PleaseEnterTheTime: "時間を入力してください",
  Send: "送信",
  SeparateTheInputsWithCommas: "入力をコンマで区切りしてください",
  InvalidEmailAddressFound: "「emailsToBeReplace」は無効です。再確認ください。",
  ConfirmSendMailWarning:
    "メールの宛先と内容をご確認の上、送信ボタンを押してください。",
  AdditionEmails: "追加メールアドレス",
  GuidanceEmailList: "案内メールリスト",
  Input: "入力",
  BmOTA: "BMの遠隔OTAの画面",
  Current: "現在の",
  BackendVersion: "Backendのバージョン",
  VersionForOTA: "OTA用バージョン",
  OTAExecution: "OTA実行",
  OMVerSelection: "OM Ver選択",
  ReadDB: "DB読み込み",
  ReadBM: "BM読み込み",
  SearchFor: "検索",
  All: "商用物件",
  EmailBodyRequired: "メール内容の入力が必要です",
  ReEdit: "再編集",
  Template: "テンプレート",
  ExportSensors: "エクスポート",
  ExportSensorsError: "エクスポートエラー",
  ClearConfirmText: "「OK」を押すとクリアを実行します。",
  FileSizeValidation: "1MB制限があります、再確認ください。",
  News: "お知らせ",
  UserGuide: "利用ガイド",
  Close: "閉じる",
  PC_WEB: "PC・WEBアプリ共通",
  OperatingProcedureManual: "操作手順書",
  Size: "判",
  Page: "ページ",
  ViewInBrowser: "ブラウザーで表示",
  PDFDownload: "PDFダウンロード",
  SystemNotificationTitle: "システムお知らせ",
  SetSystemNotificationError: "システムお知らせ設定エラー",
  SetSystemNotificationSuccessfully: "システムお知らせ設定成功しました",
  UserGuideDocsTitle: "ユーザーガイド書類",
  SetUserGuideDocsError: "ユーザーガイド書類設定エラー",
  SetUserGuideDocsSuccessfully: "ユーザーガイド書類設定成功しました",
  PreviewButton: "プレビュー",
  ChooseFile: "ファイル選択",
  NoFileChosen: "ファイル未選択",
  LoadPreviewFailed: "プレビューを表示できません",
  PreventBackConfirm: "メンテナンスメール画面から移動しますが、OKですか？",
  SIMSettings: "店舗SIM設定",
  MonitoringHQ: "遠隔監視管理者",
  ContructionUser: "工事部",
  NoEmailsWereAdded: "メールアドレスが登録されていません。",
  TestStore: "テスト表示",
  Users: "ユーザー",
  IsAdmin: "店舗管理アクセス",
  AccessibleStores: "アクセス可能",
  FullScreen: "全画面表示",
  MonitoredStores: "監視店舗数 {0} 店舗",
  OperationalSensors: "運用センサ総数 {0}	台",
  Timestamp: "時刻",
  ChangeFrom: "更新前",
  ChangeTo: "更新後",
  EventType: "更新タイプ",
  TextEventType: {
    CREATE_STORE: "店舗作成",
    UPDATE_STORE: "店舗更新",
    DELETE_STORE: "店舗削除",
    UPDATE_STORE_SETTINGS: "店舗設定更新",
    UPDATE_SENSOR_SETTINGS: "センサー設定更新",
    DELETE_SENSOR: "センサー削除",
    CREATE_SENSOR: "センサー作成",
    UPDATE_SENSOR: "センサー更新",
    UPDATE_SLOT_HIDDEN_STATUS: "識別No.隠れる更新",
    SET_SENSORS_POSITIONS: "センサー位置設定",
    UPDATE_MAP: "マップ更新",
    ONSITE_UPLOAD_STORE_HEARING: "店舗Hearingアップロード",
    ONSITE_DOWNLOAD_STORE_HEARING: "店舗Hearingダウンロード",
    ONSITE_UPLOAD_STORE_SETTINGS: "店舗Settingsアップロード",
    ONSITE_DOWNLOAD_STORE_SETTINGS: "店舗Settingsダウンロード",
    ONSITE_REGISTER_STORE_DEVICE: "BMが登録されました",
    ONSITE_UPLOAD_DEVICE_SETTINGS: "BM設定アップロード",
    ONSITE_DOWNLOAD_DEVICE_SETTINGS: "BM設定ダウンロード",
  },
  Logs: '更新ログ',
  NotifyOnReconnect: '再接続通知',
  ToggleReconnectNotificationSuccess: '再接続通知がオンに設定されました',
  ToggleReconnectNotificationError: '再接続通知がオフに設定されました',
  AlertPauseMinutes: 'アラート停止時間設定',
  DepartmentId: '部門ID',
  HozonTimings: '保存タイミング',
  ProductCode: '種別',
  defrostModeNone: "なし",
  defrostModeTemp: "温度設定",
  defrostModeTime: "定期間設定",
  Home: "ホーム",
  IsNoChange: "変更なし",
  LoadingStoreData: "店舗データを読み込み中",
  SummaryEnabled: "サマリーメール",
  HearingFiles: "Hearing (保留中)",
  SettingFiles: "Settings (保留中)",
  UploadStoreHearingSuccess: "Hearingファイルが保存されました",
  UploadStoreSettingsSuccess: "Settingsファイルが保存されました",
  StoreHearingFileNotFound: "保留中のHearingファイルが見つかりませんでした",
  StoreSettingFilesNotFound: "保留中のSettingsファイルが見つかりませんでした",
  StoreHoldingFilesNotFound: "店舗の保有ファイルはありません。",
  UploadDeviceSettingsSuccess: "BMのSettingsファイルが保存されました",
  SelectStore: "店舗選択",
  DisplaySensors: "センサーリスト表示",
  SignalStatus: "電波強度",
  SignalStatuses: {
    excellent: "Excellent",
    good: "Good",
    poor: "Poor",
  },
  LastInteractedTime: "最新データ取得時刻",
  CreateSetupAdmin: "設定管理者作成",
  EditSetupAdmin: "設定管理者編集",
  DeleteSetupAdmin: "設定管理者削除",
  ConfirmDeleteSetupAdmin: "{username}\nユーザーを削除しますか？",
  PleaseSelectStoreAlert: "店舗を選択してください",
  ConfirmRegisterDevice: "{:storeName}に{:deviceId}を追加しますか？",
  RegisterStoreDeviceSuccess: "BMが店舗に追加しました",
  DeviceRegistration: "BM登録",
  DeviceSettings: "BM設定",
  FilenameIsNotMatchError: "ファイル名が選択されたBMと一致しない。",
};

export default TextConstants;