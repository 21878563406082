import { all, fork, takeLeading, call, put, select } from "redux-saga/effects";
import * as Actions from "../actions";
import Apis from 'src/store/api/ApiConstants';
import * as client from "src/helpers/HTTPRequest";
import { NotificationManager } from "react-notifications";
import TextConstants from "src/helpers/TextConstants";

function* getDepartments() {
  try {
    const result = yield call(fetchDepartments);
    yield put({ type: Actions.GET_DEPARTMENTS_SUCCESS, payload: result });
  } catch (error) {
    console.log("getDepartments error : ", error);
    yield put({ type: Actions.GET_DEPARTMENTS_FAILURE });
  }
}

function* getCompanyDepartments({ payload }) {
  try {
    const result = yield call(fetchDepartments, payload);
    yield put({ type: Actions.GET_COMPANY_DEPARTMENTS_SUCCESS, payload: result });
  } catch (error) {
    console.log("getCompanyDepartments error : ", error);
    yield put({ type: Actions.GET_COMPANY_DEPARTMENTS_FAILURE });
  }
}

function fetchDepartments(companyId = null) {
  let url = `${Apis.BASE_URL}${Apis.DEPARTMENTS}`;
  if (companyId) {
    url += `?companyId=${companyId}`;
  }
  return client.get(url);
}

function* getDepartmentManagers() {
  try {
    const result = yield call(fetchDepartmentManagers);
    yield put({ type: Actions.GET_DEPARTMENT_MANAGERS_SUCCESS, payload: result });
  } catch (error) {
    console.log("getDepartmentManagers error : ", error);
    yield put({ type: Actions.GET_DEPARTMENT_MANAGERS_FAILURE });
  }
}

function fetchDepartmentManagers() {
  const url = `${Apis.BASE_URL}${Apis.DEPARTMENT_MANAGERS}`;
  return client.get(url);
}

function* addDepartmentManager({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.DEPARTMENT_MANAGERS}`;
    const response = yield call(client.post, url, payload);
    yield put(Actions.addDepartmentManagerSuccess(response));
    NotificationManager.success(TextConstants.AddDepartmentManagerSuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("addDepartmentManager error : ", error);
    yield put(Actions.addDepartmentManagerFailure());
  }
}

function* updateDepartmentManager({ payload }) {
  try {
    const { id, data } = payload
    const url = `${Apis.BASE_URL}${Apis.DEPARTMENT_MANAGERS}/${id}`;
    const response = yield call(client.put, url, data);
    yield put(Actions.updateDepartmentManagerSuccess(response));
    NotificationManager.success(TextConstants.UpdateDepartmentManagerSuccess);
    yield put(Actions.fetBMsListForAll());
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("updateDepartmentManager error : ", error);
    yield put(Actions.updateDepartmentManagerFailure());
  }
}

function* deleteDepartmentManager({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.DEPARTMENT_MANAGERS}/${payload}`;
    yield call(client.deleteMethod, url);
    yield put(Actions.deleteDepartmentManagerSuccess(payload));
    NotificationManager.success(TextConstants.DeleteDepartmentManagerSuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("deleteDepartmentManager error : ", error);
    yield put(Actions.deleteDepartmentManagerFailure());
  }
}

function* getDepartmentSettings() {
  try {
    const departmentId = yield (select(state => state.auth.user.attributes['custom:department_id']))
    const url = `${Apis.BASE_URL}${Apis.DEPARTMENTS}/${departmentId}/settings`;
    const response = yield call(client.get, url);
    yield put(Actions.getDepartmentSettingsSuccess(response));
  } catch (error) {
    NotificationManager.error(error.message);
    yield put(Actions.getDepartmentSettingsFailure());
  }
}

function* setDepartmentSettings({ payload }) {
  try {
    const departmentId = yield select(state => state.auth.user.attributes['custom:department_id'])
    const url = `${Apis.BASE_URL}${Apis.DEPARTMENTS}/${departmentId}/settings`;
    const response = yield call(client.post, url, payload);
    yield put(Actions.setDepartmentSettingsSuccess(response));
    NotificationManager.success(TextConstants.SetDepartmentSettingSuccess);
  } catch (error) {
    NotificationManager.error(error.message);
    console.log("setDepartmentSettings error : ", error);
    yield put(Actions.setDepartmentSettingsFailure());
  }
}

export function* watchDepartment() {
  yield takeLeading(Actions.GET_DEPARTMENTS, getDepartments);
  yield takeLeading(Actions.GET_COMPANY_DEPARTMENTS, getCompanyDepartments);
  yield takeLeading(Actions.GET_DEPARTMENT_MANAGERS, getDepartmentManagers);
  yield takeLeading(Actions.ADD_DEPARTMENT_MANAGER, addDepartmentManager);
  yield takeLeading(Actions.UPDATE_DEPARTMENT_MANAGER, updateDepartmentManager);
  yield takeLeading(Actions.DELETE_DEPARTMENT_MANAGER, deleteDepartmentManager);
  yield takeLeading(Actions.GET_DEPARTMENT_SETTINGS, getDepartmentSettings);
  yield takeLeading(Actions.SET_DEPARTMENT_SETTINGS, setDepartmentSettings);
}

export default function* rootSaga() {
  yield all([fork(watchDepartment)]);
}
