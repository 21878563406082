import { all } from "redux-saga/effects";
import appSagas from "./app";
import authSagas from "./auth";
import dashboardSagas from "./dashboard";
import sensorSagas from "./sensor";
import bmsSagas from "./bms";
import companySagas from "./company";
import departmentSagas from "./department";
import graphSensorSagas from "./graph_sensor";
import storeSagas from "./store";
import settingsSagas from "./settings";
import setupDashboardSagas from "./setup_dashboard";

export default function* rootSaga(getState) {
  yield all([
    appSagas(),
    authSagas(),
    dashboardSagas(),
    sensorSagas(),
    bmsSagas(),
    companySagas(),
    departmentSagas(),
    graphSensorSagas(),
    storeSagas(),
    settingsSagas(),
    setupDashboardSagas(),
  ]);
}
