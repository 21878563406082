import * as Actions from "../actions";

const initialState = {
  type: "",
  news: {
    loading: false,
    dataHtml: null,
  },
  userGuide: {
    loading: false,
    fileData: null,
  },
};

const Settings = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.SET_NEWS:
      return {
        ...state,
        type,
        news: { ...state.news, loading: true },
      };
    case Actions.SET_NEWS_SUCCESS:
      return {
        ...state,
        type,
        news: { ...state.news, loading: false, dataHtml: payload },
      };
    case Actions.SET_NEWS_FAILURE:
    case Actions.SET_NEWS_CLEAN_STATE:
      return {
        ...state,
        type,
        news: { ...state.news, loading: false },
      };

    case Actions.GET_NEWS:
      return {
        ...state,
        type,
        news: { ...state.news, loading: true },
      };
    case Actions.GET_NEWS_SUCCESS:
      return {
        ...state,
        type,
        news: { ...state.news, loading: false, dataHtml: payload },
      };
    case Actions.GET_NEWS_FAILURE:
    case Actions.GET_NEWS_CLEAN_STATE:
      return {
        ...state,
        type,
        news: { ...state.news, loading: false },
      };

    case Actions.SET_USER_GUIDE_DOCS:
      return {
        ...state,
        type,
        userGuide: { ...state.userGuide, loading: true },
      };
    case Actions.SET_USER_GUIDE_DOCS_SUCCESS:
      return {
        ...state,
        type,
        userGuide: { ...state.userGuide, loading: false },
      };
    case Actions.SET_USER_GUIDE_DOCS_FAILURE:
    case Actions.SET_USER_GUIDE_DOCS_CLEAN_STATE:
      return {
        ...state,
        type,
        userGuide: { ...state.userGuide, loading: false },
      };

    case Actions.GET_USER_GUIDE_DOCS:
      return {
        ...state,
        type,
        userGuide: { ...state.userGuide, loading: true },
      };
    case Actions.GET_USER_GUIDE_DOCS_SUCCESS:
      return {
        ...state,
        type,
        userGuide: {
          ...state.userGuide,
          fileData: payload,
          loading: false,
        },
      };
    case Actions.GET_USER_GUIDE_DOCS_FAILURE:
    case Actions.GET_USER_GUIDE_DOCS_CLEAN_STATE:
      return {
        ...state,
        type,
        userGuide: { ...state.userGuide, loading: false },
      };

    default:
      return state;
  }
};

export default Settings;
