import {
  all,
  fork,
  put,
  takeEvery,
  call,
  select,
  takeLatest,
} from "redux-saga/effects";
import * as StoreActions from "../actions";
import { NotificationManager } from "react-notifications";
import * as client from "src/helpers/HTTPRequest";
import TextConstants from "src/helpers/TextConstants";
import Apis from "src/store/api/ApiConstants";
import { DefaultNotificationTime, DefaultSavingTime } from "src/helpers/Utils";

function* getStores() {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}`;
    const response = yield call(client.get, url);
    yield put(StoreActions.fetchStoresSuccess(response));
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.fetchStoresFailure());
  }
}

function* getDepartmentStores({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}?departmentId=${payload}`;
    const response = yield call(client.get, url);
    yield put(StoreActions.fetchDepartmentStoresSuccess(response));
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.fetchDepartmentStoresFailure());
  }
}

function* addStore({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}`;
    const response = yield call(client.post, url, payload);
    yield put(StoreActions.addStoreSuccess(response));
    NotificationManager.success(TextConstants.AddStoreUserSuccess);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.addStoreFailure());
  }
}

function* updateStore({ payload }) {
  try {
    const { id, data } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORES}/${id}`;
    const response = yield call(client.put, url, data);
    yield put(StoreActions.updateStoreSuccess(response));
    NotificationManager.success(TextConstants.UpdateStoreUserSuccess);
    yield put(StoreActions.fetBMsListForAll());
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.updateStoreFailure());
  }
}

function* deleteStore({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}/${payload}`;
    yield call(client.deleteMethod, url);
    yield put(StoreActions.deleteStoreSuccess(payload));
    NotificationManager.success(TextConstants.DeleteStoreUserSuccess);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.deleteStoreFailure());
  }
}

function* getStoreSettings() {
  try {
    const { selectedStore } = yield select((state) => state.sensor);
    let storeId = selectedStore?.id;
    if (!storeId) {
      const hasSelectedStore = localStorage.getItem("SelectedStore");
      if (hasSelectedStore) {
        const store = JSON.parse(hasSelectedStore);
        storeId = store?.id;
        selectedStore['id'] = storeId
      }
    }
    const url = `${Apis.BASE_URL}${Apis.STORES}/${storeId}/settings`;
    const storeData = yield call(client.get, url);
    yield put(
      StoreActions.getStoreSettingsSuccess({ ...selectedStore, ...storeData })
    );
  } catch (error) {
    yield put(StoreActions.getStoreSettingsFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* updateStoreSettings({ payload }) {
  try {
    const { selectedStore } = yield select((state) => state.sensor);
    const storeUrl = `${Apis.BASE_URL}${Apis.STORES}/${selectedStore?.id}/settings`;
    const store = yield call(client.put, storeUrl, payload);
    yield put(
      StoreActions.updateStoreSettingsSuccess({
        savingTime: store.savingTime || DefaultSavingTime,
        hozonTimings: store.hozonTimings || [],
        alertPauseMinutes:
          store.alertPauseMinutes !== null ? store.alertPauseMinutes : 10,
        defrostSettings: store.defrostSettings || [],
        notificationTime: store.notificationTime || DefaultNotificationTime,
      })
    );
    NotificationManager.success(
      TextConstants.UpdateStoreSettingsSuccess,
      "",
      5000
    );
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.updateStoreSettingsFailure());
  }
}

function* requestPauseAlert({ payload }) {
  try {
    const { isPausing } = payload;
    const { selectedStore } = yield select((state) => state.sensor);
    const url = `${Apis.BASE_URL}${Apis.STORES}/${selectedStore.id}/${
      isPausing ? "resume" : "pause"
    }-alert`;
    const store = yield call(client.post, url);
    yield put(
      StoreActions.pauseAlertSuccess({ lastPauseAlert: store.lastPauseAlert })
    );

    let messageToShow = TextConstants.PauseAlertSuccess;
    if (isPausing) {
      messageToShow = TextConstants.ResumeAlertSuccess;
    }

    NotificationManager.success(messageToShow, "", 5000);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.pauseAlertFailure());
  }
}

function* requestSendTemplates({ payload }) {
  try {
    const { selectedStore } = yield select((state) => state.sensor);
    const url = `${Apis.BASE_URL}${Apis.STORES}/${selectedStore.id}/test-mails`;
    yield call(client.post, url, payload);
    yield put(StoreActions.sendTemplatesSuccess());
    NotificationManager.success(
      TextConstants.SendMailTemplatesSuccess,
      "",
      5000
    );
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.sendTemplatesFailure());
  }
}

function* getStoreUsers({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}/${payload}/users`;
    const users = yield call(client.get, url);
    yield put(StoreActions.getStoreUsersSuccess(users));
  } catch (error) {
    yield put(StoreActions.getStoreUsersFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* addStoreUsers({ payload }) {
  try {
    console.log(payload);
    const { storeId, user } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORES}/${storeId}/users`;
    yield call(client.post, url, user);
    if (user["password"]) {
      delete user["password"];
    }
    user["storeId"] = storeId;
    yield put(StoreActions.addStoreUsersSuccess(user));
    NotificationManager.success(TextConstants.AddStoreUserSuccess);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.addStoreUsersFailure());
  }
}

function* updateStoreUsers({ payload }) {
  try {
    const { storeId, user } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORES}/${storeId}/users/${user.username}`;
    yield call(client.put, url, user);
    if (user["password"]) {
      delete user["password"];
    }
    user["storeId"] = storeId;
    yield put(StoreActions.updateStoreUsersSuccess(user));
    NotificationManager.success(TextConstants.UpdateStoreUserSuccess);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.updateStoreUsersFailure());
  }
}

function* deleteStoreUsers({ payload }) {
  try {
    const { storeId, username } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORES}/${storeId}/users/${username}`;
    yield call(client.deleteMethod, url);
    yield put(StoreActions.deleteStoreUsersSuccess(username));
    NotificationManager.success(TextConstants.DeleteStoreUserSuccess);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.deleteStoreUsersFailure());
  }
}

function* getStoreLogs({ payload }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.STORES}/${payload}/logs`;
    const response = yield call(client.get, url);
    yield put(StoreActions.getStoreLogsSuccess(response));
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.getStoreLogsFailure());
  }
}

function* toggleSummaryNotification({ payload }) {
  try {
    const { storeId, isEnabled } = payload;
    const url = `${Apis.BASE_URL}${Apis.STORES}/${storeId}/summary-notification`;
    yield call(client.post, url, { isEnabled });
    yield put(StoreActions.toggleSummaryNotificationSuccess(payload));
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(StoreActions.toggleSummaryNotificationFailure());
  }
}

export function* watchStore() {
  yield takeEvery(StoreActions.GET_STORES, getStores);
  yield takeEvery(StoreActions.GET_DEPARTMENT_STORES, getDepartmentStores);
  yield takeEvery(StoreActions.ADD_STORE, addStore);
  yield takeEvery(StoreActions.UPDATE_STORE, updateStore);
  yield takeEvery(StoreActions.DELETE_STORE, deleteStore);
  yield takeLatest(StoreActions.GET_STORE_SETTINGS, getStoreSettings);
  yield takeEvery(StoreActions.UPDATE_STORE_SETTINGS, updateStoreSettings);
  yield takeEvery(StoreActions.REQUEST_PAUSE_ALERT, requestPauseAlert);
  yield takeEvery(StoreActions.SEND_MAIL_TEMPLATES, requestSendTemplates);
  yield takeEvery(StoreActions.GET_STORE_USERS, getStoreUsers);
  yield takeEvery(StoreActions.ADD_STORE_USERS, addStoreUsers);
  yield takeEvery(StoreActions.UPDATE_STORE_USERS, updateStoreUsers);
  yield takeEvery(StoreActions.DELETE_STORE_USERS, deleteStoreUsers);
  yield takeEvery(StoreActions.GET_STORE_LOGS, getStoreLogs);
  yield takeEvery(StoreActions.TOGGLE_SUMMARY_NOTIFICATION, toggleSummaryNotification);
}

export default function* storeListSaga() {
  yield all([fork(watchStore)]);
}
