import * as Actions from "../actions";

export const fetchDepartments = () => ({
  type: Actions.GET_DEPARTMENTS,
});

export const fetchCompanyDepartments = (data) => ({
  type: Actions.GET_COMPANY_DEPARTMENTS,
  payload: data,
});

export const fetchDepartmentManagers = () => ({
  type: Actions.GET_DEPARTMENT_MANAGERS,
});

export const addDepartmentManager = (payload) => ({
  type: Actions.ADD_DEPARTMENT_MANAGER,
  payload
});
export const addDepartmentManagerSuccess = (payload) => ({
  type: Actions.ADD_DEPARTMENT_MANAGER_SUCCESS,
  payload
});
export const addDepartmentManagerFailure = (payload) => ({
  type: Actions.ADD_DEPARTMENT_MANAGER_FAILURE,
  payload
});

export const updateDepartmentManager = (id, data) => ({
  type: Actions.UPDATE_DEPARTMENT_MANAGER,
  payload: { id, data }
});
export const updateDepartmentManagerSuccess = (payload) => ({
  type: Actions.UPDATE_DEPARTMENT_MANAGER_SUCCESS,
  payload
});
export const updateDepartmentManagerFailure = (payload) => ({
  type: Actions.UPDATE_DEPARTMENT_MANAGER_FAILURE,
  payload
});

export const deleteDepartmentManager = (payload) => ({
  type: Actions.DELETE_DEPARTMENT_MANAGER,
  payload
});
export const deleteDepartmentManagerSuccess = (payload) => ({
  type: Actions.DELETE_DEPARTMENT_MANAGER_SUCCESS,
  payload
});
export const deleteDepartmentManagerFailure = (payload) => ({
  type: Actions.DELETE_DEPARTMENT_MANAGER_FAILURE,
  payload
});

export const getDepartmentSettings = () => ({
  type: Actions.GET_DEPARTMENT_SETTINGS,
});
export const getDepartmentSettingsSuccess = (payload) => ({
  type: Actions.GET_DEPARTMENT_SETTINGS_SUCCESS,
  payload
});
export const getDepartmentSettingsFailure = (payload) => ({
  type: Actions.GET_DEPARTMENT_SETTINGS_FAILURE,
  payload
});

export const setDepartmentSettings = (payload) => ({
  type: Actions.SET_DEPARTMENT_SETTINGS,
  payload
});
export const setDepartmentSettingsSuccess = (payload) => ({
  type: Actions.SET_DEPARTMENT_SETTINGS_SUCCESS,
  payload
});
export const setDepartmentSettingsFailure = (payload) => ({
  type: Actions.SET_DEPARTMENT_SETTINGS_FAILURE,
  payload
});