import { all, fork, put, takeEvery } from "redux-saga/effects";
import * as dashboardActions from "../actions";
import { GET_DASHBOARD } from "../actions";

function* fetchDashboard() {
  // let data = yield call(dashboardApi.fetchData);
  yield put(dashboardActions.setDashboardSuccess([]));
  yield put(dashboardActions.setDashboardCleanState());
}

export function* watchDashboard() {
  yield takeEvery(GET_DASHBOARD, fetchDashboard);
}

export default function* dashboardSaga() {
  yield all([fork(watchDashboard)]);
}
