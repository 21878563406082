import * as SensorGraphActionTypes from "../actions";

export const fetchSensorGraphData = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA,
  payload: data,
});
export const setSensorGraphDataSuccess = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_SUCCESS,
  payload: data,
});
export const setSensorGraphDataFailure = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_FAILURE,
});
export const setSensorGraphDataCleanState = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_CLEAN_STATE,
});
export const setSensorGraphDataClear = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_CLEAR,
});

export const fetchSensorGraphDataDaily = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_DAILY,
  payload: data,
});
export const setSensorGraphDataDailySuccess = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_DAILY_SUCCESS,
  payload: data,
});
export const setSensorGraphDataDailyFailure = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_DAILY_FAILURE,
});
export const setSensorGraphDataDailyCleanState = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_GRAPH_DATA_DAILY_CLEAN_STATE,
});

export const getProductSettings = () => ({
  type: SensorGraphActionTypes.GET_PRODUCT_SETTINGS,
});
export const getProductSettingsSuccess = (data) => ({
  type: SensorGraphActionTypes.GET_PRODUCT_SETTINGS_SUCCESS,
  payload: data,
});
export const getProductSettingsFailure = () => ({
  type: SensorGraphActionTypes.GET_PRODUCT_SETTINGS_FAILURE,
});

export const letDownloadRequestedData = (data) => ({
  type: SensorGraphActionTypes.LET_DOWNLOAD_REQUESTED_DATA,
  payload: data,
});
export const letDownloadRequestedDataSuccess = (data) => ({
  type: SensorGraphActionTypes.LET_DOWNLOAD_REQUESTED_DATA_SUCCESS,
  payload: data,
});
export const letDownloadRequestedDataFailure = () => ({
  type: SensorGraphActionTypes.LET_DOWNLOAD_REQUESTED_DATA_FAILURE,
});
export const letDownloadRequestedDataCleanState = () => ({
  type: SensorGraphActionTypes.LET_DOWNLOAD_REQUESTED_DATA_CLEAN_STATE,
});

export const getSensorSetting = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_SETTING,
  payload: data,
});
export const getSensorSettingSuccess = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_SETTING_SUCCESS,
  payload: data,
});
export const getSensorSettingFailure = (data) => ({
  type: SensorGraphActionTypes.GET_SENSOR_SETTING_FAILURE,
  payload: data,
});
export const getSensorSettingCleanState = () => ({
  type: SensorGraphActionTypes.GET_SENSOR_SETTING_CLEAN_STATE,
});