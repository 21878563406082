import { store } from "src/store/store";
import * as Actions from "src/store/actions";
// Load Amplify Auth
import { Auth } from "aws-amplify";

const forceLogout = async () => {
  try {
    await Auth.signOut();
    store.dispatch(Actions.forceLogout());
  } catch {
    console.log("Force logout error!");
  }
};

const dataToExport = { forceLogout };

export default dataToExport;
