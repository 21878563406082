import { getUserToken } from "./StorageUtils";
import AuthHelper from "./AuthHelper";

export const get = (url, params) => {
  return httpRequest("get", url, params);
};

export const put = (url, params) => {
  return httpRequest("put", url, params);
};

export const patch = (url, params) => {
  return httpRequest("PATCH", url, params);
};

export const post = (url, params) => {
  return httpRequest("post", url, params);
};

export const deleteMethod = (url, params) => {
  return httpRequest("delete", url, params);
};

const httpRequest = async (method, url, params) => {
  const token = getUserToken();

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: "Bearer " + token }),
    },
    method,
    ...(params &&
      method.toLowerCase() !== "get" && { body: typeof (params) === typeof ({}) ? JSON.stringify(params) : params }
    ),
  };

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        return new Promise((resolve) =>
          response.text().then((resText) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json: resText === "" || !response.ok ? {} : JSON.parse(resText),
              message: resText && JSON.parse(resText)?.message,
            });
          })
        );
      })
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        } else if (response.status === 401) {
          // Unauthorize
          AuthHelper.forceLogout();
          return reject(response);
        } else {
          return reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
