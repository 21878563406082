import TextConstants from "src/helpers/TextConstants";
import { components } from "react-select";
import { getBatteryPercentage } from "src/helpers/Utils";
import ImageHolders from "src/helpers/ImageHolders";
import moment from "moment";
import { CButton } from "@coreui/react";

export const CheckOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const StatusList = [
  { value: "normal", label: "正常" },
  { value: "abnormal", label: "温度異常" },
  { value: "low_charge", label: "電量低下" },
  { value: "error", label: "通信エラー" },
  { value: "error_all", label: "通信障害" },
];

export const tableFields = [
  { key: "CompanyName", label: "お客様名", _style: { width: "150px" } },
  { key: "StoreName", label: "店舗名", _style: { width: "240px" } },
  { key: "NoOfSensors", label: "センサ設置数", _style: { width: "150px" } },
  { key: "Sensors", label: "状態", _style: { width: "170px" }, sorter: false },
  {
    key: "GroupedBatteries",
    label: "電量(30%以下)",
    _style: { width: "155px" },
  },
  { key: "CommunicationError3DaysInARow", label: "通信エラー(3日続き)" },
  { key: "LastMaintenanceDate", label: TextConstants.LastMaintenanceDate },
  { key: "IntroductionDay", label: TextConstants.IntroductionDay },
  { key: "NextMaintenance", label: TextConstants.NextMaintenance },
  { key: "timestamp", label: "最後更新", sorter: false },
  { key: "NotificationSettings", label: "通知設定" },
];

export const tableFieldsNew = [
  { key: "CompanyName", label: "お客様名", width: 155, sorter: false },
  { key: "StoreName", label: "店舗名", width: 200, sorter: false },
  { key: "NoOfSensors", label: "センサ 設置数", width: 70, sorter: false },
  { key: "Sensors", label: "状態・件数", width: 170, sorter: false },
  {
    key: "GroupedBatteries",
    label: "電量 (30%以下)",
    width: 150,
    sorter: true,
  },
  {
    key: "trafficData",
    label: "通信量",
    width: 80,
    sorter: true,
  },
  {
    key: "CommunicationError3DaysInARow",
    label: "通信エラー (3日続き)",
    width: 130,
    sorter: true,
  },
  // {
  //   key: "MaintenancePeriod",
  //   label: TextConstants.MaintenancePeriod,
  //   width: 160,
  //   sorter: true,
  // },
  {
    key: "IntroductionDay",
    label: TextConstants.IntroductionDay,
    width: 100,
    sorter: true,
  },
  {
    key: "LastMaintenanceDate",
    // label: TextConstants.LastMaintenanceDate,
    label: "前回 メンテナンス",
    width: 120,
    sorter: true,
  },
  {
    key: "NextMaintenance",
    // label: TextConstants.NextMaintenance,
    label: "次回 メンテナンス日",
    width: 120,
    sorter: true,
  },
  { key: "timestamp", label: "DATA更新日時", width: 150, sorter: false },
  { key: "NotificationSettings", label: "通知設定", width: 102, sorter: false },
];

export const renderItem = (
  field,
  item,
  handleAlertBtnClick,
  handleEditSettingModal,
  handleRefreshClick,
  handleStoreNameClick
) => {
  switch (field.key) {
    case "StoreName":
      return (
        <div>
          <span
            className="store-name c-pointer"
            onClick={() => handleStoreNameClick(item)}
          >
            {item.StoreName}
          </span>
          <br />
          <CButton
            color="secondary"
            size="sm"
            className="mr-1"
            onClick={() => handleAlertBtnClick(item.Store?.id || "")}
          >
            アラート
          </CButton>
        </div>
      );
    case "NoOfSensors":
      return <div>{item.Sensors.length}</div>;
    case "Sensors":
      const groupedStatuses = statusRenderer(item.Sensors);
      // console.log(groupedStatuses);
      return (
        <div>
          {Object.keys(groupedStatuses || {}).map((statusKey, idx) => (
            <div
              key={idx}
              className={`w-100 set-bottom-border ${
                idx === 0 ? "single-status" : ""
              }`}
            >
              <label className="sensor-status mb-0">
                {getJapaneseStatusText(statusKey)}
              </label>
              &nbsp;
              <label className="sensor-status-count mb-0">
                {groupedStatuses[statusKey].length + "件"}
              </label>
              <span>
                <img
                  src={getStatusImages(statusKey)}
                  alt="AltValue"
                  width="30"
                />
              </span>
            </div>
          ))}
        </div>
      );
    case "GroupedBatteries":
      const groupedBatteries = item.batteryObj;
      return (
        <div>
          {Object.keys(groupedBatteries || {})
            .reverse()
            .map((baterryKey, idx) => {
              return (
                groupedBatteries[baterryKey] > 0 && (
                  <div key={idx}>
                    <div className="mb-1">
                      <label className="sensor-battery">
                        (~{baterryKey + "%"})
                      </label>
                      &nbsp;
                      <span>{groupedBatteries[baterryKey] + "件"}</span>
                      &nbsp;
                      <img
                        src={getBatteryImages(baterryKey)}
                        alt="AltValue"
                        width="40"
                        height={15}
                      />
                    </div>
                  </div>
                )
              );
            })}
        </div>
      );
    case "trafficData":
      const trafficData = item.trafficData;
      return (
        <span>
          {trafficData ? (
            <>
              <span>{trafficData.usage}MB</span>
              <br />
              <span>({trafficData.percentage}%)</span>
            </>
          ) : (
            "-"
          )}
        </span>
      );
    case "CommunicationError3DaysInARow":
      return (
        <span>
          {item.CommunicationError3DaysInARow > 0 &&
            item.CommunicationError3DaysInARow + " 件"}
        </span>
      );
    case "LastMaintenanceDate":
      return item.LastMaintenanceDate
        ? moment(item.LastMaintenanceDate).format("YYYY/MM/DD")
        : "-";
    case "IntroductionDay":
      return item.IntroductionDay
        ? moment(item.IntroductionDay).format("YYYY/MM/DD")
        : "-";
    case "NextMaintenance":
      return item.NextMaintenance
        ? moment(item.NextMaintenance).format("YYYY/MM/DD")
        : "-";
    case "NotificationSettings":
      return (
        <div className="c-pointer">
          <CButton
            color="secondary"
            size="sm"
            className="mr-1 mb-1"
            onClick={() => handleEditSettingModal(item)}
          >
            {TextConstants.Edit}
          </CButton>
          <CButton
            color="secondary"
            size="sm"
            className="mb-1"
            onClick={() => handleRefreshClick(item)}
          >
            {TextConstants.Update}
          </CButton>
        </div>
      );
    default:
      return <span>{item[field.key] || "-"}</span>;
  }
};

export const modalDateFields = [
  // {
  //   key: "MaintenancePeriod",
  //   label: TextConstants.MaintenancePeriod,
  //   showMonthYearPicker: true,
  // },
  {
    key: "NextMaintenance",
    label: TextConstants.NextMaintenance,
    showMonthYearPicker: false,
  },
  {
    key: "LastMaintenanceDate",
    label: TextConstants.LastMaintenanceDate,
    showMonthYearPicker: false,
  },
  {
    key: "IntroductionDay",
    label: TextConstants.IntroductionDay,
    showMonthYearPicker: false,
  },
];

export const getJapaneseStatusText = (status) => {
  switch (status.toLowerCase()) {
    case "error":
      return "通信エラー";
    case "alert":
      return "警告";
    case "warning":
      return "注意";
    default:
      return "正常";
  }
};

export const getStatusImages = (status) => {
  switch (status.toLowerCase()) {
    case "error":
      return ImageHolders.t_error;
    case "alert":
      return ImageHolders.t_alert;
    case "warning":
      return ImageHolders.t_warning;
    default:
      return ImageHolders.t_ok;
  }
};

export const getBatteryImages = (batery) => {
  switch (parseInt(batery)) {
    case 10:
      return ImageHolders.i10;
    case 20:
      return ImageHolders.i20;
    default:
      return ImageHolders.i30;
  }
};

export const statusRenderer = (sensors) => {
  const groupedStatues = sensors.reduce((groups, row) => {
    const status = row.status;
    if (!groups[status]) {
      groups[status] = [];
    }
    groups[status].push(row);
    return groups;
  }, {});

  const statusObj = {};
  ["error", "alert", "warning", "ok"].map((status) => {
    if (!statusObj[status] && groupedStatues[status]) {
      statusObj[status] = groupedStatues[status];
    }
    return statusObj;
  });

  return statusObj;
};

export const batteryRenderer = (sensors) => {
  return sensors.reduce((groups, row) => {
    const battery = row.battery;
    if (!groups[battery]) {
      groups[battery] = [];
    }
    groups[battery].push(row);
    return groups;
  }, {});
};

export const getTrClassesForStatus = (sensors) => {
  const clsArr = [];

  const checkAllNormal = (sensors.filter((fl) => fl.status === "ok") || [])
    .length;
  if (checkAllNormal === sensors.length && sensors.length > 0) {
    clsArr.push("tr-normal");
  }

  const checkAbNormal = (sensors.filter((fl) => fl.status === "alert") || [])
    .length;
  if (checkAbNormal > 0 && sensors.length > 0) {
    clsArr.push("tr-abnormal");
  }

  const hasBatteryLessThanTen =
    sensors
      .map((fl) => getBatteryPercentage(parseInt(fl.battery)))
      .filter((fl) => fl <= 10).length > 0;
  if (hasBatteryLessThanTen) {
    clsArr.push("tr-normal-battery-10");
    clsArr.push("text-white");
  }

  const errorSTatusLen = (sensors.filter((fl) => fl.status === "error") || [])
    .length;
  if (errorSTatusLen > 0) {
    clsArr.push("tr-error");
    const index = clsArr.indexOf("text-white");
    if (index > -1) {
      clsArr.splice(index, 1);
    }
  }
  if (errorSTatusLen === sensors.length && sensors.length > 0) {
    clsArr.push("tr-error-all");
    clsArr.push("text-white");
  }

  return clsArr.join(" ");
};
