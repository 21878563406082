import * as SensorActions from "../actions";

export const setSensorID = (data) => ({
  type: SensorActions.SET_SENSOR_ID,
  payload: data,
});

export const fetchSensorList = (selectedBmId, url = "") => ({
  type: SensorActions.GET_SENSOR_LIST,
  payload: { selectedBmId, url },
});
export const setSensorListSuccess = (data) => ({
  type: SensorActions.GET_SENSOR_LIST_SUCCESS,
  payload: data,
});
export const setSensorListFailure = () => ({
  type: SensorActions.GET_SENSOR_LIST_FAILURE,
});
export const setSensorListCleanState = () => ({
  type: SensorActions.GET_SENSOR_LIST_CLEAN_STATE,
});

export const letApplyFilterSensor = (data) => ({
  type: SensorActions.LET_APPLY_SENSOR_FILTER,
  payload: data,
});
export const letApplyFilterSensorSuccess = (data) => ({
  type: SensorActions.LET_APPLY_SENSOR_FILTER_SUCCESS,
  payload: data,
});
export const letApplyFilterSensorFailure = () => ({
  type: SensorActions.LET_APPLY_SENSOR_FILTER_FAILURE,
});
export const resetTheFilters = (data) => ({
  type: SensorActions.RESET_THE_FILTERS,
  payload: data,
});
export const enableSearchFilters = () => ({
  type: SensorActions.ENABLE_SEARCH_FILTERS,
});

export const fetchAlaramList = (payload) => ({
  type: SensorActions.GET_ALARAM_LIST,
  payload,
});
export const setAlaramListSuccess = (data) => ({
  type: SensorActions.GET_ALARAM_LIST_SUCCESS,
  payload: data,
});
export const setAlaramListFailure = () => ({
  type: SensorActions.GET_ALARAM_LIST_FAILURE,
});
export const updateAlertComment = (alert, data) => ({
  type: SensorActions.UPDATE_ALERT_COMMENT,
  payload: { alert, data },
});
export const updateAlertCommentSuccess = (data) => ({
  type: SensorActions.UPDATE_ALERT_COMMENT_SUCCESS,
  payload: data,
});
export const updateAlertCommentFailure = () => ({
  type: SensorActions.UPDATE_ALERT_COMMENT_FAILURE,
});
export const clearAlertHistories = (payload) => ({
  type: SensorActions.CLEAR_ALERT_HISTORIES,
  payload,
});
export const clearAlertHistoriesSuccess = (data) => ({
  type: SensorActions.CLEAR_ALERT_HISTORIES_SUCCESS,
  payload: data,
});
export const clearAlertHistoriesFailure = () => ({
  type: SensorActions.CLEAR_ALERT_HISTORIES_FAILURE,
});
export const clearAlertHistoriesClearState = () => ({
  type: SensorActions.CLEAR_ALERT_HISTORIES_CLEAN_STATE,
});

export const setStoreForSensor = (store) => ({
  type: SensorActions.SET_STORE_FOR_SENSOR,
  payload: store,
});

export const resetSelectedValues = () => ({
  type: SensorActions.RESET_SELECTED_VALUES,
});

export const loadBmImage = (bmId) => ({
  type: SensorActions.LOAD_BM_IMAGE,
  payload: bmId,
});

export const loadBmImageSuccess = (link) => ({
  type: SensorActions.LOAD_BM_IMAGE_SUCCESS,
  payload: link,
});

export const loadBmImageFailure = (missingImage) => ({
  type: SensorActions.LOAD_BM_IMAGE_FAILURE,
  payload: missingImage,
});

export const loadBmImageCleanState = () => ({
  type: SensorActions.LOAD_BM_IMAGE_CLEAN_STATE,
});

export const mapLoadBmImage = (bmId) => ({
  type: SensorActions.MAP_LOAD_BM_IMAGE,
  payload: bmId,
});
export const mapLoadBmImageSuccess = (link) => ({
  type: SensorActions.MAP_LOAD_BM_IMAGE_SUCCESS,
  payload: link,
});
export const mapLoadBmImageFailure = (missingImage) => ({
  type: SensorActions.MAP_LOAD_BM_IMAGE_FAILURE,
  payload: missingImage,
});
export const mapLoadBmImageCleanState = () => ({
  type: SensorActions.MAP_LOAD_BM_IMAGE_CLEAN_STATE,
});

export const updateSensorSettings = (data) => ({
  type: SensorActions.UPDATE_SENSOR_SETTINGS,
  payload: data,
});

export const updateSensorSettingsSuccess = (data) => ({
  type: SensorActions.UPDATE_SENSOR_SETTINGS_SUCCESS,
  payload: data,
});

export const updateSensorSettingsFailure = () => ({
  type: SensorActions.UPDATE_SENSOR_SETTINGS_FAILURE,
});

export const updateCurrentSensor = (sensor) => ({
  type: SensorActions.UPDATE_CURRENT_SENSOR,
  payload: sensor,
});

export const requestGenerateReport = (bm, start, end, mode) => ({
  type: SensorActions.REQUEST_GENERATE_REPORT,
  payload: { bm, start, end, mode },
});

export const requestGenerateReportSuccess = (data) => ({
  type: SensorActions.REQUEST_GENERATE_REPORT_SUCCESS,
  payload: data,
});

export const requestGenerateReportFailure = (data) => ({
  type: SensorActions.REQUEST_GENERATE_REPORT_FAILURE,
  payload: data,
});

export const requestGenerateReportCleanState = () => ({
  type: SensorActions.REQUEST_GENERATE_REPORT_CLEAN_STATE,
});

export const downloadBmReport = () => ({
  type: SensorActions.DOWNLOAD_BM_REPORT,
});

export const downloadBmReportSuccess = (data) => ({
  type: SensorActions.DOWNLOAD_BM_REPORT_SUCCESS,
  payload: data,
});

export const downloadBmReportFailure = (data) => ({
  type: SensorActions.DOWNLOAD_BM_REPORT_FAILURE,
  payload: data,
});

export const downloadBmReportCleanState = () => ({
  type: SensorActions.DOWNLOAD_BM_REPORT_CLEAN_STATE,
});

export const letUpdateSensorList = (data) => ({
  type: SensorActions.LET_UPDATE_SENSOR_LIST,
  payload: data,
});
export const letUpdateSensorListSuccess = (data) => ({
  type: SensorActions.LET_UPDATE_SENSOR_LIST_SUCCESS,
  payload: data,
});
export const letUpdateSensorListFailure = () => ({
  type: SensorActions.LET_UPDATE_SENSOR_LIST_FAILURE,
});
export const letUpdateSensorListCleanState = () => ({
  type: SensorActions.LET_UPDATE_SENSOR_LIST_CLEAN_STATE,
});

export const letUpdateSensorPosition = (data) => ({
  type: SensorActions.LET_UPDATE_SENSOR_POSITION,
  payload: data,
});
export const letUpdateSensorPositionSuccess = (data) => ({
  type: SensorActions.LET_UPDATE_SENSOR_POSITION_SUCCESS,
  payload: data,
});
export const letUpdateSensorPositionFailure = () => ({
  type: SensorActions.LET_UPDATE_SENSOR_POSITION_FAILURE,
});
export const letUpdateSensorPositionCleanState = () => ({
  type: SensorActions.LET_UPDATE_SENSOR_POSITION_CLEAN_STATE,
});

// Bm sensor management start
export const addNewSensor = (payload) => ({
  type: SensorActions.ADD_NEW_SENSOR,
  payload,
});
export const addNewSensorSuccess = (payload) => ({
  type: SensorActions.ADD_NEW_SENSOR_SUCCESS,
  payload,
});
export const addNewSensorFailure = () => ({
  type: SensorActions.ADD_NEW_SENSOR_FAILURE,
});
export const addNewSensorCleanState = () => ({
  type: SensorActions.ADD_NEW_SENSOR_CLEAN_STATE,
});

export const deleteTheSensor = (payload) => ({
  type: SensorActions.DELETE_SENSOR,
  payload,
});
export const deleteTheSensorSuccess = (payload) => ({
  type: SensorActions.DELETE_SENSOR_SUCCESS,
  payload,
});
export const deleteTheSensorFailure = () => ({
  type: SensorActions.DELETE_SENSOR_FAILURE,
});
export const deleteTheSensorCleanState = () => ({
  type: SensorActions.DELETE_SENSOR_CLEAN_STATE,
});

export const editTheSensor = (payload) => ({
  type: SensorActions.EDIT_SENSOR,
  payload,
});
export const editTheSensorSuccess = (payload) => ({
  type: SensorActions.EDIT_SENSOR_SUCCESS,
  payload,
});
export const editTheSensorFailure = () => ({
  type: SensorActions.EDIT_SENSOR_FAILURE,
});
export const editTheSensorCleanState = () => ({
  type: SensorActions.EDIT_SENSOR_CLEAN_STATE,
});

export const hideSlotOfSensor = (payload) => ({
  type: SensorActions.HIDE_SLOT_SENSOR,
  payload,
});
export const hideSlotOfSensorSuccess = (payload) => ({
  type: SensorActions.HIDE_SLOT_SENSOR_SUCCESS,
  payload,
});
export const hideSlotOfSensorFailure = () => ({
  type: SensorActions.HIDE_SLOT_SENSOR_FAILURE,
});
export const hideSlotOfSensorCleanState = () => ({
  type: SensorActions.HIDE_SLOT_SENSOR_CLEAN_STATE,
});
// Bm sensor management end
