/*
 * combines all th existing reducers
 */
import { combineReducers } from "redux";
import auth from "./auth";
import theme from "./theme";
import dashboard from "./dashboard";
import company from "./company";
import department from "./department";
import sensor from "./sensor";
import bms from "./bms";
import graph_sensor from "./graph_sensor";
import store from "./store";
import settings from "./settings";
import setupDashboard from "./setup_dashboard";
import { LOGOUT_USER_SUCCESS } from "../actions";

const reducers = combineReducers({
  auth,
  theme,
  dashboard,
  sensor,
  company,
  department,
  bms,
  graph_sensor,
  store,
  settings,
  setupDashboard,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER_SUCCESS) {
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

export default rootReducer;
