import * as Actions from "../actions";

const initialState = {
  companies: [],
  isLoading: false,
  addingCompany: false,
  updatingCompany: false,
  deletingCompany: false,
  type: "",
  isFetchingMonitoringData: false,
  monitoringData: [],
  isUpdateMoniToring: false,
  history: {
    isLoading: false,
    dataList: [],
  },
  guidanceEmail: {
    isLoading: false,
    groups: [],
    templates: [],
  },
  gridMonitoring: {
    isLoading: false,
    gridList: [],
    lastRowGridsIndex: null,
    gridCompany: null,
  },
  monitoringGlobalEmails: null,
  actionLoaderForGlobalEmails: false,
};

const Company = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_COMPANIES:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    case Actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: action.payload,
        type: action.type,
      };

    case Actions.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case Actions.GET_COMPANIES_CLEAN_STATE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case Actions.ADD_COMPANY:
      return {
        ...state,
        addingCompany: true,
        type: action.type,
      };

    case Actions.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addingCompany: false,
        companies: [action.payload, ...state.companies],
        type: action.type,
      };

    case Actions.ADD_COMPANY_FAILURE:
      return {
        ...state,
        addingCompany: false,
        type: action.type,
      };

    case Actions.UPDATE_COMPANY:
      return {
        ...state,
        updatingCompany: true,
        type: action.type,
      };

    case Actions.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updatingCompany: false,
        companies: state.companies.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
        type: action.type,
      };

    case Actions.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        updatingCompany: false,
        type: action.type,
      };

    case Actions.DELETE_COMPANY:
      return {
        ...state,
        deletingCompany: true,
        type: action.type,
      };

    case Actions.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deletingCompany: false,
        companies: state.companies.filter((x) => x.id !== action.payload),
        type: action.type,
      };

    case Actions.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        deletingCompany: false,
        type: action.type,
      };

    case Actions.GET_MONITORING_DATA:
    case Actions.GET_STORE_DATA:
      return {
        ...state,
        isFetchingMonitoringData: true,
        type: action.type,
        // monitoringData: [],
      };
    case Actions.GET_MONITORING_DATA_SUCCESS:
    case Actions.GET_STORE_DATA_SUCCESS:
      return {
        ...state,
        isFetchingMonitoringData: false,
        monitoringData: action.payload,
        type: action.type,
      };
    case Actions.GET_MONITORING_DATA_FAILURE:
    case Actions.GET_MONITORING_DATA_CLEAN_STATE:
    case Actions.GET_STORE_DATA_FAILURE:
    case Actions.GET_STORE_DATA_CLEAN_STATE:
      return {
        ...state,
        isFetchingMonitoringData: false,
        type: action.type,
      };

    case Actions.UPDATE_MONITORING_DATA:
      return {
        ...state,
        isUpdateMoniToring: true,
        type: action.type,
      };
    case Actions.UPDATE_MONITORING_DATA_SUCCESS:
      return {
        ...state,
        isUpdateMoniToring: false,
        monitoringData: action.payload,
        type: action.type,
      };
    case Actions.UPDATE_MONITORING_DATA_FAILURE:
    case Actions.UPDATE_MONITORING_DATA_CLEAN_STATE:
      return {
        ...state,
        isUpdateMoniToring: false,
        type: action.type,
      };

    case Actions.GET_HISTORY_LIST:
      return {
        ...state,
        type: action.type,
        history: {
          ...state.history,
          isLoading: true,
        },
      };
    case Actions.GET_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        type: action.type,
        history: {
          ...state.history,
          isLoading: false,
          dataList: action.payload,
        },
      };
    case Actions.GET_HISTORY_LIST_FAILURE:
    case Actions.GET_HISTORY_LIST_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        history: {
          ...state.history,
          isLoading: false,
        },
      };

    case Actions.GET_GROUPS_AND_TEMPLATES:
      return {
        ...state,
        type: action.type,
        guidanceEmail: {
          ...state.guidanceEmail,
          isLoading: true,
        },
      };
    case Actions.GET_GROUPS_AND_TEMPLATES_SUCCESS:
      const { groups, templates } = action.payload;
      return {
        ...state,
        type: action.type,
        guidanceEmail: {
          ...state.guidanceEmail,
          isLoading: false,
          groups,
          templates,
        },
      };
    case Actions.GET_GROUPS_AND_TEMPLATES_FAILURE:
    case Actions.GET_GROUPS_AND_TEMPLATES_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        guidanceEmail: {
          ...state.guidanceEmail,
          isLoading: false,
        },
      };

    case Actions.SEND_GUIDANCE_EMAIL:
      return {
        ...state,
        type: action.type,
        guidanceEmail: {
          ...state.guidanceEmail,
          isLoading: true,
        },
      };
    case Actions.SEND_GUIDANCE_EMAIL_SUCCESS:
    case Actions.SEND_GUIDANCE_EMAIL_FAILURE:
    case Actions.SEND_GUIDANCE_EMAIL_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        guidanceEmail: {
          ...state.guidanceEmail,
          isLoading: false,
        },
      };

    case Actions.GET_GRID_MONITORING:
      return {
        ...state,
        type: action.type,
        gridMonitoring: {
          ...state.gridMonitoring,
          isLoading: true,
          lastRowGridsIndex: null,
          gridCompany: null,
        },
      };
    case Actions.GET_GRID_MONITORING_SUCCESS:
      return {
        ...state,
        type: action.type,
        gridMonitoring: {
          ...state.gridMonitoring,
          isLoading: false,
          gridList: action.payload.gridList,
          lastRowGridsIndex: action.payload.lastRowGridsIndex,
        },
      };
    case Actions.GET_GRID_MONITORING_FAILURE:
    case Actions.GET_GRID_MONITORING_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        gridMonitoring: {
          ...state.gridMonitoring,
          isLoading: false,
        },
      };

    case Actions.SET_GRID_COMPANY:
      return {
        ...state,
        type: action.type,
      };
    case Actions.SET_GRID_COMPANY_SUCCESS:
      return {
        ...state,
        type: action.type,
        gridMonitoring: {
          ...state.gridMonitoring,
          gridCompany: action.payload,
        },
      };
    case Actions.SET_GRID_COMPANY_FAILURE:
    case Actions.SET_GRID_COMPANY_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
      };

    case Actions.UPDATE_GLOBAL_EMAILS:
    case Actions.GET_GLOBAL_EMAILS:
      return {
        ...state,
        type: action.type,
        actionLoaderForGlobalEmails: true,
      };
    case Actions.UPDATE_GLOBAL_EMAILS_SUCCESS:
    case Actions.GET_GLOBAL_EMAILS_SUCCESS:
      return {
        ...state,
        type: action.type,
        actionLoaderForGlobalEmails: false,
        monitoringGlobalEmails: { ...action.payload },
      };
    case Actions.UPDATE_GLOBAL_EMAILS_FAILURE:
    case Actions.UPDATE_GLOBAL_EMAILS_CLEAN_STATE:
    case Actions.GET_GLOBAL_EMAILS_FAILURE:
    case Actions.GET_GLOBAL_EMAILS_CLEAN_STATE:
      return {
        ...state,
        type: action.type,
        actionLoaderForGlobalEmails: false,
      };

    case Actions.RESET_GRID_COMPANY:
      return {
        ...state,
        type: action.type,
        gridMonitoring: {
          ...state.gridMonitoring,
          gridCompany: null,
        },
      };

    default:
      return state;
  }
};

export default Company;
