import TextConstants from "src/helpers/TextConstants";

const DEFROST_MODES = {
  NONE: {
    text: TextConstants.None,
    value: 1,
  },
  TEMP: {
    text: TextConstants.TempVal,
    value: 2,
  },
  TIME: {
    text: TextConstants.Time,
    value: 3,
  },
};

const SELECTED_BM = {
  ALL: "_all",
  NULL: "_null",
};

const SENSOR_VIEWS = {
  LIST: TextConstants.List,
  MAP: TextConstants.Map,
};

const SENSOR_STATUS = {
  OK: 0,
  WARNING: 1,
  ALERT: 2,
  ERROR: 3,
};

const exportHolders = {
  DEFROST_MODES,
  SELECTED_BM,
  SENSOR_VIEWS,
  SENSOR_STATUS,
};

export default exportHolders;
