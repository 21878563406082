import * as Actions from "../actions";

const initialState = {
  departments: [],
  users: [],
  settings: {}, // Set it as dict instead if array
  isLoading: false,
  addingManager: false,
  updatingManager: false,
  deletingManager: false,
  savingSettings: false,
  type: "",
};

const Department = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DEPARTMENTS:
    case Actions.GET_COMPANY_DEPARTMENTS:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };
    case Actions.GET_DEPARTMENTS_SUCCESS:
    case Actions.GET_COMPANY_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        departments: action.payload,
        type: action.type,
      };
    case Actions.GET_DEPARTMENTS_FAILURE:
    case Actions.GET_COMPANY_DEPARTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };
    case Actions.GET_DEPARTMENT_MANAGERS:
      return {
        ...state,
        isLoading: true,
        type: action.type,
      };

    case Actions.GET_DEPARTMENT_MANAGERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
        type: action.type,
      };

    case Actions.GET_DEPARTMENT_MANAGERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case Actions.GET_DEPARTMENT_MANAGERS_CLEAN_STATE:
      return {
        ...state,
        isLoading: false,
        type: action.type,
      };

    case Actions.ADD_DEPARTMENT_MANAGER:
      return {
        ...state,
        addingManager: true,
        type: action.type,
      };

    case Actions.ADD_DEPARTMENT_MANAGER_SUCCESS:
      return {
        ...state,
        addingManager: false,
        users: [action.payload, ...state.users],
        type: action.type,
      };

    case Actions.ADD_DEPARTMENT_MANAGER_FAILURE:
      return {
        ...state,
        addingManager: false,
        type: action.type,
      };

    case Actions.UPDATE_DEPARTMENT_MANAGER:
      return {
        ...state,
        updatingManager: true,
        type: action.type,
      };

    case Actions.UPDATE_DEPARTMENT_MANAGER_SUCCESS:
      return {
        ...state,
        updatingManager: false,
        users: state.users.map(x => x.id === action.payload.id ? action.payload : x),
        type: action.type,
      };

    case Actions.UPDATE_DEPARTMENT_MANAGER_FAILURE:
      return {
        ...state,
        updatingManager: false,
        type: action.type,
      };

    case Actions.DELETE_DEPARTMENT_MANAGER:
      return {
        ...state,
        deletingManager: true,
        type: action.type,
      };

    case Actions.DELETE_DEPARTMENT_MANAGER_SUCCESS:
      return {
        ...state,
        deletingManager: false,
        users: state.users.filter(x => x.id !== action.payload),
        type: action.type,
      };

    case Actions.DELETE_DEPARTMENT_MANAGER_FAILURE:
      return {
        ...state,
        deletingManager: false,
        type: action.type,
      };

    case Actions.GET_DEPARTMENT_SETTINGS:
    case Actions.GET_DEPARTMENT_SETTINGS_FAILURE:
      return {
        ...state,
        type: action.type,
      };
    case Actions.GET_DEPARTMENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        type: action.type,
      };

    case Actions.SET_DEPARTMENT_SETTINGS:
      return {
        ...state,
        savingSettings: true,
        type: action.type,
      };
    case Actions.SET_DEPARTMENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        savingSettings: false,
        type: action.type,
      };
    case Actions.SET_DEPARTMENT_SETTINGS_FAILURE:
      return {
        ...state,
        savingSettings: false,
        type: action.type,
      };

    default:
      return state;
  }
};

export default Department;
