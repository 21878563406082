import * as Actions from "../actions";

export const fetchCompanies = () => ({
  type: Actions.GET_COMPANIES,
});

export const addCompany = (payload) => ({
  type: Actions.ADD_COMPANY,
  payload,
});
export const addCompanySuccess = (payload) => ({
  type: Actions.ADD_COMPANY_SUCCESS,
  payload,
});
export const addCompanyFailure = (payload) => ({
  type: Actions.ADD_COMPANY_FAILURE,
  payload,
});

export const updateCompany = (id, data) => ({
  type: Actions.UPDATE_COMPANY,
  payload: { id, data },
});
export const updateCompanySuccess = (payload) => ({
  type: Actions.UPDATE_COMPANY_SUCCESS,
  payload,
});
export const updateCompanyFailure = (payload) => ({
  type: Actions.UPDATE_COMPANY_FAILURE,
  payload,
});

export const deleteCompany = (payload) => ({
  type: Actions.DELETE_COMPANY,
  payload,
});
export const deleteCompanySuccess = (payload) => ({
  type: Actions.DELETE_COMPANY_SUCCESS,
  payload,
});
export const deleteCompanyFailure = (payload) => ({
  type: Actions.DELETE_COMPANY_FAILURE,
  payload,
});

export const getMonitoringData = (payload) => ({
  type: Actions.GET_MONITORING_DATA,
  payload,
});
export const getMonitoringDataSuccess = (payload) => ({
  type: Actions.GET_MONITORING_DATA_SUCCESS,
  payload,
});
export const getMonitoringDataFailure = (payload) => ({
  type: Actions.GET_MONITORING_DATA_FAILURE,
  payload,
});
export const getMonitoringDataCleanState = () => ({
  type: Actions.GET_MONITORING_DATA_CLEAN_STATE,
});

export const updateMonitoringData = (payload) => ({
  type: Actions.UPDATE_MONITORING_DATA,
  payload,
});
export const updateMonitoringDataSuccess = (payload) => ({
  type: Actions.UPDATE_MONITORING_DATA_SUCCESS,
  payload,
});
export const updateMonitoringDataFailure = (payload) => ({
  type: Actions.UPDATE_MONITORING_DATA_FAILURE,
  payload,
});
export const updateMonitoringDataCleanState = () => ({
  type: Actions.UPDATE_MONITORING_DATA_CLEAN_STATE,
});

export const getGlobalEmails = (payload) => ({
  type: Actions.GET_GLOBAL_EMAILS,
  payload,
});
export const getGlobalEmailsSuccess = (payload) => ({
  type: Actions.GET_GLOBAL_EMAILS_SUCCESS,
  payload,
});
export const getGlobalEmailsFailure = (payload) => ({
  type: Actions.GET_GLOBAL_EMAILS_FAILURE,
  payload,
});
export const getGlobalEmailsCleanState = () => ({
  type: Actions.GET_GLOBAL_EMAILS_CLEAN_STATE,
});
export const updateGlobalEmails = (payload) => ({
  type: Actions.UPDATE_GLOBAL_EMAILS,
  payload,
});
export const updateGlobalEmailsSuccess = (payload) => ({
  type: Actions.UPDATE_GLOBAL_EMAILS_SUCCESS,
  payload,
});
export const updateGlobalEmailsFailure = (payload) => ({
  type: Actions.UPDATE_GLOBAL_EMAILS_FAILURE,
  payload,
});
export const updateGlobalEmailsCleanState = () => ({
  type: Actions.UPDATE_GLOBAL_EMAILS_CLEAN_STATE,
});

export const getMonitoringStoreData = (payload) => ({
  type: Actions.GET_STORE_DATA,
  payload,
});
export const getMonitoringStoreDataSuccess = (payload) => ({
  type: Actions.GET_STORE_DATA_SUCCESS,
  payload,
});
export const getMonitoringStoreDataFailure = (payload) => ({
  type: Actions.GET_STORE_DATA_FAILURE,
  payload,
});
export const getMonitoringStoreDataCleanState = () => ({
  type: Actions.GET_STORE_DATA_CLEAN_STATE,
});

export const getHistoryList = (payload) => ({
  type: Actions.GET_HISTORY_LIST,
  payload,
});
export const getHistoryListSuccess = (payload) => ({
  type: Actions.GET_HISTORY_LIST_SUCCESS,
  payload,
});
export const getHistoryListFailure = (payload) => ({
  type: Actions.GET_HISTORY_LIST_FAILURE,
  payload,
});
export const getHistoryListCleanState = () => ({
  type: Actions.GET_HISTORY_LIST_CLEAN_STATE,
});

export const getGroupsAndTemplates = (payload) => ({
  type: Actions.GET_GROUPS_AND_TEMPLATES,
  payload,
});
export const getGroupsAndTemplatesSuccess = (payload) => ({
  type: Actions.GET_GROUPS_AND_TEMPLATES_SUCCESS,
  payload,
});
export const getGroupsAndTemplatesFailure = (payload) => ({
  type: Actions.GET_GROUPS_AND_TEMPLATES_FAILURE,
  payload,
});
export const getGroupsAndTemplatesCleanState = () => ({
  type: Actions.GET_GROUPS_AND_TEMPLATES_CLEAN_STATE,
});

export const sendGuidanceEmail = (payload) => ({
  type: Actions.SEND_GUIDANCE_EMAIL,
  payload,
});
export const sendGuidanceEmailSuccess = (payload) => ({
  type: Actions.SEND_GUIDANCE_EMAIL_SUCCESS,
  payload,
});
export const sendGuidanceEmailFailure = (payload) => ({
  type: Actions.SEND_GUIDANCE_EMAIL_FAILURE,
  payload,
});
export const sendGuidanceEmailCleanState = () => ({
  type: Actions.SEND_GUIDANCE_EMAIL_CLEAN_STATE,
});
// Grid Monitoring Data Start
export const getGridMonitoringData = (payload) => ({
  type: Actions.GET_GRID_MONITORING,
  payload,
});
export const getGridMonitoringDataSuccess = (payload) => ({
  type: Actions.GET_GRID_MONITORING_SUCCESS,
  payload,
});
export const getGridMonitoringDataFailure = (payload) => ({
  type: Actions.GET_GRID_MONITORING_FAILURE,
  payload,
});
export const getGridMonitoringDataCleanState = () => ({
  type: Actions.GET_GRID_MONITORING_CLEAN_STATE,
});

export const setGridCompany = (payload) => ({
  type: Actions.SET_GRID_COMPANY,
  payload,
});
export const setGridCompanySuccess = (payload) => ({
  type: Actions.SET_GRID_COMPANY_SUCCESS,
  payload,
});
export const setGridCompanyFailure = () => ({
  type: Actions.SET_GRID_COMPANY_FAILURE,
});
export const setGridCompanyCleanState = () => ({
  type: Actions.SET_GRID_COMPANY_CLEAN_STATE,
});
export const resetGridCompany = () => ({
  type: Actions.RESET_GRID_COMPANY,
});
// Grid Monitoring Data End
