import * as Actions from "../actions";

const initialState = {
  // --- Setup Dashboard & Onsite Dashboard
  isUploadingStoreHearing: false,
  isDownloadingStoreHearing: false,
  isUploadingStoreSettingFiles: false,
  isDownloadingStoreSettingFiles: false,
  isDownloadingStoreHoldingFiles: false,
  isUploadingDeviceSettings: false,
  isDownloadingDeviceSettings: false,
  isRegisteringDevice: false,
  isLoadingSensors: false,
  sensors: [],
  // --- Setup Admin Management
  isLoadingUsers: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  users: [],
};

const SetupDashboard = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Setup Dashboard & Onsite Dashboard
    case Actions.UPLOAD_STORE_HEARING:
      return {
        ...state,
        isUploadingStoreHearing: true,
        type,
      };
    case Actions.UPLOAD_STORE_HEARING_SUCCESS:
    case Actions.UPLOAD_STORE_HEARING_FAILURE:
      return {
        ...state,
        isUploadingStoreHearing: false,
        type,
      };

    case Actions.DOWNLOAD_STORE_HEARING:
      return {
        ...state,
        isDownloadingStoreHearing: true,
        type,
      };
    case Actions.DOWNLOAD_STORE_HEARING_SUCCESS:
    case Actions.DOWNLOAD_STORE_HEARING_FAILURE:
      return {
        ...state,
        isDownloadingStoreHearing: false,
        type,
      };

    case Actions.UPLOAD_STORE_SETTING_FILES:
      return {
        ...state,
        isUploadingStoreSettingFiles: true,
        type,
      };
    case Actions.UPLOAD_STORE_SETTING_FILES_SUCCESS:
    case Actions.UPLOAD_STORE_SETTING_FILES_FAILURE:
      return {
        ...state,
        isUploadingStoreSettingFiles: false,
        type,
      };

    case Actions.DOWNLOAD_STORE_SETTING_FILES:
      return {
        ...state,
        isDownloadingStoreSettingFiles: true,
        type,
      };
    case Actions.DOWNLOAD_STORE_SETTING_FILES_SUCCESS:
    case Actions.DOWNLOAD_STORE_SETTING_FILES_FAILURE:
      return {
        ...state,
        isDownloadingStoreSettingFiles: false,
        type,
      };

    case Actions.DOWNLOAD_STORE_HOLDING_FILES:
      return {
        ...state,
        isDownloadingStoreHoldingFiles: true,
        type,
      };
    case Actions.DOWNLOAD_STORE_HOLDING_FILES_SUCCESS:
    case Actions.DOWNLOAD_STORE_HOLDING_FILES_FAILURE:
      return {
        ...state,
        isDownloadingStoreHoldingFiles: false,
        type,
      };

    case Actions.UPLOAD_DEVICE_SETTINGS:
      return {
        ...state,
        isUploadingDeviceSettings: true,
        type,
      };
    case Actions.UPLOAD_DEVICE_SETTINGS_SUCCESS:
    case Actions.UPLOAD_DEVICE_SETTINGS_FAILURE:
      return {
        ...state,
        isUploadingDeviceSettings: false,
        type,
      };

    case Actions.DOWNLOAD_DEVICE_SETTINGS:
      return {
        ...state,
        isDownloadingDeviceSettings: true,
        type,
      };
    case Actions.DOWNLOAD_DEVICE_SETTINGS_SUCCESS:
    case Actions.DOWNLOAD_DEVICE_SETTINGS_FAILURE:
      return {
        ...state,
        isDownloadingDeviceSettings: false,
        type,
      };

    case Actions.REGISTER_STORE_DEVICE:
      return {
        ...state,
        isRegisteringDevice: true,
        type,
      };
    case Actions.REGISTER_STORE_DEVICE_SUCCESS:
    case Actions.REGISTER_STORE_DEVICE_FAILURE:
      return {
        ...state,
        isRegisteringDevice: false,
        type,
      };

    case Actions.DISPLAY_DEVICE_SENSORS:
      return {
        ...state,
        isLoadingSensors: true,
        type,
      };
    case Actions.DISPLAY_DEVICE_SENSORS_SUCCESS:
      return {
        ...state,
        isLoadingSensors: false,
        sensors: payload,
        type,
      };
    case Actions.DISPLAY_DEVICE_SENSORS_FAILURE:
      return {
        ...state,
        isLoadingSensors: false,
        type,
      };

    case Actions.LET_APPLY_SENSOR_FILTER_SUCCESS:
      return {
        ...state,
        sensors: payload,
        type,
      };

    // Setup Admin Management
    case Actions.FETCH_SETUP_ADMINS:
      return {
        ...state,
        isLoadingUsers: true,
        type,
      };
    case Actions.FETCH_SETUP_ADMINS_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: payload,
        type,
      };
    case Actions.FETCH_SETUP_ADMINS_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        type,
      };

    case Actions.CREATE_SETUP_ADMIN:
      return {
        ...state,
        isCreating: true,
        type,
      };
    case Actions.CREATE_SETUP_ADMIN_SUCCESS:
      return {
        ...state,
        isCreating: false,
        users: [payload, ...state.users],
        type,
      };
    case Actions.CREATE_SETUP_ADMIN_FAILURE:
      return {
        ...state,
        isCreating: false,
        type,
      };

    case Actions.UPDATE_SETUP_ADMIN:
      return {
        ...state,
        isUpdating: true,
        type,
      };
    case Actions.UPDATE_SETUP_ADMIN_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        users: state.users.map((x) => (x.username === payload.username ? payload.data : x)),
        type,
      };
    case Actions.UPDATE_SETUP_ADMIN_FAILURE:
      return {
        ...state,
        isUpdating: false,
        type,
      };

    case Actions.DELETE_SETUP_ADMIN:
      return {
        ...state,
        isDeleting: true,
        type,
      };
    case Actions.DELETE_SETUP_ADMIN_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        users: state.users.filter((x) => x.username !== payload),
        type,
      };
    case Actions.DELETE_SETUP_ADMIN_FAILURE:
      return {
        ...state,
        isDeleting: false,
        type,
      };

    default:
      return state;
  }
};

export default SetupDashboard;
