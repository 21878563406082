import { 
    GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILURE, GET_DASHBOARD_CLEAN_STATE
  } from "../actions";
  
  const initialState = {
    data: "Demo",
    isLoading: false,
    userData: [],
  };
  
const dashboard =  (state = initialState, action) => {
    switch (action.type) {
  
      case GET_DASHBOARD:
        return {
          ...state,
          isLoading: true,
        }
  
      case GET_DASHBOARD_SUCCESS:
        return {
          ...state,
          userData: action.data.results[0],
        };
      
      case GET_DASHBOARD_FAILURE:
        return {
          ...state,
          isLoading: false
        };
      
      case GET_DASHBOARD_CLEAN_STATE:
        return {
          ...state,
          isLoading: false
        }
  
      default:
        return state;
    }
  };
  
  export default dashboard