import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  TOGGLE_RELEASE_NOTES_MODAL,
  FORCE_LOGOUT,
} from "../actions";

const userData = JSON.parse(localStorage.getItem("HaccpAppUserData"));

const INIT_STATE = {
  user: userData !== null ? userData : null,
  loading: false,
  type: "",
  isLoggedIn: userData !== null ? true : false,
  userType: userData !== null ? userData.userType : "",
  loginType: userData !== null ? userData.loginType : "",
  shouldOpenReleaseNoteModal: false,
};

const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        userType: action.payload.userType,
        loginType: action.payload.loginType,
        type: action.type,
        isLoggedIn: true,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        type: action.type,
        isLoggedIn: false,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        type: action.type,
        isLoggedIn: false,
      };
    case TOGGLE_RELEASE_NOTES_MODAL:
      return {
        ...state,
        type: action.type,
        shouldOpenReleaseNoteModal: action.payload,
      };
    case FORCE_LOGOUT:
      localStorage.removeItem('userToken');
      localStorage.removeItem('HaccpAppUserData');
      return {
        user: null,
        loading: false,
        type: "",
        isLoggedIn: false,
        userType: "",
        shouldOpenReleaseNoteModal: false,
      }
    default:
      return { ...state };
  }
};

export default auth;
