/* APP */
export const APP_CHECK_VERSION = "APP_CHECK_VERSION";

/* THEME */
export const THEME_CHANGE_TYPE = "THEME_CHANGE_TYPE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const FORCE_LOGOUT = "FORCE_LOGOUT";

// Release Notes Modal
export const TOGGLE_RELEASE_NOTES_MODAL = "TOGGLE_RELEASE_NOTES_MODAL";

/* Dashboard */
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export const GET_DASHBOARD_CLEAN_STATE = "GET_DASHBOARD_CLEAN_STATE";

/* Sensors */
export const SET_STORE_FOR_SENSOR = "SET_STORE_FOR_SENSOR";
export const SET_SENSOR_ID = "SET_SENSOR_ID";
export const GET_SENSOR_LIST = "GET_SENSOR_LIST";
export const GET_SENSOR_LIST_SUCCESS = "GET_SENSOR_LIST_SUCCESS";
export const GET_SENSOR_LIST_FAILURE = "GET_SENSOR_LIST_FAILURE";
export const GET_SENSOR_LIST_CLEAN_STATE = "GET_SENSOR_LIST_CLEAN_STATE";
export const LET_APPLY_SENSOR_FILTER = "LET_APPLY_SENSOR_FILTER";
export const LET_APPLY_SENSOR_FILTER_SUCCESS =
  "LET_APPLY_SENSOR_FILTER_SUCCESS";
export const LET_APPLY_SENSOR_FILTER_FAILURE =
  "LET_APPLY_SENSOR_FILTER_FAILURE";
export const RESET_THE_FILTERS = "RESET_THE_FILTERS";
export const ENABLE_SEARCH_FILTERS = "ENABLE_SEARCH_FILTERS";
export const GET_ALARAM_LIST = "GET_ALARAM_LIST";
export const GET_ALARAM_LIST_SUCCESS = "GET_ALARAM_LIST_SUCCESS";
export const GET_ALARAM_LIST_FAILURE = "GET_ALARAM_LIST_FAILURE";
export const UPDATE_ALERT_COMMENT = "UPDATE_ALERT_COMMENT";
export const UPDATE_ALERT_COMMENT_SUCCESS = "UPDATE_ALERT_COMMENT_SUCCESS";
export const UPDATE_ALERT_COMMENT_FAILURE = "UPDATE_ALERT_COMMENT_FAILURE";
export const CLEAR_ALERT_HISTORIES = "CLEAR_ALERT_HISTORIES";
export const CLEAR_ALERT_HISTORIES_SUCCESS = "CLEAR_ALERT_HISTORIES_SUCCESS";
export const CLEAR_ALERT_HISTORIES_FAILURE = "CLEAR_ALERT_HISTORIES_FAILURE";
export const CLEAR_ALERT_HISTORIES_CLEAN_STATE =
  "CLEAR_ALERT_HISTORIES_CLEAN_STATE";
export const LET_UPDATE_SENSOR_LIST = "LET_UPDATE_SENSOR_LIST";
export const LET_UPDATE_SENSOR_LIST_SUCCESS = "LET_UPDATE_SENSOR_LIST_SUCCESS";
export const LET_UPDATE_SENSOR_LIST_FAILURE = "LET_UPDATE_SENSOR_LIST_FAILURE";
export const LET_UPDATE_SENSOR_LIST_CLEAN_STATE =
  "LET_UPDATE_SENSOR_LIST_CLEAN_STATE";
export const LET_UPDATE_SENSOR_POSITION = "LET_UPDATE_SENSOR_POSITION";
export const LET_UPDATE_SENSOR_POSITION_SUCCESS =
  "LET_UPDATE_SENSOR_POSITION_SUCCESS";
export const LET_UPDATE_SENSOR_POSITION_FAILURE =
  "LET_UPDATE_SENSOR_POSITION_FAILURE";
export const LET_UPDATE_SENSOR_POSITION_CLEAN_STATE =
  "LET_UPDATE_SENSOR_POSITION_CLEAN_STATE";
export const ADD_NEW_SENSOR = "ADD_NEW_SENSOR";
export const ADD_NEW_SENSOR_SUCCESS = "ADD_NEW_SENSOR_SUCCESS";
export const ADD_NEW_SENSOR_FAILURE = "ADD_NEW_SENSOR_FAILURE";
export const ADD_NEW_SENSOR_CLEAN_STATE = "ADD_NEW_SENSOR_CLEAN_STATE";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const DELETE_SENSOR_SUCCESS = "DELETE_SENSOR_SUCCESS";
export const DELETE_SENSOR_FAILURE = "DELETE_SENSOR_FAILURE";
export const DELETE_SENSOR_CLEAN_STATE = "DELETE_SENSOR_CLEAN_STATE";
export const EDIT_SENSOR = "EDIT_SENSOR";
export const EDIT_SENSOR_SUCCESS = "EDIT_SENSOR_SUCCESS";
export const EDIT_SENSOR_FAILURE = "EDIT_SENSOR_FAILURE";
export const EDIT_SENSOR_CLEAN_STATE = "EDIT_SENSOR_CLEAN_STATE";
export const HIDE_SLOT_SENSOR = "HIDE_SLOT_SENSOR";
export const HIDE_SLOT_SENSOR_SUCCESS = "HIDE_SLOT_SENSOR_SUCCESS";
export const HIDE_SLOT_SENSOR_FAILURE = "HIDE_SLOT_SENSOR_FAILURE";
export const HIDE_SLOT_SENSOR_CLEAN_STATE = "HIDE_SLOT_SENSOR_CLEAN_STATE";

/* Sensors Graph */
export const GET_SENSOR_GRAPH_DATA = "GET_SENSOR_GRAPH_DATA";
export const GET_SENSOR_GRAPH_DATA_SUCCESS = "GET_SENSOR_GRAPH_DATA_SUCCESS";
export const GET_SENSOR_GRAPH_DATA_FAILURE = "GET_SENSOR_GRAPH_DATA_FAILURE";
export const GET_SENSOR_GRAPH_DATA_CLEAN_STATE =
  "GET_SENSOR_GRAPH_DATA_CLEAN_STATE";
export const GET_SENSOR_GRAPH_DATA_CLEAR = "GET_SENSOR_GRAPH_DATA_CLEAR";
export const GET_SENSOR_GRAPH_DATA_DAILY = "GET_SENSOR_GRAPH_DATA_DAILY";
export const GET_SENSOR_GRAPH_DATA_DAILY_SUCCESS =
  "GET_SENSOR_GRAPH_DATA_DAILY_SUCCESS";
export const GET_SENSOR_GRAPH_DATA_DAILY_FAILURE =
  "GET_SENSOR_GRAPH_DATA_DAILY_FAILURE";
export const GET_SENSOR_GRAPH_DATA_DAILY_CLEAN_STATE =
  "GET_SENSOR_GRAPH_DATA_DAILY_CLEAN_STATE";
export const GET_SENSOR_GRAPH_DATA_DAILY_CLEAR =
  "GET_SENSOR_GRAPH_DATA_DAILY_CLEAR";
export const LET_DOWNLOAD_REQUESTED_DATA = "LET_DOWNLOAD_REQUESTED_DATA";
export const LET_DOWNLOAD_REQUESTED_DATA_SUCCESS =
  "LET_DOWNLOAD_REQUESTED_DATA_SUCCESS";
export const LET_DOWNLOAD_REQUESTED_DATA_FAILURE =
  "LET_DOWNLOAD_REQUESTED_DATA_FAILURE";
export const LET_DOWNLOAD_REQUESTED_DATA_CLEAN_STATE =
  "LET_DOWNLOAD_REQUESTED_DATA_CLEAN_STATE";

export const GET_SENSOR_SETTING = "GET_SENSOR_SETTING";
export const GET_SENSOR_SETTING_SUCCESS = "GET_SENSOR_SETTING_SUCCESS";
export const GET_SENSOR_SETTING_FAILURE = "GET_SENSOR_SETTING_FAILURE";
export const GET_SENSOR_SETTING_CLEAN_STATE = "GET_SENSOR_SETTING_CLEAN_STATE";

/* Sensors Settings */
export const UPDATE_SENSOR_SETTINGS = "UPDATE_SENSOR_SETTINGS";
export const UPDATE_SENSOR_SETTINGS_SUCCESS = "UPDATE_SENSOR_SETTINGS_SUCCESS";
export const UPDATE_SENSOR_SETTINGS_FAILURE = "UPDATE_SENSOR_SETTINGS_FAILURE";
export const UPDATE_CURRENT_SENSOR = "UPDATE_CURRENT_SENSOR";

// product settings
export const GET_PRODUCT_SETTINGS = "GET_PRODUCT_SETTINGS";
export const GET_PRODUCT_SETTINGS_SUCCESS = "GET_PRODUCT_SETTINGS_SUCCESS";
export const GET_PRODUCT_SETTINGS_FAILURE = "GET_PRODUCT_SETTINGS_FAILURE";

// Store
export const UPDATE_STORE_SETTINGS = "UPDATE_STORE_SETTINGS";
export const UPDATE_STORE_SETTINGS_SUCCESS = "UPDATE_STORE_SETTINGS_SUCCESS";
export const UPDATE_STORE_SETTINGS_FAILURE = "UPDATE_STORE_SETTINGS_FAILURE";
export const REQUEST_PAUSE_ALERT = "REQUEST_PAUSE_ALERT";
export const PAUSE_ALERT_SUCCESS = "PAUSE_ALERT_SUCCESS";
export const PAUSE_ALERT_FAILURE = "PAUSE_ALERT_FAILURE";
export const GET_STORE_SETTINGS = "GET_STORE_SETTINGS";
export const GET_STORE_SETTINGS_SUCCESS = "GET_STORE_SETTINGS_SUCCESS";
export const GET_STORE_SETTINGS_FAILURE = "GET_STORE_SETTINGS_FAILURE";

/* BMs */
export const GET_BMS_LIST = "GET_BMS_LIST";
export const GET_BMS_LIST_SUCCESS = "GET_BMS_LIST_SUCCESS";
export const GET_BMS_LIST_FAILURE = "GET_BMS_LIST_FAILURE";
export const GET_BMS_LIST_CLEAN_STATE = "GET_BMS_LIST_CLEAN_STATE";
export const RESET_BMS_LIST_LAZY_LOAD = "RESET_BMS_LIST_LAZY_LOAD";

export const GET_REMAINING_BMS_LIST = "GET_REMAINING_BMS_LIST";
export const GET_REMAINING_BMS_LIST_SUCCESS = "GET_REMAINING_BMS_LIST_SUCCESS";
export const GET_REMAINING_BMS_LIST_FAILURE = "GET_REMAINING_BMS_LIST_FAILURE";
export const GET_REMAINING_BMS_LIST_CLEAN_STATE = "GET_REMAINING_BMS_LIST_CLEAN_STATE";
export const MODIFY_SL_LIST = "MODIFY_SL_LIST"; // (EDIT)
export const GET_SL_LIST = "GET_SL_LIST"; // (EDIT)

export const GET_STORE_BMS = "GET_STORE_BMS";
export const GET_STORE_BMS_SUCCESS = "GET_STORE_BMS_SUCCESS";
export const GET_STORE_BMS_FAILURE = "GET_STORE_BMS_FAILURE";
export const GET_STORE_BMS_CLEAN_STATE = "GET_STORE_BMS_CLEAN_STATE";

export const GET_BMS_LIST_OF_ALL = "GET_BMS_LIST_OF_ALL";
export const GET_BMS_LIST_OF_ALL_SUCCESS = "GET_BMS_LIST_OF_ALL_SUCCESS";
export const GET_BMS_LIST_OF_ALL_FAILURE = "GET_BMS_LIST_OF_ALL_FAILURE";
export const GET_BMS_LIST_OF_ALL_CLEAN_STATE =
  "GET_BMS_LIST_OF_ALL_CLEAN_STATE";
export const ADD_NEW_BMS = "ADD_NEW_BMS";
export const ADD_NEW_BMS_SUCCESS = "ADD_NEW_BMS_SUCCESS";
export const ADD_NEW_BMS_FAILURE = "ADD_NEW_BMS_FAILURE";
export const ADD_NEW_BMS_CLEAN_STATE = "ADD_NEW_BMS_CLEAN_STATE";
export const UPDATE_NEW_BMS = "UPDATE_NEW_BMS";
export const UPDATE_NEW_BMS_SUCCESS = "UPDATE_NEW_BMS_SUCCESS";
export const UPDATE_NEW_BMS_FAILURE = "UPDATE_NEW_BMS_FAILURE";
export const UPDATE_NEW_BMS_CLEAN_STATE = "UPDATE_NEW_BMS_CLEAN_STATE";
export const DELETE_BM = "DELETE_BM";
export const DELETE_BM_SUCCESS = "DELETE_BM_SUCCESS";
export const DELETE_BM_FAILURE = "DELETE_BM_FAILURE";
export const DELETE_BM_CLEAN_STATE = "DELETE_BM_CLEAN_STATE";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";
export const GET_COMPANIES_CLEAN_STATE = "GET_COMPANIES_CLEAN_STATE";
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";
export const GET_COMPANY_DEPARTMENTS = "GET_COMPANY_DEPARTMENTS";
export const GET_COMPANY_DEPARTMENTS_SUCCESS = "GET_COMPANY_DEPARTMENTS_SUCCESS";
export const GET_COMPANY_DEPARTMENTS_FAILURE = "GET_COMPANY_DEPARTMENTS_FAILURE";
export const GET_DEPARTMENT_MANAGERS = "GET_DEPARTMENT_MANAGERS";
export const GET_DEPARTMENT_MANAGERS_SUCCESS =
  "GET_DEPARTMENT_MANAGERS_SUCCESS";
export const GET_DEPARTMENT_MANAGERS_FAILURE =
  "GET_DEPARTMENT_MANAGERS_FAILURE";
export const GET_DEPARTMENT_MANAGERS_CLEAN_STATE =
  "GET_DEPARTMENT_MANAGERS_CLEAN_STATE";
export const ADD_DEPARTMENT_MANAGER = "ADD_DEPARTMENT_MANAGER";
export const ADD_DEPARTMENT_MANAGER_SUCCESS = "ADD_DEPARTMENT_MANAGER_SUCCESS";
export const ADD_DEPARTMENT_MANAGER_FAILURE = "ADD_DEPARTMENT_MANAGER_FAILURE";
export const UPDATE_DEPARTMENT_MANAGER = "UPDATE_DEPARTMENT_MANAGER";
export const UPDATE_DEPARTMENT_MANAGER_SUCCESS =
  "UPDATE_DEPARTMENT_MANAGER_SUCCESS";
export const UPDATE_DEPARTMENT_MANAGER_FAILURE =
  "UPDATE_DEPARTMENT_MANAGER_FAILURE";
export const DELETE_DEPARTMENT_MANAGER = "DELETE_DEPARTMENT_MANAGER";
export const DELETE_DEPARTMENT_MANAGER_SUCCESS =
  "DELETE_DEPARTMENT_MANAGER_SUCCESS";
export const DELETE_DEPARTMENT_MANAGER_FAILURE =
  "DELETE_DEPARTMENT_MANAGER_FAILURE";
export const GET_DEPARTMENT_SETTINGS = "GET_DEPARTMENT_SETTINGS";
export const GET_DEPARTMENT_SETTINGS_SUCCESS =
  "GET_DEPARTMENT_SETTINGS_SUCCESS";
export const GET_DEPARTMENT_SETTINGS_FAILURE =
  "GET_DEPARTMENT_SETTINGS_FAILURE";
export const SET_DEPARTMENT_SETTINGS = "SET_DEPARTMENT_SETTINGS";
export const SET_DEPARTMENT_SETTINGS_SUCCESS =
  "SET_DEPARTMENT_SETTINGS_SUCCESS";
export const SET_DEPARTMENT_SETTINGS_FAILURE =
  "SET_DEPARTMENT_SETTINGS_FAILURE";

export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAILURE = "GET_STORES_FAILURE";
export const GET_DEPARTMENT_STORES = "GET_DEPARTMENT_STORES";
export const GET_DEPARTMENT_STORES_SUCCESS = "GET_DEPARTMENT_STORES_SUCCESS";
export const GET_DEPARTMENT_STORES_FAILURE = "GET_DEPARTMENT_STORES_FAILURE";
export const ADD_STORE = "ADD_STORE";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_FAILURE = "ADD_STORE_FAILURE";
export const UPDATE_STORE = "UPDATE_STORE";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAILURE = "UPDATE_STORE_FAILURE";
export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAILURE = "DELETE_STORE_FAILURE";

export const RESET_SELECTED_VALUES = "RESET_SELECTED_VALUES";
export const LOGIN_ENTER = "LOGIN_ENTER";

export const LOAD_BM_IMAGE = "LOAD_BM_IMAGE";
export const LOAD_BM_IMAGE_SUCCESS = "LOAD_BM_IMAGE_SUCCESS";
export const LOAD_BM_IMAGE_FAILURE = "LOAD_BM_IMAGE_FAILURE";
export const LOAD_BM_IMAGE_CLEAN_STATE = "LOAD_BM_IMAGE_CLEAN_STATE";
export const MAP_LOAD_BM_IMAGE = "MAP_LOAD_BM_IMAGE";
export const MAP_LOAD_BM_IMAGE_SUCCESS = "MAP_LOAD_BM_IMAGE_SUCCESS";
export const MAP_LOAD_BM_IMAGE_FAILURE = "MAP_LOAD_BM_IMAGE_FAILURE";
export const MAP_LOAD_BM_IMAGE_CLEAN_STATE = "MAP_LOAD_BM_IMAGE_CLEAN_STATE";

export const REQUEST_GENERATE_REPORT = "REQUEST_GENERATE_REPORT";
export const REQUEST_GENERATE_REPORT_SUCCESS =
  "REQUEST_GENERATE_REPORT_SUCCESS";
export const REQUEST_GENERATE_REPORT_FAILURE =
  "REQUEST_GENERATE_REPORT_FAILURE";
export const REQUEST_GENERATE_REPORT_CLEAN_STATE =
  "REQUEST_GENERATE_REPORT_CLEAN_STATE";

export const DOWNLOAD_BM_REPORT = "DOWNLOAD_BM_REPORT";
export const DOWNLOAD_BM_REPORT_SUCCESS = "DOWNLOAD_BM_REPORT_SUCCESS";
export const DOWNLOAD_BM_REPORT_FAILURE = "DOWNLOAD_BM_REPORT_FAILURE";
export const DOWNLOAD_BM_REPORT_CLEAN_STATE = "DOWNLOAD_BM_REPORT_CLEAN_STATE";

export const UPLOAD_XML = "UPLOAD_XML";
export const UPLOAD_XML_SUCCESS = "UPLOAD_XML_SUCCESS";
export const UPLOAD_XML_FAILURE = "UPLOAD_XML_FAILURE";
export const UPLOAD_XML_CLEAN_STATE = "UPLOAD_XML_CLEAN_STATE";

export const EXPORT_DEVICE_SENSORS = "EXPORT_DEVICE_SENSORS";
export const EXPORT_DEVICE_SENSORS_SUCCESS = "EXPORT_DEVICE_SENSORS_SUCCESS";
export const EXPORT_DEVICE_SENSORS_FAILURE = "EXPORT_DEVICE_SENSORS_FAILURE";
export const EXPORT_DEVICE_SENSORS_CLEAN_STATE =
  "EXPORT_DEVICE_SENSORS_CLEAN_STATE";

export const UPLOAD_MAP_BACKGROUND = "UPLOAD_MAP_BACKGROUND";
export const UPLOAD_MAP_BACKGROUND_SUCCESS = "UPLOAD_MAP_BACKGROUND_SUCCESS";
export const UPLOAD_MAP_BACKGROUND_FAILURE = "UPLOAD_MAP_BACKGROUND_FAILURE";
export const UPLOAD_MAP_BACKGROUND_CLEAN_STATE =
  "UPLOAD_MAP_BACKGROUND_CLEAN_STATE";

export const GET_MONITORING_DATA = "GET_MONITORING_DATA";
export const GET_MONITORING_DATA_SUCCESS = "GET_MONITORING_DATA_SUCCESS";
export const GET_MONITORING_DATA_FAILURE = "GET_MONITORING_DATA_FAILURE";
export const GET_MONITORING_DATA_CLEAN_STATE =
  "GET_MONITORING_DATA_CLEAN_STATE";
export const UPDATE_MONITORING_DATA = "UPDATE_MONITORING_DATA";
export const UPDATE_MONITORING_DATA_SUCCESS = "UPDATE_MONITORING_DATA_SUCCESS";
export const UPDATE_MONITORING_DATA_FAILURE = "UPDATE_MONITORING_DATA_FAILURE";
export const UPDATE_MONITORING_DATA_CLEAN_STATE =
  "UPDATE_MONITORING_DATA_CLEAN_STATE";
export const GET_STORE_DATA = "GET_STORE_DATA";
export const GET_STORE_DATA_SUCCESS = "GET_STORE_DATA_SUCCESS";
export const GET_STORE_DATA_FAILURE = "GET_STORE_DATA_FAILURE";
export const GET_STORE_DATA_CLEAN_STATE = "GET_STORE_DATA_CLEAN_STATE";
export const GET_GLOBAL_EMAILS = "GET_GLOBAL_EMAILS";
export const GET_GLOBAL_EMAILS_SUCCESS = "GET_GLOBAL_EMAILS_SUCCESS";
export const GET_GLOBAL_EMAILS_FAILURE = "GET_GLOBAL_EMAILS_FAILURE";
export const GET_GLOBAL_EMAILS_CLEAN_STATE = "GET_GLOBAL_EMAILS_CLEAN_STATE";
export const UPDATE_GLOBAL_EMAILS = "UPDATE_GLOBAL_EMAILS";
export const UPDATE_GLOBAL_EMAILS_SUCCESS = "UPDATE_GLOBAL_EMAILS_SUCCESS";
export const UPDATE_GLOBAL_EMAILS_FAILURE = "UPDATE_GLOBAL_EMAILS_FAILURE";
export const UPDATE_GLOBAL_EMAILS_CLEAN_STATE =
  "UPDATE_GLOBAL_EMAILS_CLEAN_STATE";

export const GET_HISTORY_LIST = "GET_HISTORY_LIST";
export const GET_HISTORY_LIST_SUCCESS = "GET_HISTORY_LIST_SUCCESS";
export const GET_HISTORY_LIST_FAILURE = "GET_HISTORY_LIST_FAILURE";
export const GET_HISTORY_LIST_CLEAN_STATE = "GET_HISTORY_LIST_CLEAN_STATE";
export const GET_GROUPS_AND_TEMPLATES = "GET_GROUPS_AND_TEMPLATES";
export const GET_GROUPS_AND_TEMPLATES_SUCCESS =
  "GET_GROUPS_AND_TEMPLATES_SUCCESS";
export const GET_GROUPS_AND_TEMPLATES_FAILURE =
  "GET_GROUPS_AND_TEMPLATES_FAILURE";
export const GET_GROUPS_AND_TEMPLATES_CLEAN_STATE =
  "GET_GROUPS_AND_TEMPLATES_CLEAN_STATE";
export const SEND_GUIDANCE_EMAIL = "SEND_GUIDANCE_EMAIL";
export const SEND_GUIDANCE_EMAIL_SUCCESS = "SEND_GUIDANCE_EMAIL_SUCCESS";
export const SEND_GUIDANCE_EMAIL_FAILURE = "SEND_GUIDANCE_EMAIL_FAILURE";
export const SEND_GUIDANCE_EMAIL_CLEAN_STATE =
  "SEND_GUIDANCE_EMAIL_CLEAN_STATE";

export const SEND_MAIL_TEMPLATES = "SEND_MAIL_TEMPLATES";
export const SEND_MAIL_TEMPLATES_SUCCESS = "SEND_MAIL_TEMPLATES_SUCCESS";
export const SEND_MAIL_TEMPLATES_FAILED = "SEND_MAIL_TEMPLATES_FAILED";

export const UPDATE_BM_NICKNAME = "UPDATE_BM_NICKNAME";
export const UPDATE_BM_NICKNAME_SUCCESS = "UPDATE_BM_NICKNAME_SUCCESS";
export const UPDATE_BM_NICKNAME_FAILURE = "UPDATE_BM_NICKNAME_FAILURE";
export const UPDATE_BM_NICKNAME_CLEAN_STATE = "UPDATE_BM_NICKNAME_CLEAN_STATE";

export const BM_OTA_GET_VERSION = "BM_OTA_GET_VERSION";
export const BM_OTA_GET_VERSION_SUCCESS = "BM_OTA_GET_VERSION_SUCCESS";
export const BM_OTA_GET_VERSION_FAILURE = "BM_OTA_GET_VERSION_FAILURE";
export const BM_OTA_GET_VERSION_CLEAN_STATE = "BM_OTA_GET_VERSION_CLEAN_STATE";

export const BM_OTA_GET_COMMAND_VERSION = "BM_OTA_GET_COMMAND_VERSION";
export const BM_OTA_GET_COMMAND_VERSION_SUCCESS =
  "BM_OTA_GET_COMMAND_VERSION_SUCCESS";
export const BM_OTA_GET_COMMAND_VERSION_FAILURE =
  "BM_OTA_GET_COMMAND_VERSION_FAILURE";
export const BM_OTA_GET_COMMAND_VERSION_CLEAN_STATE =
  "BM_OTA_GET_VERSION_COMMAND_CLEAN_STATE";

export const BM_OTA_SEND_COMMAND = "BM_OTA_SEND_COMMAND";
export const BM_OTA_SEND_COMMAND_SUCCESS = "BM_OTA_SEND_COMMAND_SUCCESS";
export const BM_OTA_SEND_COMMAND_FAILURE = "BM_OTA_SEND_COMMAND_FAILURE";
export const BM_OTA_SEND_COMMAND_CLEAN_STATE =
  "BM_OTA_SEND_COMMAND_CLEAN_STATE";

export const BM_OTA_SEND_BINFILE = "BM_OTA_SEND_BINFILE";
export const BM_OTA_SEND_BINFILE_SUCCESS = "BM_OTA_SEND_BINFILE_SUCCESS";
export const BM_OTA_SEND_BINFILE_FAILURE = "BM_OTA_SEND_BINFILE_FAILURE";
export const BM_OTA_SEND_BINFILE_CLEAN_STATE =
  "BM_OTA_SEND_BINFILE_CLEAN_STATE";

export const GET_GRID_MONITORING = "GET_GRID_MONITORING";
export const GET_GRID_MONITORING_SUCCESS = "GET_GRID_MONITORING_SUCCESS";
export const GET_GRID_MONITORING_FAILURE = "GET_GRID_MONITORING_FAILURE";
export const GET_GRID_MONITORING_CLEAN_STATE =
  "GET_GRID_MONITORING_CLEAN_STATE";
export const SET_GRID_COMPANY = "SET_GRID_COMPANY";
export const SET_GRID_COMPANY_SUCCESS = "SET_GRID_COMPANY_SUCCESS";
export const SET_GRID_COMPANY_FAILURE = "SET_GRID_COMPANY_FAILURE";
export const SET_GRID_COMPANY_CLEAN_STATE = "SET_GRID_COMPANY_CLEAN_STATE";
export const RESET_GRID_COMPANY = "RESET_GRID_COMPANY";
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAILURE = "GET_NEWS_FAILURE";
export const GET_NEWS_CLEAN_STATE = "GET_NEWS_CLEAN_STATE";
export const SET_NEWS = "SET_NEWS";
export const SET_NEWS_SUCCESS = "SET_NEWS_SUCCESS";
export const SET_NEWS_FAILURE = "SET_NEWS_FAILURE";
export const SET_NEWS_CLEAN_STATE = "SET_NEWS_CLEAN_STATE";
export const GET_USER_GUIDE_DOCS = "GET_USER_GUIDE_DOCS";
export const GET_USER_GUIDE_DOCS_SUCCESS = "GET_USER_GUIDE_DOCS_SUCCESS";
export const GET_USER_GUIDE_DOCS_FAILURE = "GET_USER_GUIDE_DOCS_FAILURE";
export const GET_USER_GUIDE_DOCS_CLEAN_STATE =
  "GET_USER_GUIDE_DOCS_CLEAN_STATE";
export const SET_USER_GUIDE_DOCS = "SET_USER_GUIDE_DOCS";
export const SET_USER_GUIDE_DOCS_SUCCESS = "SET_USER_GUIDE_DOCS_SUCCESS";
export const SET_USER_GUIDE_DOCS_FAILURE = "SET_USER_GUIDE_DOCS_FAILURE";
export const SET_USER_GUIDE_DOCS_CLEAN_STATE =
  "SET_USER_GUIDE_DOCS_CLEAN_STATE";

export const GET_STORE_USERS = "GET_STORE_USERS";
export const GET_STORE_USERS_SUCCESS = "GET_STORE_USERS_SUCCESS";
export const GET_STORE_USERS_FAILURE = "GET_STORE_USERS_FAILURE";
export const GET_STORE_USERS_CLEAN_STATE = "GET_STORE_USERS_CLEAN_STATE";
export const UPDATE_STORE_USERS = "UPDATE_STORE_USERS";
export const UPDATE_STORE_USERS_SUCCESS = "UPDATE_STORE_USERS_SUCCESS";
export const UPDATE_STORE_USERS_FAILURE = "UPDATE_STORE_USERS_FAILURE";
export const UPDATE_STORE_USERS_CLEAN_STATE = "UPDATE_STORE_USERS_CLEAN_STATE";
export const ADD_STORE_USERS = "ADD_STORE_USERS";
export const ADD_STORE_USERS_SUCCESS = "ADD_STORE_USERS_SUCCESS";
export const ADD_STORE_USERS_FAILURE = "ADD_STORE_USERS_FAILURE";
export const ADD_STORE_USERS_CLEAN_STATE = "ADD_STORE_USERS_CLEAN_STATE";
export const DELETE_STORE_USERS = "DELETE_STORE_USERS";
export const DELETE_STORE_USERS_SUCCESS = "DELETE_STORE_USERS_SUCCESS";
export const DELETE_STORE_USERS_FAILURE = "DELETE_STORE_USERS_FAILURE";
export const GET_STORE_LOGS = "GET_STORE_LOGS";
export const GET_STORE_LOGS_SUCCESS = "GET_STORE_LOGS_SUCCESS";
export const GET_STORE_LOGS_FAILURE = "GET_STORE_LOGS_FAILURE";
export const GET_STORE_LOGS_CLEAN_STATE = "GET_STORE_LOGS_CLEAN_STATE";

export const TOGGLE_RECONNECT_NOTIFICATION = "TOGGLE_RECONNECT_NOTIFICATION";
export const TOGGLE_RECONNECT_NOTIFICATION_SUCCESS = "TOGGLE_RECONNECT_NOTIFICATION_SUCCESS";
export const TOGGLE_RECONNECT_NOTIFICATION_FAILURE = "TOGGLE_RECONNECT_NOTIFICATION_FAILURE";

export const TOGGLE_SUMMARY_NOTIFICATION = "TOGGLE_SUMMARY_NOTIFICATION";
export const TOGGLE_SUMMARY_NOTIFICATION_SUCCESS = "TOGGLE_SUMMARY_NOTIFICATION_SUCCESS";
export const TOGGLE_SUMMARY_NOTIFICATION_FAILURE = "TOGGLE_SUMMARY_NOTIFICATION_FAILURE";

// SETUP DASHBOARD
export const UPLOAD_STORE_HEARING = "UPLOAD_STORE_HEARING";
export const UPLOAD_STORE_HEARING_SUCCESS = "UPLOAD_STORE_HEARING_SUCCESS";
export const UPLOAD_STORE_HEARING_FAILURE = "UPLOAD_STORE_HEARING_FAILURE";

export const DOWNLOAD_STORE_HEARING = "DOWNLOAD_STORE_HEARING";
export const DOWNLOAD_STORE_HEARING_SUCCESS = "DOWNLOAD_STORE_HEARING_SUCCESS";
export const DOWNLOAD_STORE_HEARING_FAILURE = "DOWNLOAD_STORE_HEARING_FAILURE";
// ---
export const UPLOAD_STORE_SETTING_FILES = "UPLOAD_STORE_SETTING_FILES";
export const UPLOAD_STORE_SETTING_FILES_SUCCESS = "UPLOAD_STORE_SETTING_FILES_SUCCESS";
export const UPLOAD_STORE_SETTING_FILES_FAILURE = "UPLOAD_STORE_SETTING_FILES_FAILURE";

export const DOWNLOAD_STORE_SETTING_FILES = "DOWNLOAD_STORE_SETTING_FILES";
export const DOWNLOAD_STORE_SETTING_FILES_SUCCESS = "DOWNLOAD_STORE_SETTING_FILES_SUCCESS";
export const DOWNLOAD_STORE_SETTING_FILES_FAILURE = "DOWNLOAD_STORE_SETTING_FILES_FAILURE";

export const DOWNLOAD_STORE_HOLDING_FILES = "DOWNLOAD_STORE_HOLDING_FILES";
export const DOWNLOAD_STORE_HOLDING_FILES_SUCCESS = "DOWNLOAD_STORE_HOLDING_FILES_SUCCESS";
export const DOWNLOAD_STORE_HOLDING_FILES_FAILURE = "DOWNLOAD_STORE_HOLDING_FILES_FAILURE";
// ---
export const UPLOAD_DEVICE_SETTINGS = "UPLOAD_DEVICE_SETTINGS";
export const UPLOAD_DEVICE_SETTINGS_SUCCESS = "UPLOAD_DEVICE_SETTINGS_SUCCESS";
export const UPLOAD_DEVICE_SETTINGS_FAILURE = "UPLOAD_DEVICE_SETTINGS_FAILURE";

export const DOWNLOAD_DEVICE_SETTINGS = "DOWNLOAD_DEVICE_SETTINGS";
export const DOWNLOAD_DEVICE_SETTINGS_SUCCESS = "DOWNLOAD_DEVICE_SETTINGS_SUCCESS";
export const DOWNLOAD_DEVICE_SETTINGS_FAILURE = "DOWNLOAD_DEVICE_SETTINGS_FAILURE";
// ---
export const REGISTER_STORE_DEVICE = "REGISTER_STORE_DEVICE";
export const REGISTER_STORE_DEVICE_SUCCESS = "REGISTER_STORE_DEVICE_SUCCESS";
export const REGISTER_STORE_DEVICE_FAILURE = "REGISTER_STORE_DEVICE_FAILURE";
// ---
export const DISPLAY_DEVICE_SENSORS = "DISPLAY_DEVICE_SENSORS";
export const DISPLAY_DEVICE_SENSORS_SUCCESS = "DISPLAY_DEVICE_SENSORS_SUCCESS";
export const DISPLAY_DEVICE_SENSORS_FAILURE = "DISPLAY_DEVICE_SENSORS_FAILURE";
// ---

// SETUP ADMIN MANAGEMENT
export const FETCH_SETUP_ADMINS = "FETCH_SETUP_ADMINS";
export const FETCH_SETUP_ADMINS_SUCCESS = "FETCH_SETUP_ADMINS_SUCCESS";
export const FETCH_SETUP_ADMINS_FAILURE = "FETCH_SETUP_ADMINS_FAILURE";
// ---
export const CREATE_SETUP_ADMIN = "CREATE_SETUP_ADMIN";
export const CREATE_SETUP_ADMIN_SUCCESS = "CREATE_SETUP_ADMIN_SUCCESS";
export const CREATE_SETUP_ADMIN_FAILURE = "CREATE_SETUP_ADMIN_FAILURE";
// ---
export const UPDATE_SETUP_ADMIN = "UPDATE_SETUP_ADMIN";
export const UPDATE_SETUP_ADMIN_SUCCESS = "UPDATE_SETUP_ADMIN_SUCCESS";
export const UPDATE_SETUP_ADMIN_FAILURE = "UPDATE_SETUP_ADMIN_FAILURE";
// ---
export const DELETE_SETUP_ADMIN = "DELETE_SETUP_ADMIN";
export const DELETE_SETUP_ADMIN_SUCCESS = "DELETE_SETUP_ADMIN_SUCCESS";
export const DELETE_SETUP_ADMIN_FAILURE = "DELETE_SETUP_ADMIN_FAILURE";
// ---

export * from "./auth";
export * from "./theme";
export * from "./dashboard";
export * from "./sensor";
export * from "./bms";
export * from "./graph_sensor";
export * from "./store";
export * from "./company";
export * from "./department";
export * from "./settings";
export * from "./setup_dashboard";
