/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobileDev = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_A6eiWLn7m",
  aws_user_pools_web_client_id: "o7ev5i78eu5hn9jnen2od1plj",
};

const awsmobileStaging = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_6nLGaztpV",
  aws_user_pools_web_client_id: "6qg08g4e1bojj9g20ci02949g9",
};

const awsmobile = {
  dev: awsmobileDev,
  staging: awsmobileStaging,
  prod: awsmobileStaging,
};

const currentEnv = process.env.REACT_APP_ENV || "dev";

export default awsmobile[currentEnv] || awsmobileDev;
