import {
  all,
  fork,
  put,
  call,
  takeLatest,
  delay,
} from "redux-saga/effects";
import * as Actions from "../actions";
import { NotificationManager } from "react-notifications";
import * as client from "src/helpers/HTTPRequest";
import TextConstants from "src/helpers/TextConstants";
import Apis from "src/store/api/ApiConstants";
import { uploadS3File } from "src/helpers/Utils";

function* setNews({ payload: content }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.NOTIFICATIONS}`;
    const response = yield call(client.put, url, { content });
    yield put(Actions.setNewsSuccess(response));
    NotificationManager.success(TextConstants.SetSystemNotificationSuccessfully, '', 5000);
  } catch (error) {
    console.log(error);
    NotificationManager.error(TextConstants.SetSystemNotificationError, '', 5000);
    yield put(Actions.setNewsFailure());
  } finally {
    yield delay(500);
    yield put(Actions.setNewsCleanState());
  }
}

function* getNews() {
  try {
    const url = `${Apis.BASE_URL}${Apis.NOTIFICATIONS}`;
    const dataRes = yield call(client.get, url);
    yield put(Actions.getNewsSuccess(dataRes));
  } catch (error) {
    console.log(error);
    yield put(Actions.getNewsFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getNewsCleanState());
  }
}

function* setUserGuide({ payload: file }) {
  try {
    const url = `${Apis.BASE_URL}${Apis.USER_GUIDE}`;
    const response = yield call(client.post, url);
    yield call(uploadS3File, response, file);
    yield put(Actions.setUserGuideDocsSuccess());
    NotificationManager.success(TextConstants.SetUserGuideDocsSuccessfully, '', 5000);
  } catch (error) {
    console.log(error);
    NotificationManager.error(TextConstants.SetUserGuideDocsError, '', 5000);
    yield put(Actions.setUserGuideDocsFailure());
  } finally {
    yield put(Actions.setUserGuideDocsCleanState());
  }
}

function* getUserGuide() {
  try {
    const url = `${Apis.BASE_URL}${Apis.USER_GUIDE}`;
    const dataRes = yield call(client.get, url);
    yield put(Actions.getUserGuideDocsSuccess(dataRes));
  } catch (error) {
    console.log(error);
    yield put(Actions.getUserGuideDocsFailure());
  } finally {
    yield delay(500);
    yield put(Actions.getUserGuideDocsCleanState());
  }
}

export function* watchSettings() {
  yield takeLatest(Actions.SET_NEWS, setNews);
  yield takeLatest(Actions.GET_NEWS, getNews);
  yield takeLatest(Actions.SET_USER_GUIDE_DOCS, setUserGuide);
  yield takeLatest(Actions.GET_USER_GUIDE_DOCS, getUserGuide);
}

export default function* settingsSaga() {
  yield all([fork(watchSettings)]);
}
