import * as Actions from "../actions";

// News & user guide feature
export const getNews = (payload) => ({
  type: Actions.GET_NEWS,
  payload,
});
export const getNewsSuccess = (payload) => ({
  type: Actions.GET_NEWS_SUCCESS,
  payload,
});
export const getNewsFailure = () => ({
  type: Actions.GET_NEWS_FAILURE,
});
export const getNewsCleanState = () => ({
  type: Actions.GET_NEWS_CLEAN_STATE,
});
export const setNews = (payload) => ({
  type: Actions.SET_NEWS,
  payload,
});
export const setNewsSuccess = (payload) => ({
  type: Actions.SET_NEWS_SUCCESS,
  payload,
});
export const setNewsFailure = () => ({
  type: Actions.SET_NEWS_FAILURE,
});
export const setNewsCleanState = () => ({
  type: Actions.SET_NEWS_CLEAN_STATE,
});
export const getUserGuideDocs = (payload) => ({
  type: Actions.GET_USER_GUIDE_DOCS,
  payload,
});
export const getUserGuideDocsSuccess = (payload) => ({
  type: Actions.GET_USER_GUIDE_DOCS_SUCCESS,
  payload,
});
export const getUserGuideDocsFailure = () => ({
  type: Actions.GET_USER_GUIDE_DOCS_FAILURE,
});
export const getUserGuideDocsCleanState = () => ({
  type: Actions.GET_USER_GUIDE_DOCS_CLEAN_STATE,
});
export const setUserGuideDocs = (payload) => ({
  type: Actions.SET_USER_GUIDE_DOCS,
  payload,
});
export const setUserGuideDocsSuccess = (payload) => ({
  type: Actions.SET_USER_GUIDE_DOCS_SUCCESS,
  payload,
});
export const setUserGuideDocsFailure = () => ({
  type: Actions.SET_USER_GUIDE_DOCS_FAILURE,
});
export const setUserGuideDocsCleanState = () => ({
  type: Actions.SET_USER_GUIDE_DOCS_CLEAN_STATE,
});