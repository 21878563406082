import * as StoreActions from "../actions";

const initialState = {
  stores: [],
  isLoading: false,
  addingStore: false,
  updatingStore: false,
  deletingStore: false,
  updatingSettings: false,
  type: "",
  storeUsers: [],
  addingStoreUsers: false,
  updatingStoreUsers: false,
  deletingStoreUsers: false,
  logs: [],
  logsStoreName: "",
  loadingLogs: false,
};

const Store = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case StoreActions.GET_STORES:
    case StoreActions.GET_DEPARTMENT_STORES:
      return {
        ...state,
        isLoading: true,
        type,
      };
    case StoreActions.GET_STORES_SUCCESS:
    case StoreActions.GET_DEPARTMENT_STORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stores: payload,
        type,
      };
    case StoreActions.GET_STORES_FAILURE:
      return {
        ...state,
        isLoading: false,
        type,
      };

    case StoreActions.ADD_STORE:
      return {
        ...state,
        addingStore: true,
        type,
      };
    case StoreActions.ADD_STORE_SUCCESS:
      return {
        ...state,
        addingStore: false,
        stores: [payload, ...state.stores],
        type,
      };
    case StoreActions.ADD_STORE_FAILURE:
      return {
        ...state,
        addingStore: false,
        type,
      };

    case StoreActions.UPDATE_STORE:
      return {
        ...state,
        updatingStore: true,
        type,
      };
    case StoreActions.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        updatingStore: false,
        stores: state.stores.map((x) => (x.id === payload.id ? payload : x)),
        type,
      };
    case StoreActions.UPDATE_STORE_FAILURE:
      return {
        ...state,
        updatingStore: false,
        type,
      };

    case StoreActions.DELETE_STORE:
      return {
        ...state,
        deletingStore: true,
        type,
      };
    case StoreActions.DELETE_STORE_SUCCESS:
      return {
        ...state,
        deletingStore: false,
        stores: state.stores.filter((x) => x.id !== payload),
        type,
      };
    case StoreActions.DELETE_STORE_FAILURE:
      return {
        ...state,
        deletingStore: false,
        type,
      };

    case StoreActions.UPDATE_STORE_SETTINGS:
      return {
        ...state,
        updatingSettings: true,
        type,
      };

    case StoreActions.UPDATE_STORE_SETTINGS_SUCCESS:
      return {
        ...state,
        updatingSettings: false,
        type,
      };

    case StoreActions.UPDATE_STORE_SETTINGS_FAILURE:
      return {
        ...state,
        updatingSettings: false,
        type,
      };

    case StoreActions.GET_STORE_USERS:
      return {
        ...state,
        isLoading: true,
        type,
      };

    case StoreActions.GET_STORE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        storeUsers: payload,
        type,
      };

    case StoreActions.GET_STORE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        type,
      };

    case StoreActions.ADD_STORE_USERS:
      return {
        ...state,
        isLoading: true,
        addingStoreUsers: true,
        type,
      };

    case StoreActions.ADD_STORE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        storeUsers: [payload, ...state.storeUsers],
        addingStoreUsers: false,
        type,
      };

    case StoreActions.ADD_STORE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        addingStoreUsers: false,
        type,
      };

    case StoreActions.UPDATE_STORE_USERS:
      return {
        ...state,
        isLoading: true,
        updatingStoreUsers: true,
        type,
      };

    case StoreActions.UPDATE_STORE_USERS_SUCCESS:
      return {
        ...state,
        updatingStoreUsers: false,
        storeUsers: state.storeUsers.map((x) =>
          x.username === payload.username ? payload : x
        ),
        isLoading: false,
        type,
      };

    case StoreActions.UPDATE_STORE_USERS_FAILURE:
      return {
        ...state,
        updatingStoreUsers: false,
        isLoading: false,
        type,
      };

    case StoreActions.DELETE_STORE_USERS:
      return {
        ...state,
        isLoading: true,
        deletingStoreUsers: true,
        type,
      };

    case StoreActions.DELETE_STORE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        storeUsers: state.storeUsers.filter((x) => x.username !== payload),
        deletingStoreUsers: false,
        type,
      };

    case StoreActions.DELETE_STORE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        deletingStoreUsers: false,
        type,
      };

    case StoreActions.GET_STORE_LOGS:
      return {
        ...state,
        loadingLogs: true,
        type,
      };
    case StoreActions.GET_STORE_LOGS_SUCCESS:
      return {
        ...state,
        loadingLogs: false,
        logs: payload.logs,
        logsStoreName: payload.storeName,
        type,
      };
    case StoreActions.GET_STORE_LOGS_FAILURE:
      return {
        ...state,
        loadingLogs: false,
        type,
      };
    case StoreActions.TOGGLE_SUMMARY_NOTIFICATION_SUCCESS:
      const { storeId, isEnabled } = payload;
      return {
        ...state,
        stores: state.stores?.map(x => x.id === storeId ? { ...x, summaryEnabled: isEnabled } : x),
        type: action.type,
      };
    default:
      return state;
  }
};

export default Store;
