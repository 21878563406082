import PowerFull from "src/assets/icons/Resources/icon_battery.png";
import PowerAdapter from "src/assets/icons/Resources/icon_Adapter.png";
import Battery0 from "src/assets/icons/Resources/icon_battery_0.png";
import Battery20 from "src/assets/icons/Resources/icon_battery_20.png";
import Battery40 from "src/assets/icons/Resources/icon_battery_40.png";
import Battery60 from "src/assets/icons/Resources/icon_battery_60.png";
import Battery80 from "src/assets/icons/Resources/icon_battery_80.png";
import Battery100 from "src/assets/icons/Resources/icon_battery_100.png";
import IconGraph from "src/assets/icons/Resources/icon_graf.png";
import ButtonSetting from "src/assets/icons/Resources/button_setting.png";
import ButtonBack from "src/assets/icons/Resources/button_modoru.png";
import ButtonAlert from "src/assets/icons/Resources/button_alart-kakunin.png";
import ButtonReport from "src/assets/icons/Resources/button_report.png";
import ButtonAlertStop from "src/assets/icons/Resources/button_alart-stop.png";
import ButtonReportCsv from "src/assets/icons/Resources/button_report_CSV.png";
import ButtonSelectAllReset from "src/assets/icons/Resources/button_icon-sentaku-kaijo.png";
import ButtonSearchFilter from "src/assets/icons/Resources/button_ND.png";
import ButtonShowErrors from "src/assets/icons/Resources/button_error_kensaku.png";
import ButtonGraphDay from "src/assets/icons/Resources/button_kikan-DAY.PNG";
import ButtonGraphDWeek from "src/assets/icons/Resources/button_kikan-WEEK.PNG";
import ButtonGraphMonth from "src/assets/icons/Resources/button_kikan-MONTH.PNG";
import ButtonAlertOff from "src/assets/icons/Resources/button_alart_report_OFF.png";
import ButtonAlertON from "src/assets/icons/Resources/button_alart_report_ON.png";
import IconSet from "src/assets/icons/Resources/icon_set.png";
import DummyTestMap from "src/assets/700x500.png";
import MailSendIcon from "src/assets/icons/Resources/MailSendIcon.PNG";
import i10 from "src/assets/icons/monitoring/i10.svg";
import i20 from "src/assets/icons/monitoring/i20.svg";
import i30 from "src/assets/icons/monitoring/i30.svg";
import t_ok from "src/assets/icons/monitoring/t_ok.svg";
import t_warning from "src/assets/icons/monitoring/t_warning.svg";
import t_alert from "src/assets/icons/monitoring/t_alert.svg";
import t_error from "src/assets/icons/monitoring/t_error.svg";
import monitor from "src/assets/icons/monitor.svg";
import download from "src/assets/icons/download.svg";

const ImageHolders = {
  PowerFull,
  PowerAdapter,
  Battery0,
  Battery20,
  Battery40,
  Battery60,
  Battery80,
  Battery100,
  IconGraph,
  ButtonSetting,
  ButtonBack,
  ButtonAlert,
  ButtonReport,
  ButtonAlertStop,
  ButtonReportCsv,
  ButtonSelectAllReset,
  ButtonSearchFilter,
  ButtonShowErrors,
  ButtonGraphDay,
  ButtonGraphDWeek,
  ButtonGraphMonth,
  ButtonAlertOff,
  ButtonAlertON,
  IconSet,
  DummyTestMap,
  MailSendIcon,
  i10,
  i20,
  i30,
  t_ok,
  t_warning,
  t_alert,
  t_error,
  monitor,
  download,
};

export default ImageHolders;
