import { NotificationManager } from "react-notifications";
import ImageHolders from "./ImageHolders";
import TextConstants from "./TextConstants";
import moment from "moment";
import { components } from "react-select";

export const clearAllToasts = () => {
  NotificationManager.listNotify.forEach((n) =>
    NotificationManager.remove({ id: n.id })
  );
};

export const tableCellBgColor = {
  OK: "#2890fe",
  Caution: "#fa0200",
  Warning: "#fe8700",
  Error: "#b471c8",
};

export const emailNotificationDefaultSettings = {
  error: true,
  alert: true,
  warning: false,
  ok: true,
  guidance_email: false,
};

export const getJapaneseStatus = (status) => {
  switch (status.toLowerCase()) {
    case "error":
      return "通信エラー";
    case "caution":
      return "警告";
    case "warning":
      return "注意";
    default:
      return "OK";
  }
};

export const DefaultBmDataSavingTimeList = [
  "00:00",
  "07:57",
  "08:26",
  "09:41",
  "10:11",
  "14:55",
  "18:00",
  "21:00",
];

export const DefaultNotificationTime = {
  start: undefined,
  end: undefined,
};

export const DefaultSavingTime = {
  start: 0,
  end: 0,
  interval: 2,
};

export const IsValidSavingTime = (savingTime) => {
  try {
    const { start, end, interval } = savingTime;
    if (
      (!start && start !== 0) ||
      (!end && end !== 0) ||
      ![2, 3].includes(interval)
    )
      return false;
    if (moment(start, "HH").isAfter(moment(end, "HH"))) return false;
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GenerateHozonTimingsFromSavingTime = (savingTime) => {
  try {
    const hozonTimings = [];
    const { start, end, interval } = savingTime;
    let time = moment(start, "HH");
    while (time.isSameOrBefore(moment(end, "HH")) && hozonTimings.length < 8) {
      hozonTimings.push(time.format("HH:mm"));
      time = time.add(interval, "hours");
    }
    return hozonTimings;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case 0:
      return TextConstants.OK;
    case 1:
      return TextConstants.Warning;
    case 2:
      return TextConstants.Caution;
    case 3:
      return TextConstants.Error;
    default:
      return "";
  }
};

export const isValidTimeRange = (startTime, endTime) => {
  try {
    if (!startTime || !endTime) return false;
    return startTime && endTime;
    // Allow to input start > end time
    // return moment(endTime, "HH:mm").isAfter(moment(startTime, "HH:mm"));
  } catch (error) {}
  return false;
};

export const getBatteryImage = (itm) => {
  const batteryValue = getBatteryPercentage(itm.battery);
  let imageToDisplay = ImageHolders.PowerFull;
  if (batteryValue >= 90) {
    imageToDisplay = ImageHolders.Battery100;
  } else if (batteryValue >= 70 && batteryValue < 90) {
    imageToDisplay = ImageHolders.Battery80;
  } else if (batteryValue >= 50 && batteryValue < 70) {
    imageToDisplay = ImageHolders.Battery60;
  } else if (batteryValue >= 30 && batteryValue < 50) {
    imageToDisplay = ImageHolders.Battery40;
  } else if (batteryValue >= 10 && batteryValue < 30) {
    imageToDisplay = ImageHolders.Battery20;
  } else if (batteryValue < 10) {
    imageToDisplay = ImageHolders.Battery0;
  }

  return imageToDisplay;
};

export const getBatteryPercentage = (battValue) => {
  // ((B + 100) --255) / (275 --255) => (B - 155) / (275 - 255)
  const percentage = Math.min(((battValue - 155) / (275 - 255)) * 100, 100);
  return Math.max(parseInt(percentage), 0);
};

export const ZoomLevel = [
  { label: "FIT", value: "FIT" },
  { label: 125, value: 0.3 },
  { label: 150, value: 0.5 },
  { label: 200, value: 0.7 },
  { label: 300, value: 1 },
];

export const ThzhotspotPosition = (evt, el, hotspotsize, percent) => {
  var left = el.getBoundingClientRect().left;
  var top = el.getBoundingClientRect().top;

  var offsetWidth = el.getBoundingClientRect().width;
  var offsetHeight = el.getBoundingClientRect().height;

  var windYOffSet = window.pageYOffset;

  var hotspot = hotspotsize ? hotspotsize : 0;

  let x, y;

  if (percent) {
    x = ((evt.pageX - left - hotspot / 2) / offsetWidth) * 100 + "%";
    y =
      ((evt.pageY - top - windYOffSet - hotspot / 2) / offsetHeight) * 100 +
      "%";
  } else {
    x = evt.pageX - left - hotspot / 2;
    y = evt.pageY - top - windYOffSet - hotspot / 2;
  }

  return { x, y };
};

export const SortSensorByNumber = (sensorData) => {
  const compareValues = (v1, v2) => (v1 > v2 ? 1 : v1 < v2 ? -1 : 0);
  const sortedData = [...sensorData].sort((x, y) => {
    const [firstNetworkId, firstSensorNo] = x.sensorNumber.split("-");
    const [secondNetworkId, secondSensorNo] = y.sensorNumber.split("-");
    var result = compareValues(Number(firstNetworkId), Number(secondNetworkId));
    return result === 0
      ? compareValues(Number(firstSensorNo), Number(secondSensorNo))
      : result;
  });
  return sortedData;
};

// For cache reset
export const getLocalVersion = () => {
  const localVersion = localStorage.getItem("_version");
  return localVersion ? JSON.parse(localVersion) : null;
};

export const setLocalVersion = (version) => {
  localStorage.setItem(
    "_version",
    JSON.stringify({
      version: version,
      lastCheckAt: moment().utc().valueOf(),
    })
  );
};

export const willCheckVersion = () => {
  const localVersion = getLocalVersion();
  if (!localVersion) {
    return true;
  } else {
    return moment(localVersion.lastCheckAt)
      .utc()
      .add(3, "minutes")
      .isBefore(moment().utc());
  }
};

export const SortSensorBySlotNo = (sensorData) => {
  const compareValues = (v1, v2) => (v1 > v2 ? 1 : v1 < v2 ? -1 : 0);
  const sortedData = [...sensorData].sort((x, y) => {
    const [firstNetworkId, firstSensorNo] = x.slotNo.split("-");
    const [secondNetworkId, secondSensorNo] = y.slotNo.split("-");
    var result = compareValues(Number(firstNetworkId), Number(secondNetworkId));
    return result === 0
      ? compareValues(Number(firstSensorNo), Number(secondSensorNo))
      : result;
  });
  return sortedData;
};

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>{TextConstants.None}</span>
    </components.NoOptionsMessage>
  );
};

export const getLastRowsIndex = (data) => {
  const gridLayout = 4;
  const dataLength = data.length;

  if (dataLength === 0) return null;

  const gridModulers = Math.floor(dataLength / gridLayout);

  const indexesToBeAdd = dataLength - gridModulers * gridLayout;

  return dataLength - indexesToBeAdd;
};

export const updateSensorListScroll = (scrollTop) => {
  if (localStorage.getItem("SensorListScrollTop")) {
    localStorage.removeItem("SensorListScrollTop");
  }
  localStorage.setItem("SensorListScrollTop", scrollTop);
};

export const checkValidateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const uploadS3File = (signedData, file) => {
  const formdata = new FormData();
  // Add fields keys values
  Object.keys(signedData?.fields || {}).forEach(key => {
    formdata.append(key, signedData?.fields[key]);
  });
  // Add file
  formdata.append("file", file);

  const options = {
    method: "POST",
    body: formdata,
  };

  return new Promise((resolve, reject) => {
    fetch(signedData.url, options)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response);
      })
      .catch((e) => reject(e));
  });
}

export const downloadS3File = (presignedUrl) => {
  return new Promise((resolve, reject) => {
    fetch(presignedUrl)
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return resolve(response.blob());
      })
      .catch((e) => reject(e));
  });
}