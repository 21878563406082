import {
  all,
  fork,
  put,
  takeEvery,
  call,
  select,
  takeLatest,
  takeLeading,
  delay,
} from "redux-saga/effects";
import * as SensorActions from "../actions";
import { NotificationManager } from "react-notifications";
import TextConstants from "src/helpers/TextConstants";
import ApiConstants from "../api/ApiConstants";
import { get } from "src/helpers/HTTPRequest";
import * as client from "src/helpers/HTTPRequest";
import { getUserToken } from "src/helpers/StorageUtils";
import moment from "moment";
import constants from "src/constants";
import { SortSensorByNumber, SortSensorBySlotNo } from "src/helpers/Utils";

function* fetchSensorList({ payload }) {
  const { selectedBmId, url } = payload;
  try {
    const allBms = yield select(
      (state) => state.sensor?.selectedStore?.bmDetails || []
    );
    if (allBms.length === 0 && selectedBmId === constants.SELECTED_BM.ALL) {
      yield put(SensorActions.setSensorListFailure());
      return false;
    }

    let setBaseUrl = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_SENSORS_STATUS}`;
    if (url === "deviceSensors") {
      // BmSensorManagement.js [if its called from management portal, url will be changed]
      setBaseUrl = `${ApiConstants.BASE_URL}${ApiConstants.DEVICE_MANAGE_SENSORS}`;
    }

    let urlToCall = `${setBaseUrl.replace("{:deviceId}", selectedBmId)}`;

    const data = [];

    // If all passed then loop through it and get all bms sensors
    if (selectedBmId === constants.SELECTED_BM.ALL) {
      for (let singleBm of allBms) {
        urlToCall = `${setBaseUrl.replace("{:deviceId}", singleBm.deviceId)}`;
        const singleBmsData = yield call(get, urlToCall);
        data.push(...singleBmsData);
      }
    } else {
      const receivedData = yield call(get, urlToCall); // single Bm's sensors
      data.push(...receivedData);
    }

    const newData =
      data?.map((item) => ({ ...item, isPositionChanged: false })) || [];

    // Apply default sorting for sensor list by sensorNumber
    let sortedData;
    if (url == "deviceSensors") {
      sortedData = SortSensorBySlotNo(newData);
    } else {
      sortedData = SortSensorByNumber(newData);
    }

    yield put(SensorActions.setSensorListSuccess(sortedData));
  } catch (error) {
    yield put(SensorActions.setSensorListFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorActions.setSensorListCleanState());
  }
}

function* letApplyFilterSensor({ payload }) {
  const { states, thumbs, unitTypeSearchTxt, isShowErrors } = payload;
  try {
    let data = yield select((state) => state.sensor.sensorDataCopy);

    if (isShowErrors) {
      data = data.filter((sensor) => sensor.status === "error");
    } else {
      if (states.length > 0) {
        data = data.filter((sensor) => states.includes(sensor.status));
      }

      if (thumbs.length > 0) {
        data = data.filter((sensor) => thumbs.includes(sensor.product.code));
      }

      if (unitTypeSearchTxt !== "") {
        const searchTxt = unitTypeSearchTxt.replace("、", ","); // remove japanese comma
        // create array from string and remove space from each word
        const searchTxtArr = searchTxt
          .split(",")
          .map((item) => item && item.replace(" ", "").toLowerCase());

        const traslatedSearchArr = [];
        Object.keys(TextConstants).map((keys) => {
          if (searchTxtArr.includes(TextConstants[keys])) {
            traslatedSearchArr.push(keys);
          }
          return traslatedSearchArr;
        });

        data = data.filter(
          (itemObj) =>
            // traslatedSearchArr.includes(itemObj.product.code.toLowerCase())
            searchTxtArr.some((searchText) =>
              itemObj.name.toLowerCase().includes(searchText)
            ) // Search for sensor name contains search text
        );
      }
    }

    yield put(SensorActions.letApplyFilterSensorSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(SensorActions.letApplyFilterSensorFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* fetchAlertHistories({ payload }) {
  try {
    const storeId = payload
      ? payload
      : yield select((state) => state.sensor.selectedStore?.id);
    if (!storeId) {
      yield put(SensorActions.setAlaramListFailure());
      NotificationManager.error(TextConstants.CantSpecifyStore, "", 5000);
      return;
    }
    const startDate = moment.utc().add(-30, "days").valueOf();
    const endDate = moment.utc().valueOf();
    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES}/${storeId}/alerts?start-date=${startDate}&end-date=${endDate}`;
    const response = yield call(client.get, url);
    yield put(SensorActions.setAlaramListSuccess(response));
  } catch (error) {
    yield put(SensorActions.setAlaramListFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* updateAlertComment({ payload }) {
  try {
    const storeId = yield select((state) => state.sensor.selectedStore?.id);
    if (!storeId) {
      NotificationManager.error(TextConstants.CantSpecifyStore, "", 5000);
      return;
    }
    const { alert, data } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES}/${storeId}/alerts/${alert.dataId}/${moment(alert.timestamp, 'YYYY/MM/DD hh:mm:ss').valueOf()}`;
    const response = yield call(client.put, url, data);
    NotificationManager.success(
      TextConstants.UpdateAlertCommentSuccess,
      "",
      5000
    );
    yield put(SensorActions.updateAlertCommentSuccess(response));
  } catch (error) {
    yield put(SensorActions.updateAlertCommentFailure());
    NotificationManager.error(error.message, "", 5000);
  }
}

function* clearAlertHistories({ payload }) {
  try {
    const storeId = payload
      ? payload
      : yield select((state) => state.sensor.selectedStore?.id);
    if (!storeId) {
      NotificationManager.error(TextConstants.CantSpecifyStore, "", 5000);
      return;
    }
    const startDate = moment.utc().add(-30, "days").valueOf();
    const endDate = moment.utc().valueOf();
    const url = `${ApiConstants.BASE_URL}${ApiConstants.STORES}/${storeId}/alerts?start-date=${startDate}&end-date=${endDate}`;
    const response = yield call(client.deleteMethod, url);
    NotificationManager.success(
      TextConstants.ClearAlertHistorySuccess,
      "",
      5000
    );
    yield put(SensorActions.clearAlertHistoriesSuccess(response));
  } catch (error) {
    yield put(SensorActions.clearAlertHistoriesFailure());
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield delay(700);
    yield put(SensorActions.clearAlertHistoriesClearState());
  }
}

function* fetchBmImage({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL
      }${ApiConstants.SINGLE_DEVICE_IMAGE.replace("{:deviceId}", payload)}`;
    const linkResponse = yield call(get, url);
    yield put(SensorActions.loadBmImageSuccess(linkResponse.url));
  } catch (error) {
    if (error.status === 404) {
      yield put(SensorActions.loadBmImageFailure(true));
      return;
    }
    yield put(SensorActions.loadBmImageFailure(false));
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorActions.setSensorListCleanState());
  }
}

function* fetchMapBmImage({ payload }) {
  try {
    const url = `${ApiConstants.BASE_URL
      }${ApiConstants.SINGLE_DEVICE_IMAGE.replace("{:deviceId}", payload)}`;
    const linkResponse = yield call(get, url);
    yield put(SensorActions.mapLoadBmImageSuccess(linkResponse.url));
  } catch (error) {
    if (error.status === 404) {
      yield put(SensorActions.mapLoadBmImageFailure(true));
      return;
    }
    yield put(SensorActions.mapLoadBmImageFailure(false));
    NotificationManager.error(error.message, "", 5000);
  } finally {
    yield put(SensorActions.setSensorListCleanState());
  }
}

function* updateSettings({ payload }) {
  try {
    const { deviceId, sensorId, settings } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${deviceId}/sensors/${sensorId}`;
    const response = yield call(client.put, url, settings);
    yield put(SensorActions.updateCurrentSensor(response));
    yield put(SensorActions.updateSensorSettingsSuccess(response));
    NotificationManager.success(
      TextConstants.UpdateSensorSettingsSuccess,
      "",
      5000
    );
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(SensorActions.updateSensorSettingsFailure());
  }
}

function* requestGenerateReport({ payload }) {
  const { bm, start, end, mode } = payload;
  try {
    const generateUrl = `${ApiConstants.BASE_URL}${ApiConstants.GENERATE_REPORT}`;
    const payload = {
      deviceId: bm,
      startDate: start,
      endDate: end,
      mode: mode,
    };
    const { executionId } = yield call(client.post, generateUrl, payload);
    if (executionId) {
      yield put(
        SensorActions.requestGenerateReportSuccess({
          mode,
          executionId,
          deviceId: bm,
        })
      );
    } else {
      yield put(SensorActions.downloadBmReportFailure());
    }
  } catch (e) {
    console.warn(e);
    yield put(SensorActions.downloadBmReportFailure());
  }
}

function* downloadReport() {
  try {
    const { deviceId, executionId } = yield select(
      (state) => state.sensor.reportExecution
    );
    if (deviceId && executionId) {
      const params = {
        executionId,
        deviceId,
      };
      const downloadUrl = `${ApiConstants.BASE_URL}${ApiConstants.DOWNLOAD_REPORT}`;
      const data = yield call(getReport, downloadUrl, params);
      if (data) {
        yield put(SensorActions.downloadBmReportSuccess(data));
      }
    }
  } catch (e) {
    console.warn(e);
    yield put(SensorActions.downloadBmReportFailure());
  }
}

function getReport(url, params) {
  const qs = new URLSearchParams();
  for (let [key, val] of Object.entries(params)) {
    qs.append(key, val);
  }
  const qsResult = qs.toString();
  if (qsResult) {
    url += `?${qsResult}`;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: `Bearer ${getUserToken()}`,
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          if (response.status === 201) {
            resolve(response.arrayBuffer());
          } else {
            resolve();
          }
        }
      })
      .catch((e) => reject(e));
  });
}

function* letUpdateSensorList({ payload }) {
  try {
    yield put(SensorActions.letUpdateSensorListSuccess(payload));
  } catch (error) {
    yield put(SensorActions.letUpdateSensorListFailure());
  } finally {
    yield put(SensorActions.letUpdateSensorListCleanState());
  }
}

function* letUpdateSensorPosition({ payload }) {
  try {
    const StoreSensorData = yield select((state) => state.sensor.sensorData);
    const StoreSelectedBmId = yield select(
      (state) => state.sensor.selectedBmId
    );

    let url = `${ApiConstants.BASE_URL
      }${ApiConstants.DEVICE_SENSORS_UPDATE.replace("{:deviceId}", StoreSelectedBmId)}`;

    yield call(client.put, url, payload);

    // reset isPositionChanged flag to false
    const newData = StoreSensorData.map((item) => ({
      ...item,
      isPositionChanged: false,
    }));

    NotificationManager.success(TextConstants.PositionUpdateSuccess, "", 5000);

    yield put(SensorActions.letUpdateSensorPositionSuccess(newData));
  } catch (error) {
    NotificationManager.error(TextConstants.PositionUpdateFailure, "", 5000);
    yield put(SensorActions.letUpdateSensorPositionFailure());
  } finally {
    yield put(SensorActions.letUpdateSensorPositionCleanState());
  }
}

function* addNewSensor({ payload }) {
  try {
    const { bmId, sensor } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${bmId}/sensors`;
    yield call(client.post, url, sensor);
    const { sensorData } = yield select((state) => state.sensor);
    const newSensors = SortSensorBySlotNo([...sensorData, sensor]);
    yield put(SensorActions.addNewSensorSuccess(newSensors));
    NotificationManager.success(TextConstants.SensorAddSuccess, "", 5000);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(SensorActions.addNewSensorFailure());
  } finally {
    yield delay(1500);
    yield put(SensorActions.addNewSensorCleanState());
  }
}

function* deleteSensor({ payload }) {
  try {
    const sensorData = yield select((state) => state.sensor.sensorData);

    const { bmId, sensorId, slotNo } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${bmId}/sensors/${sensorId}`;
    yield call(client.deleteMethod, url, { slotNo });
    yield put(
      SensorActions.deleteTheSensorSuccess(
        sensorData.filter((sensor) => sensor.sensorId !== sensorId)
      )
    );
    NotificationManager.success(TextConstants.SensorDeleteSuccess, "", 5000);
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(SensorActions.deleteTheSensorFailure());
  } finally {
    yield delay(1500);
    yield put(SensorActions.deleteTheSensorCleanState());
  }
}

function* editSensor({ payload }) {
  try {
    const sensorData = yield select((state) => state.sensor.sensorData);

    const { bmId, sensorId, sensor } = payload;
    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${bmId}/sensors/${sensorId}`;
    yield call(client.patch, url, sensor);

    const SensorDataToBeUpdated = [...sensorData];
    const findIndex = SensorDataToBeUpdated.findIndex(
      (sensor) => sensor.sensorId === sensorId
    );
    if (findIndex !== -1) {
      SensorDataToBeUpdated[findIndex] = { ...sensor };
      yield put(
        SensorActions.editTheSensorSuccess(
          SortSensorBySlotNo(SensorDataToBeUpdated)
        )
      );
      NotificationManager.success(TextConstants.SensorUpdatedSuccess, "", 5000);
    }
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(SensorActions.editTheSensorFailure());
  } finally {
    yield delay(1500);
    yield put(SensorActions.editTheSensorCleanState());
  }
}

function* hideSlotSensor({ payload }) {
  try {
    const sensorData = yield select((state) => state.sensor.sensorData);

    const { bmId, sensorId, sensor } = payload;

    let isHidden = true;
    if (sensor.hidden) {
      isHidden = false;
    }

    const objToUpdate = {
      hidden: isHidden,
      slotNo: sensor.slotNo,
    };

    const url = `${ApiConstants.BASE_URL}${ApiConstants.DEVICES}/${bmId}/sensors/${sensorId}/hide`;
    const apiRes = yield call(client.put, url, objToUpdate);

    const SensorDataToBeUpdated = [...sensorData];
    const findIndex = SensorDataToBeUpdated.findIndex(
      (sensor) => sensor.sensorId === sensorId
    );
    if (findIndex !== -1) {
      SensorDataToBeUpdated[findIndex] = { ...apiRes };
      yield put(SensorActions.hideSlotOfSensorSuccess(SensorDataToBeUpdated));
      NotificationManager.success(TextConstants.SensorUpdatedSuccess, "", 5000);
    }
  } catch (error) {
    NotificationManager.error(error.message, "", 5000);
    yield put(SensorActions.hideSlotOfSensorFailure());
  } finally {
    yield delay(1500);
    yield put(SensorActions.hideSlotOfSensorCleanState());
  }
}

export function* watchSensor() {
  yield takeLatest(SensorActions.GET_SENSOR_LIST, fetchSensorList);
  yield takeEvery(SensorActions.LET_APPLY_SENSOR_FILTER, letApplyFilterSensor);
  yield takeEvery(SensorActions.GET_ALARAM_LIST, fetchAlertHistories);
  yield takeEvery(SensorActions.UPDATE_ALERT_COMMENT, updateAlertComment);
  yield takeEvery(SensorActions.CLEAR_ALERT_HISTORIES, clearAlertHistories);
  yield takeLatest(SensorActions.LOAD_BM_IMAGE, fetchBmImage);
  yield takeLatest(SensorActions.UPDATE_SENSOR_SETTINGS, updateSettings);
  yield takeLeading(
    SensorActions.REQUEST_GENERATE_REPORT,
    requestGenerateReport
  );
  yield takeLeading(SensorActions.DOWNLOAD_BM_REPORT, downloadReport);
  yield takeLeading(SensorActions.LET_UPDATE_SENSOR_LIST, letUpdateSensorList);
  yield takeLeading(
    SensorActions.LET_UPDATE_SENSOR_POSITION,
    letUpdateSensorPosition
  );
  yield takeLeading(SensorActions.ADD_NEW_SENSOR, addNewSensor);
  yield takeLeading(SensorActions.DELETE_SENSOR, deleteSensor);
  yield takeLeading(SensorActions.EDIT_SENSOR, editSensor);
  yield takeLeading(SensorActions.HIDE_SLOT_SENSOR, hideSlotSensor);
  yield takeLeading(SensorActions.MAP_LOAD_BM_IMAGE, fetchMapBmImage);
}

export default function* sensorListSaga() {
  yield all([fork(watchSensor)]);
}
